import React, { useEffect, useState } from 'react'
import Navbar from '../../navbar/Navbar'
import { Badge, Card, Col, Dropdown, DropdownMenu, Row, Table, Button, Modal, Form, ProgressBar } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookReader, faCalendar, faChalkboardTeacher, faCheck, faClipboardUser, faDownload, faEllipsisV, faEye, faFileExport, faHandHoldingHeart, faHandsHelping, faPen, faPeopleCarry, faPrint, faTimes, faTrash, faUniversalAccess, faUser } from '@fortawesome/free-solid-svg-icons'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import ActionButton from '../../general-components/ActionButton'
import { getUserRole, getUserToken, logout, validateToken } from '../../../controllers/user.controller'
import { GET, POST, PUT } from '../../../controllers/endpoints.controller'
import moment from 'moment'
import Loading from '../../general-components/Loading'
import InfoField from '../../general-components/InfoField'

function Courses() {
    const PAGE_TITLE = "Cursos";
    const PAGE_ICON = faChalkboardTeacher;

    const [isTableLoaded, setIsTableLoaded] = useState(false);
    const [myRole, setMyRole] = useState("")

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = `Tasky - ${PAGE_TITLE}`;
        validateToken();
        setMyRole(getUserRole());
        getClassrooms();
    }, []);

    const getParams = function () {
        const params = new URLSearchParams(window.location.search);
        if (params.has('id')) {
            return params.get('id');
        } else {
            return -1;
        }
    }

    //DropDown Course
    const [selectedClassroomOption, setSelectedClassroomOption] = useState('Curso');
    const [selectedClassroomId, setSelectedClassroomId] = useState(null);

    const handleSelect = (eventKey) => {
        setIsTableLoaded(false)
        setSelectedClassroomOption(`${classrooms[eventKey].degree}º ${classrooms[eventKey].division}`);
        setSelectedClassroomId(classrooms[eventKey].id)
        getClassroomStudents(classrooms[eventKey].id, false)
        navigate({
            pathname: location.pathname,
            search: `?id=${classrooms[eventKey].id}`,
        });
    };

    const [classrooms, setClassrooms] = useState([]);
    const [students, setStudents] = useState([]);

    const getClassrooms = async function () {
        if (getUserRole() == "Docente Jardín" || getUserRole() == "Docente Primaria" || getUserRole() == "Docente Secundaria") {
            const req = await GET(`/classes/classrooms/byTeacherId`, getUserToken());
            const res = await req.json();

            if (req.status === 200) {
                console.log(res)
                setClassrooms(res);
                if (res.length > 0) {
                    let classroomIdUrl = getParams();
                    if (classroomIdUrl >= 0) {
                        let index = res.findIndex(item => item.id == classroomIdUrl);
                        index = index >= 0 ? index : 0;
                        setSelectedClassroomOption(`${res[index].degree}º ${res[index].division}`);
                        setSelectedClassroomId(res[index].id);
                        getClassroomStudents(res[index].id, false);
                    } else {
                        setSelectedClassroomOption(`${res[0].degree}º ${res[0].division}`);
                        setSelectedClassroomId(res[0].id);
                        getClassroomStudents(res[0].id, false);
                    }
                }
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        } else {
            const req = await GET(`/classes/classrooms/bySchoolYear/${moment().format('YYYY')}`, getUserToken());
            const res = await req.json();

            if (req.status === 200) {
                console.log(res)
                setClassrooms(res);
                if (res.length > 0) {
                    let classroomIdUrl = getParams();
                    if (classroomIdUrl >= 0) {
                        let index = res.findIndex(item => item.id == classroomIdUrl);
                        index = index >= 0 ? index : 0;
                        setSelectedClassroomOption(`${res[index].degree}º ${res[index].division}`);
                        setSelectedClassroomId(res[index].id);
                        getClassroomStudents(res[index].id, false);
                    } else {
                        setSelectedClassroomOption(`${res[0].degree}º ${res[0].division}`);
                        setSelectedClassroomId(res[0].id);
                        getClassroomStudents(res[0].id, false);
                    }
                }
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        }
    }

    const getClassroomStudents = async function (cId, avatars) {
        const req = await GET(`/classes/students/withStatus/byClassroomId/${cId}/${avatars}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setStudents(res);
            setIsTableLoaded(true)
            if (avatars == false) { getClassroomStudents(cId, true); }
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const [showPPIModal, setShowPPIModal] = useState(false);
    const [studentPPI, setStudentPPI] = useState(null);

    const handleClosePPIModal = () => setShowPPIModal(false);
    const handleShowPPIModal = (student) => {
        setStudentPPI(student);
        getClassesByClassroomId(selectedClassroomId, student.id);
    };

    const [classes, setClasses] = useState([]);
    const getClassesByClassroomId = async function (cid, uid) {
        const req = await GET(`/grades/reportSubject/getIsPPIByUserAndClassroomId/${uid}/${cid}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setClasses(res);
            setShowPPIModal(true);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const putModifiedPpi = async function () {
        let classesPPI = [];

        for (let c of classes) {
            classesPPI.push({
                classId: c.classId,
                value: document.getElementById(`checkPPI-${c.classId}`).checked
            })
        }

        const body = {
            userId: studentPPI.id,
            Classes: classesPPI
        }

        const req = await PUT(`/grades/reportSubject/isPPI`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            console.log(res)
            handleClosePPIModal();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const [isLoadingRecord, setIsLoadingRecord] = useState(false);
    const getStudentsRecord = async function () {
        setProgressRecord(0)
        const body = {
            periodId: selectedPeriodId,
            classroomId: selectedClassroomId
        }
        setIsLoadingRecord(true);
        const req = await POST(`/students/studentRecordsFiles/createStudentRecords`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 200) {
            const linkSource = res.b64;
            const downloadLink = document.createElement("a");
            downloadLink.href = linkSource;
            downloadLink.download = `Registro-${selectedClassroomOption}.xlsx`;
            downloadLink.click();
            let timer = setInterval(() => {
                setProgressRecord((prevProgress) => {
                    if (prevProgress >= 100) {
                        clearInterval(timer);  // Detener el temporizador cuando llega a 100%
                        setIsLoadingRecord(false);
                        handleCloseRecordsModal();
                        return 100;  // Asegurarse de que el progreso no supere el 100%
                    } else {
                        return prevProgress + 1;  // Incrementar el progreso
                    }
                });
            }, 40);  // Intervalo de 40ms para el progreso    

        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    useEffect(() => {
        let timer;
        if (isLoadingRecord) {
            // Avanzar hasta el 90% en 7 segundos
            timer = setInterval(() => {
                setProgressRecord((oldProgress) => {
                    if (oldProgress < 90) {
                        return oldProgress + 1;
                    } else {
                        clearInterval(timer);
                        return oldProgress;
                    }
                });
            }, 80); // 70ms por incremento para lograr el 90% en 7 segundos
        }
        return () => clearInterval(timer);
    }, [isLoadingRecord]);

    const [progressRecord, setProgressRecord] = useState(0);
    const [showRecordsModal, setShowRecordsModal] = useState(false);
    const handleShowRecordsModal = () => {
        setIsLoadingRecord(false)
        setProgressRecord(0);
        getPeriods(selectedClassroomId);
        setShowRecordsModal(true);
    };
    const handleCloseRecordsModal = () => {
        setShowRecordsModal(false);
    };

    const [selectedPeriodId, setSelectedPeriodId] = useState(null);
    const [selectedPeriodName, setSelectedPeriodName] = useState("Periodo");
    const [periods, setPeriods] = useState(null);
    const handleSelectPeriod = (period) => {
        setSelectedPeriodId(period.id);
        setSelectedPeriodName(period.name)
    }

    const getPeriods = async function (cid) {
        const req = await GET(`/grades/periods/byClassroomId/noGrouped/${cid}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setPeriods(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const rolesPrimaria = ["Docente Primaria", "Secretaría Primaria", "Director Primaria", "Dirección Primaria", "Apoderado Legal - Primaria"]

    return (
        <div className="app">
            <Navbar />
            <div className="content">
                <Row className='toolbar m-0'>
                    <Col>
                        <span className={'navbar-icon'}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={PAGE_ICON}></FontAwesomeIcon></span>
                        <span className='ms-2'>{PAGE_TITLE}</span>
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <Col className='fitContentWidth'>
                        <div className='align-center-vertically'>
                            <FontAwesomeIcon icon={faChalkboardTeacher} className='dropDownIcon' style={{ height: 16 }} />
                            <Dropdown className='dropdownButton'>
                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-100">
                                    {selectedClassroomOption}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedClassroomOption}>
                                    {classrooms.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelect(i)}>{opt.degree}º {opt.division}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Col>
                    {rolesPrimaria.find(r => r == myRole) ? <Col className='endRow'>
                        <ActionButton iconFa={faDownload} title='Descargar registro' variant='secondary' func={handleShowRecordsModal} />
                    </Col> : ''}
                    {/*<Col className='endRow fitContentWidth'>
                        <Link to={`/asistencia-curso/?id=${selectedClassroomId}`} style={{ textDecoration: 'none', }} >
                            <Button variant='primary'><FontAwesomeIcon className='mr-10' icon={faPen} />Tomar asistencia</Button>
                        </Link>
                    </Col>*/}
                </Row>

                <Card className='cards mt-3'>
                    {!isTableLoaded ? <div className='justify-content-center'>
                        <Loading animation={'border'} classExtra='loading-100' />
                    </div> : <><div>
                        <Row className='p-2 mb-2' style={{ color: 'white', backgroundColor: '#3680F4', borderRadius: 8 }}>
                            <Col md={1} className='align-center-vertically-v2'>
                                <div style={{ textAlign: 'center' }}>
                                    #
                                </div>
                            </Col>
                            <Col md={5} className='align-center-vertically-v2'>
                                <div>
                                    Nombre
                                </div>
                            </Col>
                            <Col md={3} className='align-center-vertically-v2'>
                                <div style={{ textAlign: 'center' }}>
                                    Condición
                                </div>
                            </Col>
                            <Col md={3} className='align-center-vertically-v2'>
                                <div style={{ textAlign: 'center' }}>
                                </div>
                            </Col>
                        </Row>
                    </div>

                        {students.map((r, i) => (
                            <div key={i}>
                                <div className='d-none d-lg-block'>
                                    <Row className='mb-1 ps-2 pe-2 pt-1 pb-1' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                        <Col md={1} className='align-center-vertically-v2'>
                                            <div style={{ textAlign: 'center' }}>
                                                {1 + i}
                                            </div>
                                        </Col>
                                        <Col md={5} className='align-center-vertically-v2'>
                                            {myRole != "" && myRole != "Docente Secundaria" && myRole != "Docente Primaria" ? <Link to={`/estudiante/?id=${r.id}`} className='studentLink link-no-deco'><div style={{ textAlign: 'center' }} className='align-center-vertically-v2'>
                                                <div className='align-center-vertically'>
                                                    <div className='fitContentWidth'>
                                                        <div className='studentImg circle-container me-2' style={{ borderColor: 'white', background: 'white' }}>
                                                            {r.avatarFile ? <img src={r.avatarFile} alt={r.name} /> : <Loading animation={'grow'} bsSize='sm' />}
                                                        </div>
                                                    </div>
                                                    {r.surname ? r.surname.toUpperCase() : ""}, {r.name}
                                                </div>
                                            </div></Link> :
                                                <div style={{ textAlign: 'center' }} className='align-center-vertically-v2'>
                                                    <div className='align-center-vertically'>
                                                        <div className='fitContentWidth'>
                                                            <div className='studentImg circle-container me-2' style={{ borderColor: 'white', background: 'white' }}>
                                                                {r.avatarFile ? <img src={r.avatarFile} alt={r.name} /> : <Loading animation={'grow'} bsSize='sm' />}
                                                            </div>
                                                        </div>
                                                        {r.surname ? r.surname.toUpperCase() : ""}, {r.name}
                                                    </div>
                                                </div>
                                            }
                                        </Col>
                                        <Col md={3} className='align-center-vertically' style={{ justifyContent: 'center' }}>
                                            {r.ppi && <Badge bg='warning' className='statusBadge fs-14 wi-100 me-2'>PPI</Badge>}
                                            <div style={{ textAlign: 'center' }}>
                                                {r.status == 'REGULAR' ? <Badge bg='success' className='statusBadge fs-14 wi-100'>Regular</Badge> : ''}
                                                {r.status == 'LIBRE' ? <Badge bg='danger' className='statusBadge fs-14 wi-100'>Libre</Badge> : ''}
                                                {r.status == 'REPITE' ? <Badge bg='dark' className='statusBadge fs-14 wi-100'>Repite</Badge> : ''}
                                            </div>
                                        </Col>

                                        <Col md={3} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                {/* <ActionButton variant='outline-secondary' title='Constancia' iconFa={faPrint} /> */}
                                                <div style={{ display: 'flex', justifyContent: 'end' }}>
                                                    <Dropdown className='ms-2'>
                                                        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                                            <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            {/* <Dropdown.Item ><Link to={`/cursos/alumno/?id=${r.id}`} className='link-no-deco link-no-hover dotsRow'><FontAwesomeIcon icon={faEye} style={{ marginRight: 10, marginLeft: 2 }} />Ver alumno</Link></Dropdown.Item> */}
                                                            <Dropdown.Item onClick={() => handleShowPPIModal(r)}><FontAwesomeIcon icon={faBookReader} style={{ marginRight: 10, marginLeft: 2 }} />PPI</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        ))}
                    </>}
                </Card>
            </div>

            <Modal size={''} show={showPPIModal} onHide={handleClosePPIModal} centered backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropDownIcon' icon={faBookReader} style={{ minWidht: '20px' }} /><Modal.Title>PPI</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {studentPPI != null && <>
                        <InfoField title='Alumno/a' extraClass='mb-3' content={
                            <div className='align-center-vertically'>
                                <div className='fitContentWidth'>
                                    <div className='studentImg circle-container me-2' style={{ borderColor: 'white', background: 'white' }}>
                                        {studentPPI.avatarFile ? <img src={studentPPI.avatarFile} alt={studentPPI.name} /> : <Loading animation={'grow'} bsSize='sm' />}
                                    </div>
                                </div>
                                {studentPPI.surname ? studentPPI.surname.toUpperCase() : ""}, {studentPPI.name}
                            </div>}></InfoField>
                        {classes.map((r, i) => (
                            <Row key={i} className='mb-1 ps-2 pe-2 pt-1 pb-1' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                <Col md={11} className='align-center-vertically-v2 p-0'>
                                    <div className='align-center-vertically' >
                                        {r.name}
                                    </div>
                                </Col>
                                <Col md={1} className='p-0'>
                                    <Form.Check type="switch" className="endRow" id={`checkPPI-${r.classId}`} defaultChecked={r.isPPI} />
                                </Col>
                            </Row>
                        ))}</>}
                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14' onClick={handleClosePPIModal}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cerrar</Button>
                    <Button variant="primary" onClick={putModifiedPpi} className='fs-14' ><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showRecordsModal} onHide={handleCloseRecordsModal} centered backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropModalCont dropDownIcon' icon={faFileExport} style={{ minWidht: '20px' }} /><Modal.Title>Registro académico</Modal.Title>
                </Modal.Header>
                <Modal.Body className='pt-4 pb-4'>
                    {!isLoadingRecord ? <><InfoField content={selectedClassroomOption} title='Curso' />
                        <div className='align-center-vertically mt-3'>
                            <FontAwesomeIcon icon={faCalendar} className='dropDownIcon' style={{ height: 16 }} />
                            <Dropdown className='dropdownButton'>
                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-300">
                                    {selectedPeriodName}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedPeriodName}>
                                    {periods && periods.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelectPeriod(opt)}>{opt.name}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div></>
                        :
                        <div className='align-center-vertically' style={{ justifyContent: 'center', height: '83px' }}>
                            <div className=''>
                                <div className='justify-content-center mb-2'>
                                    {/* <Loading animation={'border'} classExtra='' /> */}
                                    <ProgressBar striped animated now={progressRecord} label={`${progressRecord}%`} style={{ width: '100%', animation: 'progress-bar-stripes 1s linear infinite !important' }} className='custom-progress-bar' />
                                </div>
                                <p className='mb-0' style={{ color: '#0480FD' }}>Estamos preparando el archivo...</p>
                            </div>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14 me-2' onClick={handleCloseRecordsModal}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                    <Button variant="primary" className='fs-14 me-2' disabled={selectedPeriodId == null || isLoadingRecord} onClick={getStudentsRecord}>
                        {!isLoadingRecord ?
                            <><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</> : <div className="justify-content-center" style={{ width: '85px' }}>
                                <Loading animation={'grow'} size='20px' bsSize='sm' variant='light' classExtra='justify-content-center' />
                            </div>}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div >
    )
}

export default Courses