import React, { useEffect, useState } from 'react'
import { Form, Button, Container, Row, Col, Modal, Badge, Alert, Card } from "react-bootstrap";
import { POST, GET } from '../../../controllers/endpoints.controller';
import { getUserToken, logout, saveChildToken, saveUserData } from '../../../controllers/user.controller';
import { Link } from 'react-router-dom';
import { formatName, formatNameV2 } from '../../../tools/tools';
import Loading from '../../general-components/Loading';

export default function ChooseChild() {

    useEffect(() => {
        document.title = `Tasky`;
        getMyChildren();
    }, []);

    const [myChildren, setMyChildren] = useState(null)
    const getMyChildren = async function () {
        const req = await GET(`/sso/families/members/children/mine`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            let a = res;
            setMyChildren(a);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const selectChild = async function (user) {
        localStorage.setItem('familyMemberId', user.id);
        localStorage.setItem('familyMemberName', user.name);
        localStorage.setItem('familyMemberSurname', user.surname);
        localStorage.setItem('familyMemberAvatarFile', user.avatarFile);
        localStorage.setItem('familyMembersAmount', myChildren.length);
        saveChildToken(user.id)
    }

    return (
        <div className='login-bigContainer' style={{backgroundColor:'#F8F9FC'}}>
            <Row>
                <div className="login-container">
                    <div className="login-card">
                        <div className="logo">
                            <img src="/logo_color.svg" width={110} alt="Tasky Logo" />
                        </div>
                        <p className='mt-4 mb-4 fs-14'>Seleccioná un estudiante:</p>
                        <Row style={{rowGap:'6px'}}>
                        {myChildren!=null && myChildren.map((r, i) => (
                            <Col style={{ minWidth: '100px' }} md={4} xs={4} className='pe-1 ps-1'>
                                <Card className='cards p-0 pointer' onClick={() => selectChild(r)} style={{ overflow: 'hidden', position: 'relative' }}>
                                    <img style={{ height: '100%', width: '100%' }} src={r.avatarFile}></img>
                                    <Badge bg='' style={{ backgroundColor: '#3680F4', color: 'white', position: 'absolute', bottom: '5px', left: '5px', fontWeight:'400' }}>{formatName(r.name, '')}</Badge>
                                </Card>
                            </Col>
                        ))}
                        </Row>
                    </div>
                </div>
            </Row>



        </div>
    );
}