import React, { useEffect, useState } from 'react'
import Navbar from '../../navbar/Navbar'
import { Badge, Button, Card, Col, Container, Dropdown, DropdownMenu, Form, Modal, Row, Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faChalkboardTeacher, faCheck, faCog, faEllipsisV, faPen, faPlus, faTrash, faUser, faPrint, faFileExport, faClipboardUser, faCross, faTimesRectangle, faTimes, faSearch } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import ActionButton from '../../general-components/ActionButton'
import { getUserToken, logout, validateToken } from '../../../controllers/user.controller'
import { useNavigate } from 'react-router-dom';
import { DEL, GET, POST } from '../../../controllers/endpoints.controller';
import Loading from '../../general-components/Loading';

function Students() {
    const PAGE_TITLE = "Alumnos";
    const PAGE_ICON = faCog;
    const PREVIOUS_PAGE = "Configuración - Cursos";
    const PREVIOUS_URL = "/configuracion/cursos"

    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1); // Navega hacia atrás en el historial
    };

    const getParams = function (keyName) { //'schoolYear', 'courseId', 'courseName'
        const params = new URLSearchParams(window.location.search);
        if (params.has(keyName)) {
            console.log(keyName + ' = ' + params.get(keyName))
            return params.get(keyName);
        } else {
            console.log("else")
            return -1;
        }
    }

    useEffect(() => {
        document.title = `Tasky - ${PAGE_TITLE}`;
        validateToken();

        setClassroomId(getParams('courseId'))
        setSchoolYear(getParams('schoolYear'))
        setSelectedCourseOption(getParams('courseName'))

        getClassrooms(getParams('schoolYear'));

        getClassroomStudents(getParams('courseId'), false)
    }, []);

    const [classroomId, setClassroomId] = useState("");
    const [schoolYear, setSchoolYear] = useState("");
    const [users, setUsers] = useState([]);

    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [deleteStudentId, setDeleteStudentId] = useState(null);
    const [deleteStudentName, setDeleteStudentName] = useState("");
    const handleCloseConfirmDeleteModal = () => setShowConfirmDeleteModal(false);
    const handleShowConfirmDeleteModal = (id, name) => {
        setDeleteStudentId(id);
        setDeleteStudentName(name);
        setShowConfirmDeleteModal(true);
    };

    //Modal Añadir alumnos
    const [showAddStudents, setShowAddStudents] = useState(false);
   
    const handleShowAddStudents = () => {
        setShowAddStudents(true)
    };

    const handleCloseModalAddStudents = () => {
        setShowAddStudents(false);
    }

    useEffect(() => {
        document.title = `Tasky - ${PAGE_TITLE}`;
    }, []);


    const [classrooms, setClassrooms] = useState([]);
    const [selectedCourseOption, setSelectedCourseOption] = useState("");

    const handleSelectCourse = (eventKey) => {
        setSelectedCourseOption(`${classrooms[eventKey].degree}º ${classrooms[eventKey].division}`);
        setClassroomId(classrooms[eventKey].id);

        getClassroomStudents(classrooms[eventKey].id, false)
    };


    const [students, setStudents] = useState([]);

    const getClassrooms = async function (schoolYearId) {
        const req = await GET(`/classes/classrooms/bySchoolYear/${schoolYearId}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setClassrooms(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getClassroomStudents = async function (cId, avatars) {
        const req = await GET(`/classes/students/withStatus/byClassroomId/${cId}/${avatars}`, getUserToken());
        const res = await req.json();
        console.log('Clasroom students cid: ' + cId)
        if (req.status === 200) {
            console.log(res)
            setStudents(res);
            if(avatars == false){getClassroomStudents(cId, true);}
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const searchPossibleStudents = async function () {
        const chain = document.getElementById('textSearch').value;

        const req = await GET(`/students/search/withoutClassroom/byClassroomId/${classroomId}/${chain == "" ? 'null' : chain}`, getUserToken());

        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setUsers(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }

    }

    const postAddStudent = async function (userId) {
        const body = {
            userId: userId,
            classroomId: classroomId
        }

        const req = await POST(`/classes/classroom/student`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            console.log(res)
            getClassroomStudents(classroomId, true);
            handleCloseModalAddStudents();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const deleteStudentFromClassroom = async function () {
        const body = {
            classroomId: classroomId,
            userId: deleteStudentId
        }

        const req = await DEL(`/classes/classroom/student/`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            handleCloseConfirmDeleteModal();
            getClassroomStudents(classroomId, true);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    return (
        <div className="app">
            <Navbar />
            <div className="content fs-14">
                <Row className='toolbar m-0'>
                    <Col>
                        {PREVIOUS_PAGE != "" ?
                            <Link className='link-primary' onClick={goBack}>
                                <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver
                            </Link>
                            : ``}

                        <span className={`navbar-icon ${PREVIOUS_PAGE != "" ? `ms-4` : ``}`}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={PAGE_ICON}></FontAwesomeIcon></span>
                        <span className='ms-2'>{PREVIOUS_PAGE != "" ? `${PREVIOUS_PAGE} - ` : ``}{PAGE_TITLE}</span>
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <Col>
                        <div className='align-center-vertically '>
                            <FontAwesomeIcon icon={faChalkboardTeacher} className='dropDownIcon' style={{ height: 16 }} />
                            <Dropdown className='dropdownButton'>
                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-150">
                                    {selectedCourseOption}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedCourseOption}>
                                    {classrooms.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelectCourse(i)}>{opt.degree}º {opt.division}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Col>
                    <Col className='endRow'>
                        <ActionButton iconFa={faPlus} title={'Añadir alumnos'} func={handleShowAddStudents} classExtra='fs-14' />
                    </Col>
                </Row>

                <Card className='cards mt-3 fs-14'>
                    <div>
                        <Row className='p-2 mb-2' style={{ color: 'white', backgroundColor: '#3680F4', borderRadius: 8 }}>
                            <Col md={1} className='align-center-vertically-v2'>
                                <div style={{ textAlign: 'center' }}>
                                    #
                                </div>
                            </Col>
                            <Col md={8} className='align-center-vertically-v2'>
                                <div>
                                    Nombre
                                </div>
                            </Col>
                            <Col md={2} className='align-center-vertically-v2'>
                                <div style={{ textAlign: 'center' }}>
                                    Condición
                                </div>
                            </Col>
                            <Col md={1} className='align-center-vertically-v2'>
                                <div style={{ textAlign: 'center' }}>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    {students.map((r, i) => (
                        <div key={i}>
                            <div className='d-none d-lg-block'>
                                <Row className='mb-1 pb-1 pt-1 ps-2 pe-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                    <Col md={1} className='align-center-vertically-v2'>
                                        <div style={{ textAlign: 'center' }}>
                                            {1 + i}
                                        </div>
                                    </Col>
                                    <Col md={8} className='align-center-vertically-v2'>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div className='fitContentWidth p-1'>
                                                <div className='studentImg circle-container' style={{ borderColor: 'white' }}>
                                                {r.avatarFile ? <img className='' src={r.avatarFile} alt={r.name} /> : <Loading animation={'grow'} bsSize='sm'/>}
                                                </div>
                                            </div>
                                            <span className='ms-1'>{r.surname ? r.surname.toUpperCase() : ""}, {r.name}</span>
                                        </div>
                                    </Col>
                                    {/*<Col md={2} className='align-center-vertically-v2'>
                                        <div style={{ textAlign: 'center' }}>
                                            <Badge bg='' style={{ background: r.condition.color }} className='statusBadge fs-14'>{r.condition.text}</Badge>
                                        </div>
                                    </Col>*/}
                                    <Col md={2} className='align-center-vertically-v2'>
                                        <div style={{ textAlign: 'center' }}>
                                        {r.status == 'REGULAR' ? <Badge bg='success' className='statusBadge fs-14 wi-100'>Regular</Badge> : ''}
                                            {r.status == 'LIBRE' ? <Badge bg='danger' className='statusBadge fs-14 wi-100'>Libre</Badge> : ''}
                                            {r.status == 'REPITE' ? <Badge bg='dark' className='statusBadge fs-14 wi-100'>Repite</Badge> : ''}
                                        </div>
                                    </Col>
                                    <Col md={1} className='align-center-vertically-v2'>
                                        <div style={{ display: 'flex', justifyContent: 'end' }}>
                                            <Dropdown className='ms-2'>
                                                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                                    <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => handleShowConfirmDeleteModal(r.id, `${r.surname ? r.surname.toUpperCase() : ""}, ${r.name}`)} ><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    ))}
                </Card>
            </div>

            {/* Añadir alumnos*/}
            <Modal size='lg' show={showAddStudents} onHide={handleCloseModalAddStudents} centered backdrop="static">
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropModalCont dropDownIcon' icon={faChalkboardTeacher} style={{ minWidht: '20px' }} /><Modal.Title>Añadir alumnos</Modal.Title>
                </Modal.Header>
                <Modal.Body className='fs-14'>
                    <Row>
                        <div className='align-center-vertically'>
                            <Form.Control className='textInputGray fs-14 wi-300' placeholder="Buscar..." id="textSearch" />
                            <ActionButton iconFa={faSearch} title='Buscar' classExtra='ms-2' func={searchPossibleStudents} />
                        </div>
                    </Row>
                    <div>

                        <Row className='mt-4 pt-2 pb-2 mb-2' style={{ color: 'white', backgroundColor: '#3680F4', borderRadius: 8 }}>
                            <Col md={1} className='align-center-vertically-v2'>
                                <div style={{ textAlign: 'center' }}>
                                    #
                                </div>
                            </Col>
                            <Col md={9} className='align-center-vertically-v2' >
                                <div>
                                    Nombre
                                </div>
                            </Col>

                            <Col md={2} className='align-center-vertically-v2' >

                            </Col>
                        </Row>

                        {users.map((r, i) => (
                            <div>
                                <div className='d-none d-lg-block'>
                                    <Row className='mb-1 pt-1 pb-1 ps-2 pe-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                        <Col md={1} className='align-center-vertically-v2'>
                                            <div style={{ textAlign: 'center' }}>
                                                {1 + i}
                                            </div>
                                        </Col>
                                        <Col md={9} className='align-center-vertically-v2'>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>

                                                <div className='studentImg circle-container me-2' style={{ borderColor: 'white' }}>
                                                    <img src={r.avatarFile} alt={r.name} />
                                                </div>

                                                <p className='classesTime m-0'><p className='m-0'>{r.surname ? r.surname.toUpperCase() : ""}, {r.name}</p><p className='m-0' style={{ fontSize: 11, color: 'gray' }}></p></p>

                                            </div>
                                        </Col>
                                        <Col md={2} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                            <div style={{ display: 'flex', justifyContent: 'end' }}>
                                                <Button variant="primary" className='fs-14' onClick={() => postAddStudent(r.id)}><FontAwesomeIcon className='mr-10' icon={faCheck} />Añadir</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        ))}
                    </div>
                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14' onClick={handleCloseModalAddStudents}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cerrar</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showConfirmDeleteModal} onHide={handleCloseConfirmDeleteModal} centered backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropModalCont dropDownIcon' icon={faTrash} style={{ minWidht: '20px' }} /><Modal.Title>Eliminar</Modal.Title>
                </Modal.Header>
                <Modal.Body className='pt-4 pb-4'>
                    <span className=''>¿Deseas eliminar del curso a {deleteStudentName}?</span>
                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14 me-2' onClick={handleCloseConfirmDeleteModal}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                    <Button variant="danger" className='fs-14 me-2' onClick={deleteStudentFromClassroom}><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Students