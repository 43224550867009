import React, { useEffect, useState } from 'react'
import Navbar from '../../navbar/Navbar'
import { Badge, Card, Col, Dropdown, DropdownMenu, Row, Table, Button, Modal, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChalkboardTeacher, faCheck, faCheckSquare, faClipboardUser, faEllipsisV, faEnvelope, faEye, faFile, faFileExport, faGraduationCap, faHeading, faIdCard, faImage, faPen, faPlus, faPrint, faSearch, faSortNumericUp, faTimes, faTrash, faUser, faUserFriends, faUsers } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import ActionButton from '../../general-components/ActionButton'
import { getUserRole, getUserToken, logout, validateToken } from '../../../controllers/user.controller'
import { faLetterboxd } from '@fortawesome/free-brands-svg-icons'
import { GET } from '../../../controllers/endpoints.controller'
import Loading from '../../general-components/Loading'

function Regulation() {
    const PAGE_TITLE = "Reglamento - Nivel Medio";
    const PAGE_ICON = faFile;

    useEffect(() => {
        document.title = `Tasky - ${PAGE_TITLE}`;
        validateToken();

    }, []);

    return (
        <div className="app">
            <Navbar />
            <div className="content">
                <div className='d-block d-lg-none' style={{ height: '70px' }}> </div>

                <Row className='toolbar m-0'>
                    <Col>
                        <span className={'navbar-icon'}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={PAGE_ICON}></FontAwesomeIcon></span>
                        <span className='ms-2'>{PAGE_TITLE}</span>
                    </Col>
                </Row>

                <Card className='mobile-cards cards mt-3'>
                    <div>
                        <Row>
                            <Col>
                                <img src="https://tasky.digital/img/caba_svp/svp.svg" height={80}></img>
                            </Col>
                            <Col className='align-center-vertically-v2' style={{textAlign: 'right'}}>
                                <img src="https://tasky.digital/img/caba_svp/red_fasta.svg" height={25}></img>
                            </Col>
                        </Row>

                        <p className='mt-4 mb-0' style={{fontSize: 20, textAlign: 'center', fontWeight: 'bold'}}>RÉGIMEN DE CONVIVENCIA DEL NIVEL MEDIO</p>
                        <p style={{textAlign: 'center'}}><i><b>Estilo formativo y Régimen de convivencia de nuestra comunidad educativa</b></i></p>

                        <p className='mt-4'><i><b>1. Principios fundamentales</b></i></p>

                        <p><b>A. El Colegio Fasta San Vicente de Paúl tiene como fines generales</b></p>

                        <p>1. Además de los fines que le determina el Ministerio de Educación a través de la DGEGP, se constituye de acuerdo al Magisterio de la Iglesia en tanto escuela como lugar de formación integral mediante la asimilación sistemática y crítica de la cultura.</p>
                        <p>2. En cuanto escuela católica es un espacio de la misma Iglesia de la que recibe la misión de anunciar la Buena Nueva, realizar la síntesis entre fe y cultura, fe y ciencia, fe y vida, y salvar las almas de todos los integrantes de la comunidad educativa.</p>
                        <p>3. El Colegio forma parte de la Fraternidad de Agrupaciones Santo Tomás de Aquino (Fasta), Asociación Internacional de Fieles de Derecho Pontificio, de la que recibe a su vez la espiritualidad dominicana (Orden de Predicadores). Fasta tiene como objetivo la evangelización de la cultura, la familia y la juventud, tarea que se nutre de los valores hispano-católicos, todo lo cual confiere al Colegio un modo educativo peculiar que se manifiesta en un estilo cuyas notas distintivas son:</p>
                        <ul>
                            <li>Formación integral centrada en las virtudes tanto intelectuales como morales.</li>
                            <li>Configuración del ser y del obrar en la Verdad como dimensión de la Caridad a través de una sólida formación doctrinal que le permita discernir la realidad desde una cosmovisión cristiana y actuar en ella coherentemente.</li>
                            <li>Formación del joven en su propia naturaleza, lo cual implica desarrollar las virtudes específicas de su condición de varón o mujer que sustentarán su vocación y misión.</li>
                            <li>Profundo y sereno amor a Dios y a la Patria, entendida ésta como valor providencial, que exige formar al varón y a la mujer en su dimensión de ciudadanos, capaces de estar en permanente actitud de servicio y comunicación de nuestra cultura católica.</li>
                        </ul>

                        <p><b>B. Perfil general del alumno/a</b></p>

                        <p>El Colegio Fasta San Vicente de Paúl se compromete a promover la formación personal, integral, humana y cristiana de un alumno que a lo largo del proceso educativo y respetando las diversas edades de maduración se caracteriza por:</p>

                        <ul>
                            <li>Buscar el cultivo permanente de su inteligencia en las virtudes intelectuales que le permitan un proceso autónomo de acercamiento a la verdad.</li>
                            <li>Buscar el cultivo permanente de su voluntad en las virtudes morales de la prudencia, templanza, fortaleza y justicia de tal modo que no sólo ame el bien sino que lo realice.</li>
                            <li>Ejercitar plenamente la libertad siendo consciente de la responsabilidad de sus actos y asumiendo las consecuencias de los mismos.</li>
                            <li>Conocer la cosmovisión católica de la realidad y realizar una síntesis personal entre fe y cultura.</li>
                            <li>Estar al servicio permanente de la Iglesia y de la Patria.</li>
                            <li>Comprometerse con su vocación según su naturaleza de varón y mujer desde su condición de laico.</li>
                            <li>Ser piadoso y confiado en la Providencia divina.</li>
                        </ul>

                        <p><b>C. La Familia: primera educadora</b></p>

                        <p>Los padres son los primeros responsables de la educación de sus hijos.</p>
                        <p>La familia es la primera evangelizadora de los hijos y la primera escuela de virtudes.</p>
                        <p>Los padres eligen el colegio conociendo el Proyecto Educativo del mismo; se identifican y se comprometen con él.</p>
                        <p>Acompañan y colaboran con el proceso formativo de sus hijos fortaleciendo con sus actitudes y acciones los vínculos y la coherencia entre el mensaje familiar y el escolar.</p>
                        <p>Respetan la autonomía del Colegio en la conducción del mismo. Esto no excluye que ésta se ejerza en un clima de coparticipación responsable.</p>
                        <p>Conocen y aceptan el régimen de convivencia del Colegio, sus principios y las normas que de él se desprenden.</p>
                        <p>Cumplen con las normas que a ellos les caben como padres de este Colegio:</p>

                        <ol type="a">
                            <li>Asistir a las convocatorias que las autoridades del nivel o que los docentes de sus hijos les realizaran.</li>
                            <li>Notificarse diariamente de los informes sobre conducta, rendimiento académico, identificación institucional, etc.</li>
                            <li>Realizar las acciones de ayuda, acompañamiento o tratamiento que el equipo directivo hubiera estimado conveniente para la continuidad del proceso educativo de su hijo en el Colegio.</li>
                            <li>Aceptar la decisión que el Consejo Superior hubiere tomado respecto a la no continuidad de su hijo en el Colegio si esto significase un obstáculo para el cumplimiento de los objetivos pedagógico-formativos, a nivel personal, y también aquellos institucionales del Colegio.</li>
                            <li>Promover en sus hijos el respeto del reglamento interno como instrumento de formación.</li>
                            <li>Participar activamente de la vida y la mística institucional a través de los canales formales y de los informales con los que cuenta el Colegio.</li>
                            <li>Generar propuestas y acciones que contribuyan al cumplimiento de los objetivos generales del Colegio.</li>
                        </ol>

                        <p><b>D. Docentes</b></p>

                        <p>Son laicos católicos comprometidos con la formación integral, personal, humana y cristiana de los niños y los jóvenes. Desde esta condición de bautizados asumen la misión de evangelización y educación.</p>
                        <p>Conocen y aceptan el Proyecto Educativo Institucional y se comprometen a realizarlo según los criterios y normas que el Colegio establece en el mismo.</p>
                        <p>Aman a los niños y a los jóvenes y buscan su bien.</p>
                        <p>Poseen las cualidades profesionales que los habilitan para desempeñarse como docentes.</p>
                        <p>Se esfuerzan por ser testimonio vivo de las cualidades y virtudes que intentan desarrollar en el alumno siendo conscientes de la influencia que ejercen sobre ellos como modelos.</p>
                        <p>Son autoridad formativa en el Colegio y su legitimidad estará respaldada por los directivos de nivel.</p>
                        <p>Esto en virtud de la total coincidencia de la actitud y tarea del docente con el Proyecto Educativo Institucional.</p>
                        <p>Se preocupan porque quede iluminado por la fe el conocimiento que los alumnos van adquiriendo de la cultura, de la ciencia y de la vida.</p>
                        <p>Tienen cualidades que les permiten desarrollar su tarea en equipo volcando sus talentos personales al servicio del bien común del nivel en el que se desempeñan. Ejercitan las virtudes de la solidaridad, la honestidad y la humildad con sus colegas y con los directivos.</p>
                        <p>Poseen una actitud positiva frente a los procesos de actualización y perfeccionamiento docente que la elaboración del proyecto educativo y el proyecto curricular requieren.</p>
                        <p>Mantienen un estrecho contacto y comunicación con los padres de los alumnos a fin de realizar una tarea coherente y efectiva de formación.</p>
                        <p>Realizan sus obligaciones de planificación, preparación de cada clase, elaboración de los modelos de evaluación, corrección, confección de registros, y de todos aquellos documentos y procesos de control según el nivel y condición correspondiente, en tiempo y forma.</p>

                        <p><b>E. Directivos</b></p>

                        <p>Son laicos católicos comprometidos con la formación integral, personal, humana y cristiana de los niños y los jóvenes. Desde esta condición de bautizados asumen la misión de evangelización y educación.</p>
                        <p>Son los responsables últimos del proceso formativo integral en su nivel y de la realización concreta del bien común del Colegio Fasta San Vicente de Paúl.</p>
                        <p>Conducen, defienden, promueven, animan y evalúan el logro de los objetivos generales de su nivel. Son los garantes de la fidelidad a los principios y objetivos del Colegio en su nivel.</p>
                        <p>Entienden y ejercen la autoridad como servicio.</p>
                        <p>Son los líderes pedagógicos de su nivel en cuanto animan a su equipo docente a realizar, mejorar y comprometerse con el Proyecto Educativo Institucional.</p>
                        <p>Aman a los niños y a los jóvenes y buscan su bien. Es éste el criterio fundamental de sus decisiones como directivos.</p>
                        <p>Poseen cualidades que les permiten trabajar en equipo siendo ésta una condición indispensable de la cultura actual.</p>
                        <p>Viven la actualización y el perfeccionamiento profesional como parte de las exigencias naturales de su misión.</p>
                        <p>Se esfuerzan por cultivar las virtudes morales e intelectuales.</p>
                        <p>Son coherentes con los principios católicos en su vida personal e institucional. Cultivan la oración y la vida sacramental.</p>
                        <p>Manifiestan en sus decisiones prudencia y sentido común, y ejercitan la justicia y la caridad como tono fundamental de su conducción.</p>
                        <p>Son los primeros en el cumplimiento de sus obligaciones y sobrios en el uso de sus derechos.</p>
                        <p>Poseen una actitud comunicativa y favorecen el estrechamiento de los vínculos con los padres. Reciben la problemática familiar y encauzan las dificultades que advierten en los alumnos a fin de que se instrumenten los medios necesarios y convenientes para su correcto proceso de formación.</p>
                        <p>Tienen un contacto estrecho con los alumnos y se esfuerzan por conocer a cada uno y su singularidad.</p>

                        <p><b>F. Alumnos/as</b></p>

                        <p>Los alumnos del Colegio Fasta San Vicente de Paúl, teniendo como meta los objetivos institucionales generales y el perfil general y siendo protagonistas de su propia formación de acuerdo a la etapa y edad madurativa en la que se encuentren, se empeñarán en:</p>

                        <ol type="a">
                            <li>Vivir en adhesión e identificación con el estilo formativo de la Institución.</li>
                            <li>Demostrar interés por su formación integral cumpliendo con los deberes y exigencias que emanan de este Proyecto educativo.</li>
                            <li>Poner especial empeño en el cultivo de la virtud del estudio, siendo ésta una nota distintiva del estilo educativo del Colegio.</li>
                            <li>Esforzarse por ejercitar las virtudes morales de la prudencia, fortaleza, paciencia, respeto, templanza en los afectos, justicia, compañerismo, solidaridad, responsabilidad, piedad, patriotismo, humildad, magnanimidad.</li>
                            <li>Respetar y cumplir los criterios de convivencia del Colegio y el reglamento interno del mismo en lo que a sus obligaciones se refiere.</li>
                        </ol>

                        <p><i><b>2. Estilo de vida de nuestra comunidad educativa</b></i></p>

                        <p>La comunidad educativa del Colegio Fasta San Vicente de Paúl promueve un estilo de convivencia basado en el ejercicio de las virtudes personales y comunitarias; cuyo fin es la realización del bien común institucional, y cuyo fundamento es la caridad cristiana, que parte del reconocimiento de la igualdad esencial en la dignidad de todas las personas, de la diversidad de misiones, tareas y responsabilidades, de los diversos grados de participación de ese bien común, a saber:</p>
                        <p>Nivel de información, nivel de opinión, nivel de consulta, nivel de decisión, que corresponderán a cada uno según la tarea y la responsabilidad que asume en el todo; cuyo principio ordenador es la autoridad.</p>
                        <p>En este sentido, las normas que conforman el Régimen de convivencia del Colegio son entendidas como instrumentos al servicio del logro efectivo del bien común y su contenido refleja el estilo de nuestro Colegio. Las normas son, por lo tanto, elementos formativos.</p>
                        <p>Señalan el camino del bien y los canales aptos para ejercitar las virtudes que en definitiva son el objetivo de nuestra educación. Buscan que cada uno de los integrantes de la comunidad educativa realice su bien personal y al mismo tiempo contribuya a la realización del común.</p>
                        <p>Son criterios de orden. Se basan en la racionalidad ya que no parten de voluntades subjetivas y cambiantes sino del conocimiento sereno y prudente de la realidad sobre la que tratan.</p>
                        <p>Cada norma tiene como fundamento un valor o bien. Es por esto que el incumplimiento de la norma no sólo será entendido como una falta sino como la negación de un valor o principio que merece ser respetado y vivido.</p>
                        <p>Este régimen de convivencia será sostenido, defendido y desarrollado por los formadores a partir de una actitud preventiva ante la falta o el incumplimiento, lo que significa básicamente que se tenderá por todos los medios a crear un clima que favorezca el ejercicio natural de la virtud y se procurará prever las condiciones o tendencias negativas que pudieran surgir en los alumnos para actuar convenientemente antes de que sucedan los hechos.</p>
                        <p>En concreto:</p>
                        <p>La vida comunitaria se esforzará por promover una relación personal entre el docente y el alumno basada en el conocimiento y en el amor.</p>
                        <p>El educador se esforzará por presentarse como causa ejemplar de los bienes y virtudes que la institución sostiene de tal modo que el alumno se sienta atraído a imitarlo por su obrar y se genere un clima educativo favorable para su práctica.</p>
                        <p>La organización escolar promoverá progresivamente el crecimiento del alumno en libertad y autonomía de tal modo que éste sea cada vez más responsable de sus actos y de las consecuencias de los mismos.</p>
                        <p>Se explicará en todos los casos el sentido y la racionalidad de la norma mostrando en cada ocasión el fundamento formativo de la misma.</p>
                        <p>Se realizarán correcciones personales y en privado antes de pasar a la sanción formal y pública, manteniendo en esto los límites temporales que la prudencia indica.</p>
                        <p>Una vez aplicada la sanción al alumno, se reflexionará con él en un clima de serenidad y convicción sobre los daños personales y a terceros que hubiera causado, de tal modo que comprenda la situación y se comprometa a modificar su conducta.</p>
                        <p>Teniendo en cuenta la gravedad y la situación en la que se desarrolló la falta se le indicará el acto de restitución que correspondiera.</p>
                        <p>El alumno deberá poder encontrar en este sistema de convivencia reconocimientos y consecuencias, y una clara distinción entre lo que ayuda a crecer en la virtud y lo que lo aleja de ella, evitando promover una moral laxa, ambigua o relativista como así también todo resentimiento en su corazón.</p>
                        <p>Las autoridades de nivel tendrán participación directa en los casos en los que la falta así lo requiera.</p>
                        <p>Los procedimientos formales concretos de aplicación y comunicación, y la graduación de sanciones dependerá del nivel educativo.</p>

                        <p><i><b>3. Reglas internas del Colegio Fasta San Vicente de Paúl</b></i></p>

                        <p><b>A. Condiciones generales del Régimen de Asistencia</b></p>

                        <p>1. La asistencia escolar constituye un derecho y un deber del estudiante y es una condición necesaria para que el proceso de enseñanza y de aprendizaje sea posible. El estudiante tiene la obligación de asistir diariamente a clase y de concurrir puntualmente a fin de recibir enseñanza sistemática en el curso, ciclo y/o nivel.</p>
                        <p>2. Al inicio del ciclo lectivo, los adultos autorizados deberán tomar conocimiento del Proyecto Institucional de Seguimiento de la Asistencia, al que suscriben mediante la aceptación del Régimen de Convivencia del Nivel Medio. Con su firma se comprometen a realizar conjuntamente con el establecimiento las diferentes acciones emanadas de ésta, con el objeto de acompañar la asistencia y permanencia de su hijo o tutorado.</p>
                        <p>3. La asistencia y el cumplimiento del horario se registra por jornada escolar completa, conforme al horario escolar establecido por la escuela al comenzar cada ciclo lectivo. Esta tarea estará a cargo del preceptor de cada curso -o su reemplazo docente a cargo de los estudiantes- al iniciar la jornada escolar, registrando los ingresos tardíos y retiros adelantados de cada estudiante. Asimismo, los profesores llevarán el registro de la asistencia de los estudiantes en los espacios curriculares que dicten a los fines del seguimiento pedagógico y la toma de decisiones sobre la promoción, y cotejarán los alumnos presentes con el reporte de asistencia del preceptor. De no estar presente algún alumno que figure como presente en el registro de asistencia, deberá informar inmediatamente al preceptor y/o autoridad del nivel.</p>
                        <p>4. En aquellos espacios curriculares cuyas clases se dictan en contra turno (por ejemplo, las clases de Educación Física), se obrará de la misma manera. El preceptor registrará la asistencia y el cumplimiento del horario de cada estudiante a cada una de ellas, y los retiros anticipados. El docente a cargo realizará su propio control de asistencia a los efectos del seguimiento pedagógico del alumno y la definición acerca de su promoción.</p>
                        <p>5. En los casos en que los estudiantes se vieran impedidos de continuar sus actividades escolares por razones de salud y/o causas de fuerza mayor, se autorizará su retiro únicamente con la presencia del adulto autorizado y/o personal médico. En caso de no estar presente el o los adultos autorizados, un docente y/o directivo acompañará, cuando corresponda, el traslado a una unidad hospitalaria.</p>
                        <p>6. Se entiende por adulto autorizado a aquel que cuenta con la guarda legal del alumno y cuya firma esté registrada, ya sea padre, madre o tutores legales y quienes, con autorización escrita, estén debidamente validados por ellos.</p>

                        <p><b>B. Seguimiento institucional de la asistencia</b></p>

                        <p>1. Condiciones generales</p>
                        <p>1. Es obligación de los padres informar las inasistencias de los alumnos al preceptor y a Secretaría a los efectos de garantizar el derecho a la educación (Ley 26.061, art. 7º). Cuando un estudiante de nivel secundario faltara a clase y los padres no informaran el motivo, podrán ser citados para dejar registrada en un Acta la situación que motiva la inasistencia y lograr los acuerdos que garanticen la continuidad pedagógica del alumno (Ley 26.061, art. 15º). Los tutores serán informados para realizar el seguimiento y registrarlo en el legajo digital del alumno y comunicarán a los directivos.</p>
                        <p>2. Ante la reiteración inasistencias sin informar los motivos o cuando un estudiante registre inasistencias frecuentes a la escuela, el equipo de tutores y/o el Departamento de Orientación intervendrá en la detección de factores de vulnerabilidad escolar y en la propuesta de apoyo a fin de propiciar la permanencia de los estudiantes en la escuela.</p>
                        <p>2.1. El equipo del DOT y los directivos realizarán un análisis de la trayectoria del estudiante y de su situación en relación con los procesos de aprendizaje, a través de la información ya registrada y de la nueva solicitada.</p>
                        <p>2.2. Seguidamente convocarán al estudiante y los adultos autorizados para que el Colegio tome conocimiento y se interiorice acerca de los motivos de las inasistencias y, tanto los adultos autorizados como el alumno, se pongan al corriente de la situación académica del estudiante.</p>
                        <p>2.3. En función de las problemáticas que pudieran suscitarse, la conducción y el Departamento de Orientación evaluarán estrategias para su intervención o derivación a diferentes áreas y renovarán el Acta Compromiso firmada al inicio del ciclo lectivo, detallando las acciones que las partes se comprometen a realizar a fin de garantizar el cumplimento de la educación obligatoria y la apropiación de los saberes necesarios.</p>
                        <p>2.4. Si la madre, padre o tutor no concurriere, se generarán nuevas estrategias a fin de garantizar la comunicación con el adulto autorizado, registrando cada una de las citaciones y las ausencias de los responsables del alumno.</p>
                        <p>2.5. En caso de que el colegio detectara abandono de las responsabilidades y/o situación de vulnerabilidad del alumno, previa notificación al Supervisor, podrá realizar la consulta y/o denuncia ante la Defensoría de niños, niñas y adolescentes.</p>
                        <p>3. En caso de verificarse veinte (20) días corridos de ausencia sin informar los motivos, la escuela elaborará un informe fundado en un análisis de la trayectoria del estudiante y de su situación en relación con los procesos de aprendizaje, para ser elevado a la Supervisión, quien derivará al Equipo de Asistencia Socioeducativa (ASE)/ Equipo de Orientación Escolar (EOE).</p>
                        <p>4. Ante casos de estudiantes con un número elevado de inasistencias o llegadas tarde a un espacio curricular específico, el docente a cargo de tal espacio deberá informar inmediatamente al preceptor y al tutor de curso. La Institución notificará y/o convocará a la familia; y el Equipo del DOT junto con los directivos, determinarán la incidencia de las ausencias y llegadas tardes en la situación académica (acreditación y promoción de los contenidos abordados) en dicho espacio curricular. La Institución se reserva el derecho de determinar el diseño adecuado de las propuestas de apoyo que requieran los estudiantes en cuestión.</p>
                        <p>5. La asistencia será considerada como condición de acreditación de los espacios curriculares. En ese sentido, el estudiante no podrá registrar más de veinticinco (25) inasistencias (sean justificadas o no justificadas) a lo largo del período regular. De lo contrario, el estudiante deberá concurrir al período de apoyo y acompañamiento para la evaluación y promoción de aquellos espacios curriculares en los que no haya acreditado el 85% de asistencia sobre la carga horaria de dichos espacios curriculares.</p>
                        <p>6. Si un estudiante no alcanza el porcentaje indicado y, aun así, puede dar cuenta de la apropiación de los contenidos y capacidades correspondientes a tales espacios acorde a las pautas establecidas por los docentes al inicio del año escolar y al modo de trabajo realizado durante el mismo, el Equipo del DOT junto con el equipo de conducción, podrá decidir una excepción a estos porcentajes, considerar su situación y dar lugar a la acreditación.</p>
                        <p>7. En el período de apoyo y acompañamiento para la evaluación y la promoción, será condición de acreditación de los espacios curriculares cumplir con un 85% de asistencia. Sin embargo, el equipo del DOT, junto con el equipo de conducción, podrá decidir una excepción a este porcentaje, atendiendo a situaciones particulares de estudiantes debidamente fundamentadas.</p>
                        <p>8. En aquellos casos en que el estudiante recurse espacios acreditados previamente, considerando que ya ha alcanzado los objetivos de aprendizaje, la calificación para acreditar como mínimo deberá ser igual o mayor a cuatro (4).</p>

                        <p>2. Asistencia y puntualidad durante el turno mañana</p>
                        <p>El alumno deberá cumplir el horario de ingreso y de egreso al Colegio.</p>
                        <p>El horario de entrada al Instituto es a partir de las 7:10; si ingresa al salón de formación después del toque de timbre a las 7:30 y hasta las 8:00, se le computará ½ falta; pasada esta hora le corresponderá falta entera, pudiendo ingresar únicamente con el debido justificativo del adulto autorizado y el permiso de la autoridad del Colegio.</p>
                        <p>La jornada finaliza con la última hora de clase (6ª, 7ª, 8ª, 9ª o 10ª, según corresponda). Durante la misma el alumno no puede salir del establecimiento, a menos que sea retirado por el adulto autorizado.</p>
                        <p>En caso de enfermedad, la inasistencia se justificará únicamente con certificado médico. Otros tipos de justificativos enviados por las familias, quedarán a consideración de la autoridad del nivel.</p>
                        <p>En caso de enfermedad o accidente dentro del horario de clase, la escuela llamará al servicio médico e inmediatamente se les comunicará a los padres.</p>
                        <p>Los alumnos que realicen viajes de estudio aprobados por el Colegio tendrán las faltas justificadas por resolución interna según autorice la Dirección General de Escuelas de Gestión Privada (DGEGP).</p>
                        <p>Los alumnos que no asistan al Colegio para participar en instancias competitivas representando al Colegio, no tendrán faltas por inasistencia.</p>
                        <p>Los alumnos que se ausenten del Colegio por viajes particulares o familiares durante el período lectivo llevarán faltas injustificadas.</p>
                        <p>Los alumnos deberán traer la ficha con los datos personales completa y firmada en la primera semana de clase.</p>

                        <p>3. Asistencia y puntualidad a Educación Física durante el turno tarde</p>
                        <p>El proceso de evaluación en Educación Física es permanente por lo que la asistencia y puntualidad en las clases son factores claves para la valoración pedagógica continua y formativa de los alumnos. En los casos en que los alumnos deban ausentarse, cada inasistencia ameritará ½  (media) falta en el boletín. A partir de los diez minutos de comenzada la clase el alumno incurrirá en una tardanza que se registrará con el criterio de media falta a clase de Educación Física. La misma será registrada por el docente e impactará en su valoración pedagógica y en el registro de inasistencias por materia que computa cada docente. Si el alumno superara el 15% de inasistencias injustificadas por bimestre, los contenidos nodales trabajados durante dicho periodo quedarán en proceso de acreditación.</p>
                        <p>Ante situaciones de alumnos que no puedan realizar actividad física por cuestiones personales, el alumno deberá informarlas debidamente al docente y al preceptor. El docente evaluará la pertinencia del pedido.</p>
                        <p>Régimen de proyecto: Se atenderá a los casos de alumnos que acrediten debidamente por certificado médico la imposibilidad de realizar actividad física, o que se encuentren federados en la práctica de algún deporte. En ambos casos los padres deberán presentar la solicitud y las acreditaciones correspondientes.</p>
                        <ul>
                            <li>Los estudiantes federados que deban realizar prácticas físicas de entrenamiento en instituciones deportivas legalmente reconocidas podrán solicitar exención de concurrencia a las clases de Educación Física, siempre y cuando éstas se realicen a contraturno y se presenten las certificaciones correspondientes, que deberán ser aprobadas por Rectoría. Serán integrado a un Régimen de Proyectos (Ley N° 5.823) cuando el adulto autorizado realice la solicitud a través de Secretaría y sea aprobado por Rectoría. Secretaría cursará las notificaciones a docentes, directivos y familia. A mitad de año las familias deberán actualizar la información que validará la continuidad del alumno federado con los mismos requisitos exigidos a principio de año para la aprobación del régimen de proyecto.</li>
                        </ul>
                        <p>Apto físico: Esta certificación médica es obligatoria para poder realizar con normalidad las clases de Educación Física. Los alumnos deberán presentarla durante la primera semana de clases.</p>
                        <ul>
                            <li>En caso de no cumplir con este requisito el alumno asistirá a las clases pero no podrá realizar actividad física, hasta no contar con el apto físico.</li>
                            <li>Los contenidos desarrollados durante dicho periodo quedarán en proceso de acreditación hasta el periodo PIA y/o las instancias de diciembre y febrero. Es responsabilidad del alumno acordar con el docente que lo orientará sobre la forma de acreditar las prácticas y/o teoría en proceso.</li>
                        </ul>

                        <p><b>C. Horarios de atención a los padres</b></p>
                        <p>Para entrevistarse con los Directivos, deberá solicitarse mediante un correo electrónico a la secretaría del Nivel la cita correspondiente.</p>
                        <p>Los Docentes de Nivel Secundario y los Jefes de Departamento atenderán a los padres en los horarios establecidos e informados a principios de año y con la debida solicitud por escrito de la entrevista mediante un correo electrónico a la secretaría del Nivel.</p>

                        <p><b>D. Comunicaciones</b></p>
                        <p>Las comunicaciones cotidianas se realizarán mediante correo electrónico a la dirección declarada por las familias en la ficha de inscripción. En el caso de las familias que posean cuenta institucional, comunicaciones llegarán a esa dirección.</p>
                        <p>Los preceptores, tutores y secretarios serán los encargados de enviar las comunicaciones hacia las familias, y de recibir y transmitir a los directivos las consultas que se puedan generar.</p>
                        <p>La plataforma institucional también se utilizará como medio de comunicación formal y fehaciente.</p>

                        <p><b>E. Orden interno</b></p>
                        <p>Los alumnos tendrán un plazo de un mes, una vez comenzadas las clases, para adquirir los materiales didácticos indicados como obligatorios por el docente, ya que se consideran indispensables para el buen desarrollo del proceso educativo de la asignatura en cuestión. Durante el año los profesores registrarán los incumplimientos ante la falta de presentación del material solicitado, y al tercer incumplimiento del trimestre quedarán facultados para calificar con un aplazo. Es responsabilidad del alumno tener el material de trabajo y tareas al inicio de la clase por lo que no se admitirá que la familia se lo acerque, ni que el alumno se retire a buscarlo.</p>
                        <p>Los alumnos deben retirarse del aula durante los recreos, siendo esta norma obligatoria. No está permitido comer ni beber durante las horas de clases.</p>
                        <p>Los alumnos deberán permanecer en las aulas mientras se dictan clases salvo que tenga permiso de un directivo para realizar alguna tarea. Todas las comunicaciones entre alumnos y sus padres, durante el horario de clase, deben canalizarse, indefectiblemente, a través del preceptor.</p>
                        <p>No está permitido el uso de teléfonos celulares dentro del establecimiento, salvo que el docente lo solicite para utilizarlo con fines pedagógicos. Quien asista con uno, deberán tenerlo siempre apagado y en la mochila. El alumno que lo utilice, bajo cualquiera de sus formas, será pasible de sanción, deberá dejar el teléfono en la institución y solo podrá retirarlo un adulto responsable previa notificación formal. Para ayudar a los alumnos a autorregular su uso, cada aula dispondrá de un contenedor para los teléfonos celulares. Al comenzar la jornada los alumnos lo guardarán allí, bajo llave, y lo retirarán al finalizar el día. El Colegio no se hace responsable de la pérdida y/o sustracción de los celulares, ni de cualquier otro objeto de valor.</p>
                        <p>No está permitido el uso de reproductores de música con auriculares para uso personal. Los libros, carpetas u otros materiales no pueden quedar en el Colegio finalizada la jornada escolar.</p>
                        <p>No está permitido filmar, grabar o sacar fotos ni subir dicho material digital a la Web sin previa autorización de los directivos del nivel.</p>
                        <p>Los comentarios en redes sociales son considerados del ámbito público, por tal motivo, podrán ser evaluados por las autoridades del Colegio siguiendo el estilo institucional.</p>

                        <p><b>F. Orden externo</b></p>

                        <p>El comportamiento de los alumnos en las cercanías del Colegio cuando porten el uniforme deberá ser acorde al estilo institucional.</p>
                        <p>No está permitido participar de situaciones de agresión física o verbal portando el uniforme, ni fumar en los alrededores del Colegio. La relación con los vecinos debe ser correcta, por lo que no está permitido ensuciar las veredas ni arrojar alimentos u objetos a personas, bajo ninguna circunstancia.</p>
                        <p>No está permitido participar en actividades ajenas al Colegio con el uniforme institucional.</p>
                        <p>1. Uniforme: símbolo institucional</p>
                        <p>El uniforme identifica al alumno con el Colegio y con sus objetivos, por lo tanto no debe incurrir en acciones ni actitudes que desprestigien o comprometan su nombre ni dentro ni fuera del establecimiento.</p>
                        <p>No está permitido realizar ningún tipo de modificación al uniforme. Los alumnos deberán llevarlo con prolijidad, pulcritud y sencillez.</p>
                        <p>2. Uniforme de gala:</p>
                        <p>2.1. Mujeres:</p>
                        <p>Camisa blanca con cuello y manga larga.</p>
                        <p>Pollera escocesa (largo mínimo: 3 cm. antes de la rodilla).</p>
                        <p>Pullover bordó escote en “V” con distintivo reglamentario del Colegio.</p>
                        <p>Cualquier accesorio como bufanda, guantes, etc., deberá respetar los colores del colegio (bordó o azul, negro o blanco).</p>
                        <p>Medias ¾ azules.</p>
                        <p>Zapatos negros.</p>
                        <p>Cabello recogido.</p>
                        <p>Camperas bordó reglamentarias con escudo del Colegio.</p>
                        <p>Se permite el uso de polera blanca debajo de la camisa.</p>
                        <p>No se permite el cabello total o parcialmente teñido, como así tampoco asistir maquilladas y/o con las uñas pintadas.</p>
                        <p>No se permite el uso de accesorios que no pertenezcan al uniforme.</p>
                        <p>No se permite el uso de alhajas, bijouterie y/o tatuajes visibles.</p>
                        <p>No concurrirán con un aspecto de dejadez y desaliño.</p>
                        <p>2.2. Varones:</p>
                        <p>Camisa blanca con manga larga.</p>
                        <p>Pantalón gris de sarga (nunca “jeans”).</p>
                        <p>Corbata bordó con escudo del Colegio.</p>
                        <p>Pullover bordó escote “V” con distintivo reglamentario del Colegio.</p>
                        <p>Cualquier accesorio como bufanda, guantes, etc., deberá respetar los colores del colegio (bordó o azul, negro o blanco).</p>
                        <p>Medias ¾ azules.</p>
                        <p>Mocasín negro o zapato negro acordonado.</p>
                        <p>Cabello corto y prolijo.</p>
                        <p>Deberán asistir afeitados.</p>
                        <p>Camperas bordó reglamentarias con escudo del Colegio.</p>
                        <p>No se permite el cabello total o parcialmente teñido.</p>
                        <p>No se permite el uso de accesorios que no pertenezcan al uniforme.</p>
                        <p>No se permite el uso de alhajas, bijouterie y/o tatuajes visibles.</p>
                        <p>No concurrirán con un aspecto de dejadez y desaliño.</p>
                        <p>3. Uniforme de Educación Física:</p>
                        <p>Pantalón azul con escudo del Colegio.</p>
                        <p>Buzo bordó también con escudo.</p>
                        <p>Remera blanca con escudo del Colegio.</p>
                        <p>Zapatillas blancas o negras.</p>
                        <p>Medias blancas.</p>
                        <p>Cuando por fuerza mayor el alumno se viera impedido de concurrir a clases con el uniforme completo, presentará una nota firmada por los padres, donde expliquen las causas de su incumplimiento. Esta nota se entregará al preceptor del curso quien analizará el caso.</p>
                        <p>El buzo de egresados de 5to año debe ser previamente aprobado por las autoridades. Los accesorios de abrigo pueden ser de color bordó, azul o negro lisos.</p>
                        <p>Todos los accesorios y útiles escolares que utilicen los alumnos vistiendo el uniforme del Colegio deberán ser acordes al estilo institucional.</p>

                        <p><b>G. Régimen de sanciones</b></p>
                        <p>La orientación del comportamiento de los alumnos en el Colegio está orientada por la pedagogía de la prevención.</p>
                        <p>Las medidas disciplinarias orientan sus elecciones a la consecución de las virtudes que forjan la personalidad del alumno para ser una persona íntegra. Las sanciones se clasifican en graves, medias y leves acorde al objeto, fin y circunstancias de la acción. Luego de un análisis y diálogo con los actores de la situación, se evalúa el nivel de gravedad.</p>
                        <p>1. Faltas graves:</p>
                        <p>-Copiarse durante el desarrollo de cualquier tipo de evaluación. Plagiar un trabajo y presentarlo como propio. Contra la virtud de la Prudencia y contra la Justicia. En esos casos también recibirá un aplazo.</p>
                        <p>-Irreverencia a los símbolos y sitios sagrados de la Fe y del culto religioso, de la Patria e institucionales (Sagrada Escritura, capilla, imágenes, banderas, cantos litúrgicos y patrios, etc.). Contra la virtud de la Justicia y la Piedad religiosa y patriótica.</p>
                        <p>-Agresiones morales o físicas contra cualquier miembro adulto (autoridad, docente, etc.), compañero o visitante de la comunidad educativa, así como la generación de disturbios o molestias notorias durante el desarrollo de las clases. Contra la virtud de la Justicia, particularmente contra la dignidad, buen nombre e integridad de la persona; así como contraria a la Piedad, Observancia y Obediencia, en caso de una autoridad, y también contraria a la Templanza.</p>
                        <p>-Copiarse durante el desarrollo de cualquier tipo de evaluación. Plagiar un trabajo y presentarlo como propio. Contra la virtud de la Prudencia y contra la Justicia. En esos casos también recibirá un aplazo.</p>
                        <p>-Robo, hurto o daño (parcial o total) de los elementos físicos de la comunidad o de sus miembros integrantes (pizarrones, bancos, material didáctico, etc.). Contra la virtud de la Justicia.</p>
                        <p>-Abandono de la jornada escolar o de actividades escolares obligatorias, sin previo aviso y permiso autorizado.</p>
                        <p>-Contra la virtud de la Prudencia y de la Justicia, la Observancia y la Obediencia.</p>
                        <p>-Falsificación de firmas autorizadas (padres, docentes, directivos) o de calificaciones. Contra la virtud de la Justicia y la Veracidad.</p>
                        <p>-Realización de actos contrarios a la moral y buenas costumbres fuera del establecimiento con el uniforme institucional o portando símbolos institucionales. Contra la virtud de la Justicia, la Observancia y la Templanza.</p>
                        <p>-Rotura voluntaria de documentación oficial de la institución. Contra la Justicia general.</p>
                        <p>El estudiante que incurra en alguna de estas faltas podrá ser sancionado con uno o más apercibimientos y pasará al estado de “condicionalidad”.</p>
                        <p>Los directivos analizarán el caso y las condiciones en las que se dieron los hechos, y consultarán a los docentes y/o preceptores del curso.</p>
                        <p>En todos los casos el alumno deberá reparar el daño causado.</p>

                        <p>2. Faltas medias:</p>
                        <p>-Incumplimientos de lo establecido por el reglamento para el uniforme y desorden en la presentación personal. Contra la virtud de la Templanza.</p>
                        <p>-Falta de presentación, o de notificación de los padres, en tiempo y forma, del boletín de calificaciones o del Cuaderno de Comunicaciones. Contra la virtud de la Justicia, la Observancia y la Obediencia.</p>
                        <p>-En caso de bajo rendimiento escolar, manifiesto y reiterado desinterés por mejorarlo. Contra la virtud de la Justicia, de la Fortaleza y de la Templanza.</p>
                        <p>-Utilizar teléfonos celulares y/o filmar - grabar - sacar fotos durante la jornada de clases. Contra la virtud de la Obediencia y la Observancia.</p>
                        <p>-Manifiesta y reiterada falta de respuesta frente a las correcciones. Generación de disturbios o molestias durante el desarrollo de las clases. Contra las virtudes de la Justicia, de la Fortaleza y de la Templanza.</p>
                        <p>Pueden ir de 1 a 3 firmas. La cantidad la determinará el análisis del hecho por parte de directivos, docentes o preceptores.</p>
                        <p>3. Faltas leves:</p>
                        <p>-Concurrir ocasionalmente con adornos o accesorios no correspondientes al uniforme.</p>
                        <p>-Concurrir ocasionalmente con uniforme que no corresponde a la jornada escolar.</p>
                        <p>-En el curso o en formación: impuntualidad, provocar desórdenes (conversar, cambiarse de ubicación sin autorización, etc.), o protagonizar ocasionalmente conductas inadecuadas (comer, hablar en clase, etc.). El celular podrá ser usado en clase solamente con indicación del docente para la realización de una tarea específica.</p>
                        <p>Las sanciones, que quedarán registradas en el Libro de disciplina como observaciones, se comunicarán a los padres por el Cuaderno de Comunicaciones.</p>
                        <p>Sostener en el tiempo la repetición de estas faltas leves dará lugar a la aplicación de una firma.</p>
                        <p>Las sanciones serán aplicadas por los preceptores o los directivos, según corresponda.</p>
                        <p>El Colegio tiene la atribución de sancionar otras faltas que se produjeran y que no se encuentran detalladas en este reglamento. Las mismas serán consideradas graves, medias o leves según corresponda.</p>

                        <p><b>H. Reconocimientos, felicitaciones y distinciones otorgadas a los alumnos/as</b></p>

                        <p>Así como nuestra institución busca rectificar las conductas no deseables del alumno en pos de su formación personal integral, es decir, siempre con un carácter educativo, así también busca destacar y reconocer las conductas y los hábitos buenos a fin de promover su consolidación y también la emulación de los mismos por parte de todos los alumnos, reforzando positivamente estas acciones y dando muestras claras de nuestro compromiso y búsqueda de la excelencia.</p>
                        <p>Se buscará promover el reconocimiento, la felicitación personal y pública y la distinción de aquellos alumnos que:</p>

                        <ul>
                            <li>Manifiestan un esfuerzo visible por superarse en el conocimiento de la verdad, y por ende, de su rendimiento escolar (estudio, fortaleza)</li>
                            <li>Participan en competencias o en actividades que implican exigencia en el estudio y dedicación de tiempo extra (estudio, esfuerzo)</li>
                            <li>Manifiestan un esfuerzo visible por rectificar su conducta (fortaleza)</li>
                            <li>Participan activamente de la vida institucional y de las actividades especiales propuestas por el Colegio (participación, compromiso)</li>
                            <li>Manifiestan una marcada actitud de servicio en diferentes situaciones</li>
                            <li>Realizan actos de caridad y de solidaridad con sus pares y con todos en general</li>
                            <li>Se hacen responsables espontáneamente de sus actos asumiendo sin dilaciones sus consecuencias, sean aquellos correctos o incorrectos (responsabilidad)</li>
                            <li>Se esfuerzan por crecer en la maduración de su afectividad (templanza)</li>
                            <li>Realizan acciones de alta calidad humana fuera o dentro del Colegio (caridad, solidaridad)</li>
                        </ul>

                        <p>En todos los casos se comunicará la distinción a los padres por cuaderno de comunicaciones y se dejará sentado en el libro de reconocimientos y distinciones del Colegio.</p>
                        <p>Las mismas podrán abarcar desde reconocimientos orales, escritos, designación de escoltas temporales, abanderado para el izamiento de la enseña patria durante un tiempo, designación del alumno como parte de una delegación oficial del Colegio para algún acontecimiento especial, entrega de un presente acorde a la acción realizada, entrega de un símbolo (medalla-diploma) al finalizar el año en el acto académico de clausura, etc.</p>

                        <p><b>I. Condicionalidad de los alumnos/as</b></p>

                        <p>Un alumno puede perder su condición de regular por varias razones:</p>
                        <p>1. Por razones de conducta:</p>
                        <p>a. Reiteración de faltas y sanciones sin que se observe objetivamente, propósito de corrección.</p>
                        <p>b. Falta de encuadramiento en el perfil del alumno del Colegio.</p>
                        <p>2. Por razones de falta de identificación con el Proyecto Institucional.</p>
                        <p>3. Por razones de rendimiento escolar: Permanencia.</p>
                        <p>Exigencias durante la situación de condicionalidad:</p>
                        <ul>
                            <li>Que el alumno obtenga una mejora comprobable en su compromiso académico. Que su responsabilidad y conducta general mejore evidentemente, de tal modo que durante todo el período escolar no se haga merecedor de ninguna sanción disciplinaria grave.</li>
                            <li>Que participe activamente de las actividades propuestas por el Colegio.</li>
                            <li>Que sus padres concurran a las citaciones que los docentes y directivos crean convenientes realizar con el objeto de seguir de cerca este proceso.</li>
                            <li>Que sus padres apoyen las recomendaciones pedagógicas que el Colegio establezca respecto del compromiso académico y comportamiento de su hijo.</li>
                        </ul>
                        <p>El Colegio en estos casos se compromete a:</p>
                        <p>Citar a los padres y al alumno cada vez que lo considere necesario en vistas a seguir de cerca este proceso. Al finalizar el año puede determinarse, acorde a los resultados obtenidos:</p>
                        <p>La finalización de la condicionalidad, la continuidad de la condicionalidad o la separación del Colegio.</p>
                        <p>El Colegio se reserva el derecho de permanencia del alumno en el Colegio en caso de violar el alumno o los padres las condiciones antes descriptas.</p>
                        <p>No resulta condición "sine qua non" haber revestido situación de condicionalidad para que el Consejo Superior decida la separación del Colegio de un alumno.</p>

                        <p><b>J. Evaluación, calificación y promoción</b></p>

                        <p>La evaluación es una instancia del proceso de formación del alumno. Tiene como objetivos básicos:</p>
                        <ul>
                            <li>Diagnosticar la situación de aprendizaje en la que se encuentra el alumno con el objeto de obtener información para poder orientarlo pedagógicamente.</li>
                            <li>Calificar, ubicar dentro de una escala conceptual o numérica el nivel de logros alcanzados.</li>
                        </ul>

                        <p>En nuestro Colegio se aplican tres tipos de evaluación: diagnóstica, formativa y sumativa. Las evaluaciones propias de nuestro estilo institucional son las integradoras, que abarcan en su tratamiento todo el año escolar del alumno.</p>
                        <p>Lo que se evalúa son las competencias del saber, del saber hacer y del saber obrar, a través de sus correspondientes contenidos e indicadores de aprobación.</p>
                        <p>La ausencia injustificada a una evaluación adecuadamente notificada será calificada con un aplazo, pudiendo acordarse -según los tiempos escolares y criterios del docente- la generación de una nueva instancia evaluativa que se promediará con el aplazo por ausencia injustificada.</p>
                        <p>Las evaluaciones integradoras serán de rendimiento obligatorio, independientemente de la metodología utilizada por el docente.</p>
                        <p>Los estudiantes que al finalizar el ciclo lectivo adeuden 3 o más materias y, en consecuencia, no puedan promocionar el año, perderán el otorgamiento automático de la vacante para el ciclo lectivo siguiente.</p>

                        <p><b>K. Actividades complementarias</b></p>

                        <p>Con el fin de complementar la formación básica que reciben los alumnos del Nivel Secundario y de introducirlos en un ámbito más amplio y específico de capacitación académica y de experiencias formativas integrales, se organizarán actividades co-curriculares dependientes de los departamentos correspondientes. En caso de realizarse las mismas fuera del horario escolar o en instituciones extraescolares (visitas a universidades, ferias, exposiciones, centros científico-tecnológicos, etc.) los alumnos deberán concurrir, siendo consideradas éstas actividades obligatorias y con régimen de asistencia. Quienes no concurran no podrán asistir al Colegio y, en el caso de presentarse, no podrán permanecer y deberán ser retirados por el adulto a cargo.</p>
                        <p>Entran en este régimen convivencias, retiros y campamentos que organiza el Colegio y que son signo distintivo del estilo educativo de la Institución.</p>
                        <p>Los directivos del nivel enviarán en tiempo y forma la correspondiente nota informativa a los padres, los que adjuntarán su autorización a la salida.</p>

                        <p style={{ textAlign: 'right' }}><i>Ciudad de Buenos Aires, Agosto 2024.</i></p>
                    </div>


                </Card>
            </div>


        </div>
    )
}

export default Regulation