import React, { useEffect, useState } from 'react'
import Navbar from '../../navbar/Navbar'
import { Badge, Card, Col, Dropdown, DropdownMenu, Row, Table, Button, Container, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChalkboardTeacher, faCheck, faClipboardUser, faEllipsisV, faEye, faFileExport, faPen, faPrint, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import ActionButton from '../../general-components/ActionButton'
import { getUserToken, logout, validateToken } from '../../../controllers/user.controller'
import { GET, POST } from '../../../controllers/endpoints.controller'
import moment from 'moment'
import { convert2Base64 } from '../../../tools/tools'

function Courses() {
    const PAGE_TITLE = "Data entry";
    const PAGE_ICON = faChalkboardTeacher;

    useEffect(() => {
        document.title = `Tasky - ${PAGE_TITLE}`;
        validateToken();

        getFileTypes();
        setLSUserId(localStorage.getItem('dataEntryUserId') ? localStorage.getItem('dataEntryUserId') : '')
    }, []);

    //DropDown Course
    const [selectedFileTypeOption, setSelectedFileTypeOption] = useState('Tipo de archivo');
    const [selectedFileTypeId, setSelectedFileTypeId] = useState(null);

    const [lsUserId, setLSUserId] = useState("");

    const handleSelect = (eventKey) => {
        setSelectedFileTypeOption(fileTypes[eventKey].name);
        setSelectedFileTypeId(fileTypes[eventKey].id)
    };

    const handleFile = async (event) => {
        setFile(event.target.files[0])
    }
    const [file, setFile] = useState({});



    const [fileTypes, setFileTypes] = useState([]);

    const getFileTypes = async function () {
        const req = await GET(`/students/files/types`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setFileTypes(res)

        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const postFile = async function () {
        const f = await convert2Base64(file);
        const aux = file.name.split('.')
        const fileExtension = `${aux[aux.length - 1]}`
        const fileName = file.name;

        const body = {
            userId: document.getElementById('fileUserId').value,
            typeId: selectedFileTypeId,
            file: {
                b64: f,
                name: fileName.replace(`.${fileExtension}`, ""),
                extension: fileExtension
            }
        }

        const req = await POST(`/students/files`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            console.log(res)
            localStorage.setItem('dataEntryUserId', document.getElementById('fileUserId').value)
            window.location.reload();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    return (
        <div className="app">

            <Container className='pt-5'>
                <Row>
                    <div style={{ textAlign: 'center' }}>
                        <img src="/logo_color.svg" style={{ width: 120 }} alt="Tasky Logo" />
                    </div>

                </Row>
                <Row className='mt-4'>
                    <Form.Text>ID de usuario</Form.Text>
                    <div className=''><Form.Control inputProps={{ inputMode: 'numeric' }} className='textInputGray fs-14 w-100' id="fileUserId" placeholder="" defaultValue={lsUserId} /></div>
                </Row>
                <Row className='mt-2'>
                    <Form.Text>Tipo de archivo</Form.Text>
                    <Dropdown className='dropdownButton'>
                        <Dropdown.Toggle className="dropdownFilter txt-left fs-14 w-100">
                            {selectedFileTypeOption}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="fs-14" title={selectedFileTypeOption}>
                            {fileTypes.map((opt, i) => (
                                <Dropdown.Item key={i} onClick={() => handleSelect(i)}>{opt.name}</Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </Row>
                <Row className='mt-2'>
                    <Form.Group className="">
                        <Form.Text>Archivo</Form.Text>
                        <Form.Control className='textInputGray' onChange={(event) => handleFile(event)} type="file" />
                    </Form.Group>
                </Row>
                <Row className='mt-4'>
                    <div>
                        <ActionButton iconFa={faCheck} title='Confirmar' func={postFile}></ActionButton>
                    </div>

                </Row>
            </Container>
        </div>
    )
}

export default Courses