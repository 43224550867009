import React, { useEffect, useState } from 'react'
import Navbar from '../../navbar/Navbar'
import { Badge, Button, Card, Col, Dropdown, DropdownMenu, Form, Modal, Row, Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faCalendar, faChalkboardTeacher, faCheck, faCog, faCross, faEllipsisV, faFolderOpen, faGraduationCap, faPen, faPenClip, faPenFancy, faPlus, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons'
import DatePicker from 'react-datepicker';
import { Link } from 'react-router-dom'
import ActionButton from '../../general-components/ActionButton'
import { getUserToken, logout, validateToken } from '../../../controllers/user.controller'
import { GET, POST } from '../../../controllers/endpoints.controller'
import Moment from 'react-moment'

function Frames() {
    const PAGE_TITLE = "Períodos";
    const PAGE_ICON = faCog;
    const PREVIOUS_PAGE = "Configuración";
    const PREVIOUS_URL = "/configuracion"

    useEffect(() => {
        document.title = `Tasky - ${PAGE_TITLE}`;
        validateToken();

        getPeriods();
    }, []);

    //Modal
    const [showModalNewFrame, setShowModalNewFrame] = useState(false);

    const handleShowModalNewFrame = () => {
        setShowModalNewFrame(true)
        setSelectedDateEnd(null)
        setSelectedDateStart(null)
        setSelectedGradable('Tipo de calificación')
        setSelectedLevelOption('Nivel')
        setSelectedGradable('Calificable')
        setSelectedGroup('Grupo')
        setCreateGroup(false)
        setNewGroupFinal('')

        getSchoolYears();
        getLevels();
    };

    const handleCloseModalNewFrame = () => {
        setShowModalNewFrame(false);
    }

    const [selectedSchoolYearOption, setSelectedSchoolYearOption] = useState('Ciclo lectivo');
    const [selectedSchoolYearId, setSelectedSchoolYearId] = useState(null);

    const handleSelectSchoolYear = (eventKey) => {
        setSelectedSchoolYearOption(schoolYears[eventKey].year);
        setSelectedSchoolYearId(schoolYears[eventKey].id);
        getPeriodGroups(schoolYears[eventKey].year)
    };


    //DropDown Nivel
    const [selectedLevelOption, setSelectedLevelOption] = useState('Nivel');
    const [selectedLevelOptionId, setSelectedLevelOptionId] = useState(null);
    const handleSelectLevel = (eventKey) => {
        setSelectedLevelOption(levels[eventKey].name);
        setSelectedLevelOptionId(levels[eventKey].id);
    };

    //DropDown Tipo de calificación
    let optionstypeOfGrade = ['Conceptual', 'Numérica'];
    const [selectedtypeOfGradeOption, setSelectedtypeOfGradeOption] = useState('Tipo de calificación');
    const handleSelecttypeOfGrade = (eventKey) => {
        setSelectedtypeOfGradeOption(optionstypeOfGrade[eventKey]);
    };

    //DropDown si es calificable
    let optionsGradable = ['Calificable', 'No calificable'];
    const [selectedGradable, setSelectedGradable] = useState('Calificable');
    const handleSelectGradable = (eventKey) => {
        setSelectedGradable(optionsGradable[eventKey]);
    };

    //DropDown grupo
    const [selectedGroup, setSelectedGroup] = useState('Grupo');
    const [selectedGroupId, setSelectedGroupId] = useState(null);
    const handleSelectGroup = (eventKey) => {
        setSelectedGroup(periodGroups[eventKey].name);
        setSelectedGroupId(periodGroups[eventKey].id);
    };

    const [createGroup, setCreateGroup] = useState(false)
    const newGroup = () => {
        setCreateGroup(true);
    }
    const [newGroupFinal, setNewGroupFinal] = useState('')
    const handleNewGroup = (e) => {
        setNewGroupFinal(e.target.value)
    }
    /*const newGroupConfirmation = () => {
        setOptionsGroup((prevOptionsGroup) => [...prevOptionsGroup, newGroupFinal]);
        
    }*/
    const goBack = () => {
        setCreateGroup(false);
        setNewGroupFinal('')
    }

    //Pick date
    const [selectedDateStart, setSelectedDateStart] = useState(null);

    const handleChangeDateStart = date => {
        setSelectedDateStart(date);
    };

    const [selectedDateEnd, setSelectedDateEnd] = useState(null);

    const handleChangeDateEnd = date => {
        setSelectedDateEnd(date);
    };

    const [levels, setLevels] = useState([]);
    const [schoolYears, setSchoolYears] = useState([]);
    const [periods, setPeriods] = useState([]);
    const [periodGroups, setPeriodGroups] = useState([]);

    const getPeriods = async function () {
        const req = await GET(`/grades/periods`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setPeriods(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getPeriodGroups = async function (year) {
        const req = await GET(`/grades/periods/groups/bySchoolYear/${year}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setPeriodGroups(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getSchoolYears = async function () {
        const req = await GET(`/classes/schoolYears`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setSchoolYears(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getLevels = async function () {
        const req = await GET(`/curriculum/levels`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setLevels(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const postPeriodGroup = async function () {
        const body = {
            name: document.getElementById('textNewPeriodGroupName').value,
            year: selectedSchoolYearOption,
            levelId: selectedLevelOptionId
        }

        const req = await POST(`/grades/groups`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setSelectedGroup(res.data.name);
            setSelectedGroupId(res.data.id);
            getPeriodGroups();
            goBack();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const postPeriod = async function () {
        const body = {
            from: selectedDateStart,
            to: selectedDateEnd,
            name: document.getElementById('textNewPeriodName').value,
            year: selectedSchoolYearOption,
            groupId: selectedGroupId,
            isGradable: selectedGradable == "Calificable" ? true : false,
            isNumerical: selectedtypeOfGradeOption == "Numérica" ? true : false
        }

        const req = await POST(`/grades/periods/groups`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            handleCloseModalNewFrame();
            getPeriods();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    return (
        <div className="app">
            <Navbar />
            <div className="content">
                <Row className='toolbar m-0'>
                    <Col>
                        {PREVIOUS_PAGE != "" ?
                            <Link to={PREVIOUS_URL} className='link-primary'>
                                <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver
                            </Link>
                            : ``}

                        <span className={`navbar-icon ${PREVIOUS_PAGE != "" ? `ms-4` : ``}`}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={PAGE_ICON}></FontAwesomeIcon></span>
                        <span className='ms-2'>{PREVIOUS_PAGE != "" ? `${PREVIOUS_PAGE} - ` : ``}{PAGE_TITLE}</span>
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <Col className='endRow'>
                        <ActionButton iconFa={faPlus} title={'Nuevo período'} func={handleShowModalNewFrame} classExtra='fs-14' />
                    </Col>
                </Row>
                <Card className='cards mt-3'>
                    <div>
                        <Row className='p-2 mb-2' style={{ color: 'white', backgroundColor: '#3680F4', borderRadius: 8 }}>
                            <Col md={2} className='align-center-vertically-v2'>
                                <div style={{ textAlign: 'left' }}>
                                    Nombre
                                </div>
                            </Col>
                            <Col md={4} className='align-center-vertically-v2' >
                                <div style={{ textAlign: 'center' }}>
                                    Fecha
                                </div>
                            </Col>
                            <Col md={3} className='align-center-vertically-v2'>
                                <div style={{ textAlign: 'center' }}>
                                    Calificación
                                </div>
                            </Col>
                            <Col md={2} className='align-center-vertically-v2'>
                                <div style={{ textAlign: 'center' }}>
                                    Nivel
                                </div>
                            </Col>
                            <Col md={1} className='align-center-vertically-v2'>
                            </Col>
                        </Row>
                    </div>

                    {periods.map((r, i) => (
                        <div>
                            <div className='d-none d-lg-block'>
                                <Row className='mb-2 pb-1 pt-1 ps-2 pe-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                    <Col md={2} className='align-center-vertically-v2'>
                                        <div style={{ textAlign: 'left' }}>
                                            {r.name}
                                        </div>
                                    </Col>
                                    <Col md={4} className='align-center-vertically-v2' >
                                        <div style={{ textAlign: 'center' }}>
                                            <Moment date={r.from} format='DD/MM/YYYY' /> al <Moment date={r.to} format='DD/MM/YYYY' />
                                        </div>
                                    </Col>

                                    <Col md={3} className='align-center-vertically-v2'>
                                        <div style={{ textAlign: 'center' }}>
                                            <Badge bg='' style={{ background: `${r.isGradable ? 'green' : 'red'}` }} className='statusBadge fs-14'>
                                                {r.isGradable ? 'Calificable' : 'No calificable'}
                                            </Badge>
                                            <Badge bg='' style={{ background: `${r.isNumerical ? 'green' : 'red'}` }} className='statusBadge fs-14 ms-2'>
                                                {r.isNumerical ? 'Numérica' : 'Conceptual'}
                                            </Badge>
                                        </div>
                                    </Col>
                                    <Col md={2} className='align-center-vertically-v2'>
                                        <div style={{ textAlign: 'center' }}>
                                            {r.Level ? r.Level.name : ""}
                                        </div>
                                    </Col>
                                    <Col md={1} className='align-center-vertically-v2'>
                                        <div style={{ display: 'flex', justifyContent: 'end' }}>
                                            <Dropdown className='ms-2'>
                                                <Dropdown.Toggle variant="secondary" id="dropdown-basic" className='dropdownToggle'>
                                                    <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item ><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    ))}
                </Card>
            </div>

            <Modal size='lg' show={showModalNewFrame} onHide={handleCloseModalNewFrame} centered className='assitanceModal modalContainer' backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropDownIcon' icon={faCalendar} style={{ minWidht: '20px' }} /><Modal.Title>Nuevo período</Modal.Title>
                </Modal.Header>
                <Modal.Body className=''>
                    <Row>
                        <Col md={6} className='p-0 mb-2'>
                            <div className='align-center-vertically'>
                                <FontAwesomeIcon icon={faCalendar} className='dropDownIcon' style={{ height: 16 }} />
                                <Dropdown className='dropdownButton'>
                                    <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-300">
                                        {selectedSchoolYearOption}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="fs-14" title={selectedSchoolYearOption}>
                                        {schoolYears.map((opt, i) => (
                                            <Dropdown.Item key={i} onClick={() => handleSelectSchoolYear(i)}>{opt.year}</Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Col>
                        <Col md={6} className='p-0 align-center-vertically mb-2'>
                            <FontAwesomeIcon icon={faChalkboardTeacher} className='dropDownIcon' style={{ height: 16 }} />
                            <Dropdown className='dropdownButton wi-300 p-0'>
                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-300">
                                    {selectedLevelOption}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedLevelOption}>
                                    {levels.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelectLevel(i)}>{opt.name}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>

                        <Col md={6} className='p-0 mb-2'>
                            <div className=' align-center-vertically'>
                                <div className='dropDownIcon' ></div>
                                <Form.Control className='textInputGray fs-14 wi-300' placeholder="Nombre" id="textNewPeriodName" />
                            </div>
                        </Col>

                        <Col className='p-0 mb-2 ' md={6}>
                            <div className='align-center-vertically'>
                                <FontAwesomeIcon icon={faFolderOpen} className='dropDownIcon' style={{ height: 16 }} />
                                {!createGroup ? <>
                                    <Dropdown className='dropdownButton'>
                                        <Dropdown.Toggle className="dropdownFilter txt-left fs-14" style={{ width: '260px' }} disabled={selectedSchoolYearId == null || selectedLevelOptionId == null}>
                                            {selectedGroup}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="fs-14" title={selectedGroup}>
                                            {periodGroups.map((opt, i) => (
                                                <Dropdown.Item key={i} onClick={() => handleSelectGroup(i)}>{opt.name}</Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <ActionButton classExtra='ms-2' func={newGroup} iconFa={faPlus} disabled={selectedSchoolYearId == null || selectedLevelOptionId == null} ></ActionButton>
                                </>
                                    : <>
                                        <Col className='inputColGroup ps-0'>
                                            <Form.Control className='textInputGray fs-14 wi-200' placeholder="Nuevo grupo" id="textNewPeriodGroupName" />
                                        </Col>
                                        <Col className='p-0 ms-2 fitContentWidth align-center-vertically'>
                                            <ActionButton func={postPeriodGroup} iconFa={faCheck}></ActionButton>
                                        </Col>
                                        <Col className='pe-0 ms-2 align-center-vertically'>
                                            <ActionButton func={goBack} iconFa={faTimes}></ActionButton>
                                        </Col>
                                    </>}
                            </div>
                        </Col>

                        <Col md={6} className='frameDateContainer p-0 mb-2'>
                            <div className='align-center-vertically'>
                                <FontAwesomeIcon icon={faCalendar} className='dropDownIcon' style={{ height: 16 }} />
                                <DatePicker
                                    selected={selectedDateStart}
                                    onChange={handleChangeDateStart}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText='Fecha de inicio'
                                    className='input-text wi-300'
                                    locale='es'
                                    disabled={selectedSchoolYearId == null || selectedLevelOptionId == null} />
                            </div>
                        </Col>
                        <Col md={6} className='frameDateContainer p-0 mb-2'>
                            <div className='align-center-vertically'>
                                <FontAwesomeIcon icon={faCalendar} className='dropDownIcon' style={{ height: 16 }} />
                                <DatePicker
                                    selected={selectedDateEnd}
                                    onChange={handleChangeDateEnd}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText='Fecha de finalización'
                                    className='input-text wi-300'
                                    locale='es'
                                    disabled={selectedSchoolYearId == null || selectedLevelOptionId == null} />
                            </div>
                        </Col>



                        <Col md={6} className='p-0 mb-2 '>
                            <div className='align-center-vertically'>
                                <FontAwesomeIcon icon={faPenClip} className='dropDownIcon' style={{ height: 16 }} />
                                <Dropdown className='dropdownButton'>
                                    <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-300" disabled={selectedSchoolYearId == null || selectedLevelOptionId == null}>
                                        {selectedGradable}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="fs-14" title={selectedGradable}>
                                        {optionsGradable.map((opt, i) => (
                                            <Dropdown.Item key={i} onClick={() => handleSelectGradable(i)}>{opt}</Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Col>

                        <Col md={6} className='p-0 mb-2 '>
                            <div className='align-center-vertically'>
                                <FontAwesomeIcon icon={faPenFancy} className='dropDownIcon' style={{ height: 16 }} />
                                <Dropdown className='dropdownButton'>
                                    <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-300" disabled={selectedSchoolYearId == null || selectedLevelOptionId == null}>
                                        {selectedtypeOfGradeOption}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="fs-14" title={selectedtypeOfGradeOption}>
                                        {optionstypeOfGrade.map((opt, i) => (
                                            <Dropdown.Item key={i} onClick={() => handleSelecttypeOfGrade(i)}>{opt}</Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                        <Button variant="outline-secondary" className='fs-14 me-2' onClick={handleCloseModalNewFrame}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                        <Button variant="primary" className='fs-14' onClick={postPeriod}><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Frames