import { faChevronRight, faBars, faEllipsisV, faCalendar, faFileArrowDown, faCalendarCheck, faCalendarTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { Card, Row, Col } from "react-bootstrap";
import { Link } from 'react-router-dom';
import NotFound from '../../general-components/NotFound';

function Calendar({ summary = true }) {
  const calendarEvents = [
/*     { level: 'Nivel Secundario', title: 'Acto del día de la hispanidad', time: '8:30', date: '22/12' },
    { level: '5º año', title: 'Reunión de padres', time: '10:30', date: '22/12' }, */
  ];

  const imgListBeta = ['https://img.freepik.com/foto-gratis/chico-guapo-seguro-posando-contra-pared-blanca_176420-32936.jpg', 'https://img.freepik.com/foto-gratis/chico-guapo-seguro-posando-contra-pared-blanca_176420-32936.jpg', 'https://img.freepik.com/foto-gratis/retrato-hermoso-mujer-joven-posicion-pared-gris_231208-10760.jpg'];

  const classContainer = summary ? 'calendarContainer ps-2 slideContent ' : '';

  let userCheck = false;
  let colorStyle = !userCheck ? 'greenBack' : 'redBack';

  const interCardsRef = useRef(null);

  useEffect(() => {
    if (interCardsRef.current) {
      const container = interCardsRef.current;
      if (container.scrollHeight > container.clientHeight) {
        container.style.padding = '0px 5px 0px 15px';
      } else {
        container.style.padding = '0px 10px 0px 10px';
      }
    }
  }, [interCardsRef]);

  const funcSite = (imgList) => {
    const eventUsers = imgList.slice(0, 2).map((image, i) => (
      <div key={i} className="circle-container me-2" style={{ border: '1px solid #0480FD' }}>
        <img className="ms-2" src={image} alt={`User ${i}`} />
      </div>
    ));

    if (imgList.length > 2) {
      eventUsers.push(
        <div key="plus" className="circle-container">
          <p>+{imgList.length - 2}</p>
        </div>
      );
    }

    return (
      <div className="fit-content align-center-vertically" style={{ width: '250px', justifyContent: 'end' }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>{eventUsers}</div>
        <FontAwesomeIcon className="dots-icon ms-2" style={{ marginLeft: '30px' }} icon={faEllipsisV} />
        <Link to="">
          <FontAwesomeIcon className="prev-next-icon ms-2" style={{ marginLeft: '30px' }} icon={faChevronRight} />
        </Link>
      </div>
    );
  };

  let days = [];

  const showDate = (d) => {
    days.push(d);
    return (<p className="fs-8 date">{d}</p>)
  }

  return (
    <div className={classContainer} ref={interCardsRef}>
      {calendarEvents.length === 0 ? (
        <div style={{ height: '90%', alignItems: 'center' }} className='justify-content-center'>                                      
        <NotFound iconFa={faCalendarTimes} text='No se han encontrado próximos eventos' />
        </div>
      ) : (
        calendarEvents.map((content, i) => (
          <div key={i} className='mb-2'>
            {!summary && !days.includes(content.date) && showDate(content.date)}

            <Card className={summary ? 'greyBackCard cards interCards p-2 overflow-hidden ' : "cards interCards p-2 overflow-hidden"}>
              <div className={`sideColor ${colorStyle}`}></div>
              <Row className="align-items-center">
                <Col>
                  <p className="smallFontSize">{content.level}</p>
                  <p>{content.title}</p>
                </Col>
                <Col className="align-items-center fitContentWidth">
                  <p className="me-2" style={{ textAlign: 'end' }}>{content.time}</p>
                </Col>
                {!summary && funcSite(imgListBeta)}
              </Row>
            </Card>
          </div>
        ))
      )}
    </div>
  );
}

export default Calendar;