import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Modal, Row, DateTime } from 'react-bootstrap';
import Navbar from '../../navbar/Navbar';
import Calendar from './Calendar';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { faPlus, faCalendar, faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DropDownMenu from '../../general-components/DropDownMenu';
import EventModal from './EventModal';
import ActionButton from '../../general-components/ActionButton';
import { validateToken } from '../../../controllers/user.controller';

function CalendarSite() {
    const PAGE_TITLE = "Calendario";
    const PAGE_ICON = faCalendar;

    useEffect(() => {
        document.title = `Tasky - ${PAGE_TITLE}`;
        validateToken();
    }, []);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    let options = ['Más próximos', 'Últimos agregados'];
    const [selectedOption, setSelectedOption] = useState('Más próximos');

    const handleSelect = (eventKey) => {
        setSelectedOption(options[eventKey]);
    };

    return (
        <div className="app">
            <Navbar />
            <div className="content">
                <Row className='toolbar m-0'>
                    <Col>
                        <span className={'navbar-icon'}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={PAGE_ICON}></FontAwesomeIcon></span>
                        <span className='ms-2'>{PAGE_TITLE}</span>
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <Row className='spacedBetween'>
                        <Col className='  '>
                            <div className='align-center-vertically '>
                                <FontAwesomeIcon icon={faCalendar} className='dropDownIcon' style={{ height: 16 }} />
                                <Dropdown className='dropdownButton'>
                                    <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-200">
                                        {selectedOption}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="fs-14" title={selectedOption}>
                                        {options.map((opt, i) => (
                                            <Dropdown.Item key={i} onClick={() => handleSelect(i)}>{opt}</Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Col>
                        <Col className='fitContentWidth'>
                            <ActionButton iconFa={faPlus} title={'Nuevo evento'} func={handleShow} classExtra='fs-14' />
                        </Col>
                    </Row>
                </Row>
                <Row className='mt-4'>
                    <Calendar summary={false} />
                </Row>
            </div>

            <EventModal show={show} handleClose={handleClose} />
        </div>
    )
}

export default CalendarSite