import { faAlignJustify, faArrowLeft, faCalendar, faChalkboardTeacher, faCheck, faClock, faCog, faEllipsisV, faEye, faFile, faPen, faPlus, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react'
import { Badge, Button, Card, Col, Modal, Row, Table, Form, Dropdown } from 'react-bootstrap';
import Navbar from '../../navbar/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import ActionButton from '../../general-components/ActionButton';
import { getUserToken, logout, validateToken } from '../../../controllers/user.controller';
import { DEL, GET, POST } from '../../../controllers/endpoints.controller';
import { useNavigate } from 'react-router-dom';


function CurriculumSubjects() {
    const PAGE_TITLE = "Materias";
    const PAGE_ICON = faCog;
    const PREVIOUS_PAGE = "Configuración - Planes de estudio - Años / grados";
    const PREVIOUS_URL = "/configuracion/planes-estudio/años"

    const [gradeId, setGradeId] = useState(null);

    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1); // Navega hacia atrás en el historial
    };

    useEffect(() => {
        document.title = `Tasky - ${PAGE_TITLE}`;
        validateToken();
        setGradeId(getParams())
        getSubjectsByGradeId(getParams())
    }, []);

    const [subjects, setSubjects] = useState([]);
    const [subjectsStats, setSubjectsStats] = useState({ extrapmHs: '-', generalHs: '-', specificHs: '-', genSpecificHs: '-' });


    const getParams = function () {
        const params = new URLSearchParams(window.location.search);
        if (params.has('id')) {
            return params.get('id');
        } else {
            return -1;
        }
    }

    /*const subjects = [
        { name: 'Matemática', extrapr: 0, general: 4, specific: 0 },
        { name: 'Lengua', extrapr: 0, general: 4, specific: 0 },
        { name: 'Inglés', extrapr: 2, general: 4, specific: 2 },
        { name: 'Biología', extrapr: 0, general: 4, specific: 0 },
        { name: 'SIC', extrapr: 0, general: 0, specific: 2 },
        { name: 'TIC', extrapr: 2, general: 0, specific: 2 },
        { name: 'SAC', extrapr: 0, general: 0, specific: 2 },
    ];*/

    const getSubjectsByGradeId = async function (id) {
        const req = await GET(`/curriculum/subjects/withHours/byGradeId/${id != null ? id : gradeId}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setSubjects(res.subjects);
            setSubjectsStats(res.stats);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const postSubjects = async function () {
        let ss = [];

        for (let i of subSubjectList) {
            ss.push(document.getElementById(i.id).value);
        }

        const body = {
            gradeId: gradeId,
            subjects: [
                {
                    name: subjectName,
                    subSubjects: ss,
                    extrapmHs: hoursExtrapr ? parseInt(hoursExtrapr) : 0,
                    generalHs: hoursGeneral ? parseInt(hoursGeneral) : 0,
                    specificHs: hoursSpecific ? parseInt(hoursSpecific) : 0,
                    mergeStudents: document.getElementById('checkNewSubjectMerge').checked,
                    isGradable: document.getElementById('checkNewSubjectIsGradable').checked
                }
            ]
        }

        const req = await POST(`/curriculum/subjects`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            handleCloseModalNewSubject();
            getSubjectsByGradeId(null);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const deleteSubject = async function () {
        const req = await DEL(`/curriculum/subjects/${deleteSubjectId}`, getUserToken());
        const res = await req.json();

        if (req.status === 201) {
            console.log(res)
            getSubjectsByGradeId(null)
            handleCloseConfirmDeleteModal()
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const indicators = { extrapr: 4, general: 16, specific: 8, generalSpecific: 24 }

    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [deleteSubjectId, setDeleteSubjectId] = useState(null);
    const [deleteSubjectName, setDeleteSubjectName] = useState("");

    const handleCloseConfirmDeleteModal = () => setShowConfirmDeleteModal(false);
    const handleShowConfirmDeleteModal = (id, name) => {
        setDeleteSubjectId(id);
        setDeleteSubjectName(name);
        setShowConfirmDeleteModal(true);
    };

    //Modal
    const [showNewSubject, setShowNewSubject] = useState(false);

    const handleShowNewSubject = () => {
        setSubjectName('');
        setHoursExtrapr('');
        setHoursGeneral('');
        setHoursSpecific('');
        setSubSubjectList([]);
        setShowNewSubject(true)
    };

    const handleCloseModalNewSubject = () => {
        setShowNewSubject(false);
    }

    const [subjectName, setSubjectName] = useState('');
    const handlesubjectName = (event) => {
        setSubjectName(event.target.value);
    };

    const [hoursGeneral, setHoursGeneral] = useState('');
    const handleHoursGeneral = (event) => {
        setHoursGeneral(event.target.value);
    };

    const [hoursSpecific, setHoursSpecific] = useState('');
    const handleHoursSpecific = (event) => {
        setHoursSpecific(event.target.value);
    };


    const [hoursExtrapr, setHoursExtrapr] = useState('');
    const handleHoursExtrapr = (event) => {
        setHoursExtrapr(event.target.value);
    };

    const [subSubjectList, setSubSubjectList] = useState([]);

    const newSubSubject = () => {
        const newId = Date.now(); // id único
        const newSubSubjectItem = {
            id: newId,
            component: (
                <div className='align-center-vertically mb-2' key={newId}>
                    <Form.Control className='textInputGray fs-14 wi-300' type="text" style={{ width: '200px' }} placeholder="Nivel" id={newId} />
                    <FontAwesomeIcon icon={faTimes} className='remiderCross ms-2' style={{ cursor: 'pointer' }} onClick={() => removeSubSubject(newId)} />
                </div>
            )
        };
        setSubSubjectList(prevList => [...prevList, newSubSubjectItem]);
    };

    const removeSubSubject = (idToRemove) => {
        setSubSubjectList(prevList => prevList.filter(item => item.id !== idToRemove));
    };

    return (
        <div className="app">
            <Navbar />
            <div className="content">
                <Row className='toolbar m-0'>
                    <Col>
                        {PREVIOUS_PAGE != "" ?
                            <Link onClick={goBack} className='link-primary'>
                                <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver
                            </Link>
                            : ``}

                        <span className={`navbar-icon ${PREVIOUS_PAGE != "" ? `ms-4` : ``}`}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={PAGE_ICON}></FontAwesomeIcon></span>
                        <span className='ms-2'>{PREVIOUS_PAGE != "" ? `${PREVIOUS_PAGE} - ` : ``}{PAGE_TITLE}</span>
                    </Col>
                </Row>
                <Row className='mt-4 mb-2'>
                    <Col className='endRow'>
                        <ActionButton iconFa={faPlus} title={'Añadir materias'} func={handleShowNewSubject} classExtra='fs-14' />
                    </Col>
                </Row>
                <Row className='mt-3'>
                    {indicators.generalSpecific > 0 && indicators.specific > 0 &&
                        <Col md={3} className='ps-0 pe-0'>
                            <Card className='staticCards cards'>
                                <p className='m-0'>F. general y específica</p>
                                <div className='wrapCont flexEnd'>
                                    <p className='fs-38 fw-700 m-0'>{subjectsStats.genSpecificHs != 1 ? `${subjectsStats.genSpecificHs} hs.` : `${subjectsStats.genSpecificHs} h.`}</p>
                                </div>
                            </Card>
                        </Col>
                    }
                    {indicators.general > 0 &&
                        <Col md={3} className='ps-3 pe-0'>
                            <Card className='staticCards cards'>
                                <p className='m-0'>Formación general</p>
                                <div className='wrapCont flexEnd'>
                                    <p className='fs-38 fw-700 m-0'>{subjectsStats.generalHs != 1 ? `${subjectsStats.generalHs} hs.` : `${subjectsStats.generalHs} h.`}</p>
                                </div>
                            </Card>
                        </Col>
                    }
                    {indicators.specific > 0 &&
                        <Col md={3} className='ps-3 pe-0'>
                            <Card className='staticCards cards'>
                                <p className='m-0'>Formación específica</p>
                                <div className='wrapCont flexEnd'>
                                    <p className='fs-38 fw-700 m-0'>{subjectsStats.specificHs != 1 ? `${subjectsStats.specificHs} hs.` : `${subjectsStats.specificHs} h.`}</p>
                                </div>
                            </Card>
                        </Col>
                    }
                    {indicators.extrapr > 0 &&
                        <Col md={3} className='ps-3 pe-0'>
                            <Card className='staticCards cards'>
                                <p className='m-0'>Extraprogramática</p>
                                <div className='wrapCont flexEnd'>
                                    <p className='fs-38 fw-700 m-0'>{subjectsStats.extrapmHs != 1 ? `${subjectsStats.extrapmHs} hs.` : `${subjectsStats.extrapmHs} h.`}</p>
                                </div>
                            </Card>
                        </Col>
                    }

                </Row>
                <Card className='cards mt-3'>
                    <div>
                        <Row className='p-2 mb-2' style={{ color: 'white', backgroundColor: '#3680F4', borderRadius: 8 }}>
                            <Col md={3} className='align-center-vertically-v2 ms-0'>
                                <div>
                                    Materia
                                </div>
                            </Col>
                            <Col md={2} className='align-center-vertically-v2 ms-0'>
                                <div>
                                    Niveles
                                </div>
                            </Col>
                            <Col md={2} className='align-center-vertically-v2 ms-0'>
                                <div style={{ textAlign: 'center' }}>
                                    F. general
                                </div>
                            </Col>
                            <Col md={2} className='align-center-vertically-v2 ms-0'>
                                <div style={{ textAlign: 'center' }}>
                                    F. específica
                                </div>
                            </Col>
                            <Col md={2} className='align-center-vertically-v2 ms-0'>
                                <div style={{ textAlign: 'center' }}>
                                    Extrapr.
                                </div>
                            </Col>
                            <Col md={1} className='align-center-vertically-v2 ms-0'>

                            </Col>
                        </Row>
                    </div>

                    {subjects.map((subject, i) => (
                        <div key={i}>
                            <div className='d-none d-lg-block'>
                                <Row className='mb-1 pb-1 pt-1 ps-2 pe-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                    <Col md={3} className='align-center-vertically-v2 pt-2 pb-2 '>
                                        <div style={{ textAlign: 'left' }}>
                                            <p className='m-0'>{subject.name}</p>
                                        </div>
                                    </Col>
                                    <Col md={2} className='align-center-vertically-v2 pt-2 pb-2 '>
                                        <div style={{ textAlign: 'left' }}>
                                            <p className='m-0'>{subject.SubSubjects ? subject.SubSubjects.map((ss, i) => (<span>{i != (subject.SubSubjects.length - 1) ? `${ss.name} | ` : ss.name}</span>)) : ""}</p>
                                        </div>
                                    </Col>
                                    <Col md={2} className='align-center-vertically-v2 ms-0'>
                                        <div style={{ textAlign: 'center' }}>
                                            {subject.generalHs != 0 && subject.generalHs != null ? <span>{subject.generalHs == 1 ? `${subject.generalHs} h.` : `${subject.generalHs} hs.`}</span> : '-'}
                                        </div>
                                    </Col>
                                    <Col md={2} className='align-center-vertically-v2 ms-0'>
                                        <div style={{ textAlign: 'center' }}>
                                            {subject.specificHs != 0 && subject.specificHs != null ? <span>{subject.specificHs == 1 ? `${subject.specificHs} h.` : `${subject.specificHs} hs.`}</span> : '-'}
                                        </div>
                                    </Col>
                                    <Col md={2} className='align-center-vertically-v2 ms-0'>
                                        <div style={{ textAlign: 'center' }}>
                                            {subject.extrapmHs != 0 && subject.extrapmHs != null ? <span>{subject.extrapmHs == 1 ? `${subject.extrapmHs} h.` : `${subject.extrapmHs} hs.`}</span> : '-'}
                                        </div>
                                    </Col>
                                    <Col md={1} className='align-center-vertically-v2 ms-0'>
                                        <div style={{ display: 'flex', justifyContent: 'end' }}>
                                            <Dropdown className='ms-2'>
                                                <Dropdown.Toggle variant="secondary" id="dropdown-basic" className='dropdownToggle'>
                                                    <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => handleShowConfirmDeleteModal(subject.id, subject.name)}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    ))}
                </Card>
            </div>

            <Modal size={'lg'} show={showNewSubject} onHide={handleCloseModalNewSubject} centered className='assitanceModal modalContainer' backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropModalCont dropDownIcon' icon={faCog} style={{ minWidht: '20px' }} /><Modal.Title>Añadir materia</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={6} className="mb-2 align-center-vertically ps-0">
                            <div className='align-center-vertically mb-1'>
                                <div className='dropDownIcon'></div>
                                <Form.Control className='textInputGray fs-14 wi-300' id="" placeholder="Nombre" onChange={handlesubjectName} value={subjectName} />
                            </div>
                        </Col>
                        <Col md={12} className="ps-0 pe-0" style={{ display: 'flex' }}>
                            <div className='align-center-vertically mb-2 fitContentWidth'>
                                <FontAwesomeIcon icon={faClock} className='dropDownIcon' style={{ height: 16 }} />
                                <Form.Control type='number' className='textInputGray fs-14 wi-120' id="" placeholder="Hs. general" onChange={handleHoursGeneral} value={hoursGeneral} />
                            </div>
                            <div className='align-center-vertically mb-2 fitContentWidth ms-2'>
                                <Form.Control type='number' className='textInputGray fs-14 wi-120' id="" placeholder="Hs. específica" onChange={handleHoursSpecific} value={hoursSpecific} />
                            </div>
                            <div className='align-center-vertically mb-2 fitContentWidth ms-2'>
                                <Form.Control type='number' className='textInputGray fs-14 wi-120' id="" placeholder="Hs. extrapro." onChange={handleHoursExtrapr} value={hoursExtrapr} />
                            </div>
                        </Col>
                        <Col md={12} className='p-0'>
                            <div className='align-center-vertically mb-2 fitContentWidth'>
                                <FontAwesomeIcon icon={faChalkboardTeacher} className='dropDownIcon' style={{ height: 16 }} />
                                <div>
                                    {subSubjectList.map(button => button.component)}
                                    <div className='align-center-vertically'>
                                        <Button variant='primary' onClick={newSubSubject}><FontAwesomeIcon className='mr-10' icon={faPlus} />Agregar nivel</Button>
                                        <div style={{ display: 'flex', justifyContent: 'center' }} className='ms-3'>
                                            <Form.Check type="switch" label="Es clase conjunta" id={`checkNewSubjectMerge`} />
                                            <Form.Check type="switch" label="Es calificable" defaultChecked id={`checkNewSubjectIsGradable`} />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14 me-2' onClick={handleCloseModalNewSubject}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                    <Button variant="primary" className='fs-14 me-2' onClick={postSubjects}><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showConfirmDeleteModal} onHide={handleCloseConfirmDeleteModal} centered backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropModalCont dropDownIcon' icon={faCog} style={{ minWidht: '20px' }} /><Modal.Title>Eliminar materia</Modal.Title>
                </Modal.Header>
                <Modal.Body className=''>
                    <span className=''>¿Deseas eliminar la materia "{deleteSubjectName}"?</span>
                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14 me-2' onClick={handleCloseConfirmDeleteModal}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                    <Button variant="danger" className='fs-14 me-2' onClick={deleteSubject}><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default CurriculumSubjects