import React, { useEffect, useState } from 'react'
import Navbar from '../../navbar/Navbar'
import { Badge, Card, Col, Dropdown, DropdownMenu, Row, Table, Button, Modal, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faCamera, faChalkboardTeacher, faCheck, faClipboardUser, faEllipsisV, faEnvelope, faEye, faFileExport, faGraduationCap, faHeading, faIdCard, faImage, faMinus, faPen, faPlus, faPrint, faSortNumericUp, faTimes, faTrash, faUser, faUserFriends, faUsers } from '@fortawesome/free-solid-svg-icons'
import { Link, useNavigate } from 'react-router-dom'
import ActionButton from '../../general-components/ActionButton'
import { getUserToken, logout, validateToken } from '../../../controllers/user.controller'
import { faLetterboxd } from '@fortawesome/free-brands-svg-icons'
import InfoField from '../../general-components/InfoField'
import { DEL, GET, PUT } from '../../../controllers/endpoints.controller'
import Loading from '../../general-components/Loading'
import { getCroppedImg } from '../../../tools/tools'
import Cropper from 'react-easy-crop'

function UserDetail() {
    const PAGE_TITLE = "Usuario";
    const PAGE_ICON = faUsers;
    const PREVIOUS_PAGE = "Usuarios";

    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1); // Navega hacia atrás en el historial
    };

    useEffect(() => {
        document.title = `Tasky - ${PAGE_TITLE}`;
        validateToken();


        getUser(getParams());
    }, []);

    const [isEditing, setIsEditing] = useState(false);

    const roles = [
        { name: 'Preceptor', id: 1 },
        { name: 'Profesor', id: 2 },
        { name: 'Alumno', id: 3 },
    ];

    const [selectedRoleOption, setSelectedRoleOption] = useState('');
    const handleSelectRole = (eventKey) => {
        setSelectedRoleOption(eventKey);
    };

    const enableOptions = [{ name: 'SI', value: true }, { name: 'NO', value: false }]
    const [selectedEnableOption, setSelectedEnableOption] = useState('');
    const handleSelectEnable = (eventKey) => {
        setSelectedEnableOption(eventKey);
    };

    const [user, setUser] = useState({});

    const getParams = function () {
        const params = new URLSearchParams(window.location.search);
        if (params.has('id')) {
            return params.get('id');
        } else {
            return -1;
        }
    }

    const getUser = async function (userId) {
        const req = await GET(`/sso/users/byId/${userId}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setUser(res);
            document.title = `Tasky - ${res.name} ${res.surname}`;
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const [showChangeAvatarModal, setShowChangeAvatarModal] = React.useState(false);
    const [actualChangeAvatar, setActualChangeAvatar] = React.useState(null);
    const handleCloseChangeAvatarModal = () => {
        setShowChangeAvatarModal(false);
        setActualChangeAvatar(null);
    };
    const handleShowChangeAvatarModal = () => {
        setShowChangeAvatarModal(true);
    };

    const [avatarCrop, setAvatarCrop] = useState({ x: 0, y: 0 });
    const [avatarZoom, setAvatarZoom] = useState(1);
    const [avatarFileSrc, setAvatarFileSrc] = useState(null);

    const [avatarCropped, setAvatarCropped] = useState({});

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        setAvatarCropped(croppedAreaPixels)
    }

    const onAvatarFileChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.onload = () => {
                setAvatarFileSrc(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const postChangeAvatar = async function () {
        const croppedImage = await getCroppedImg(avatarFileSrc, {
            x: avatarCropped.x,
            y: avatarCropped.y,
            width: avatarCropped.width, // Estos valores ahora son dinámicos
            height: avatarCropped.height
        });

        const body = {
            userId: getParams(),
            file: {
                b64: croppedImage,
                name: `avatar-${getParams()}`,
                extension: 'jpg'
            }
        }

        const req = await PUT(`/sso/users/avatar`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            console.log(res)
            handleCloseChangeAvatarModal();
            getUser(getParams())
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState({state: false, modalTitle: "Eliminar", modalContent:"", funct:""});
    const [deleteElementId, setDeleteElementId] = useState(null);

    const handleCloseConfirmDeleteModal = () => setShowConfirmDeleteModal({state: false, modalTitle: "Eliminar", modalContent:"", funct:""});
    const handleShowConfirmDeleteModal = (id, title, content, funct) => {
        setDeleteElementId(id);
        setShowConfirmDeleteModal({
            state: true,
            modalTitle: title ,
            modalContent: content,
            funct: () => funct(id),
        });
    };

    const deleteUser = async function () {
        const req = await DEL(`//${deleteElementId}`, getUserToken());
        const res = await req.json();

        if (req.status === 201) {
            handleCloseConfirmDeleteModal();
            goBack();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    return (
        <div className="app">
            <Navbar />
            <div className="content">
                <Row className='toolbar m-0'>
                    <Col>
                        {PREVIOUS_PAGE != "" ?
                            <Link onClick={goBack} className='link-primary'>
                                <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver
                            </Link>
                            : ``}
                        <span className={'navbar-icon ms-2'}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={PAGE_ICON}></FontAwesomeIcon></span>
                        <span className='ms-2'>{PAGE_TITLE}</span>
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col className='endRow'>
                        {!isEditing ? 
                        <>
                        <Button variant='outline-danger' onClick={() => handleShowConfirmDeleteModal(getParams(), "Eliminar", `¿Deseas eliminar el usuario "${user.name} ${user.surname}"?`, deleteUser)} className='me-2'><FontAwesomeIcon className='mr-10' icon={faTrash} />Eliminar usuario</Button> 
                        <Button variant='primary' onClick={() => setIsEditing(true)}><FontAwesomeIcon className='mr-10' icon={faPen} />Modificar usuario</Button> 
                        </>:
                            <>
                                <Button variant='outline-secondary me-2' onClick={() => setIsEditing(false)}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                                <Button variant='primary' onClick={() => setIsEditing(false)}><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button></>
                        }
                    </Col>
                </Row>
                <Card className='cards mt-3'>
                    <Row>
                        <Col md={9} style={{ display: 'flex', flexDirection: "column", justifyContent: 'space-between' }}>
                            

                            <InfoField title='Nombre' content={<Form.Control className='textInputGray fs-14 wi-300' defaultValue={user.name} disabled={isEditing ? false : true} id="textNewUserName" />} extraClass='mb-2   ' />
                            <InfoField title='Apellido' content={<Form.Control className='textInputGray fs-14 wi-300' defaultValue={user.surname} disabled={isEditing ? false : true} id="textNewUserLastName" />} extraClass='mb-2   ' />
                            <InfoField title='Correo electrónico' content={<Form.Control className='textInputGray fs-14 wi-300' defaultValue={user.email} disabled={isEditing ? false : true} id="textNewUserEmail" />} extraClass='mb-2   ' />
                            <InfoField title='Rol' content={<div className='align-center-vertically'>
                                <Dropdown className='dropdownButton' disabled={isEditing ? false : true}>
                                    <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-300" disabled={isEditing ? false : true}>
                                        {selectedRoleOption !== '' ? roles[selectedRoleOption].name : user.Role ? user.Role.name : 'Rol'}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="fs-14" title={selectedRoleOption !== '' ? roles[selectedRoleOption].name : ""}>
                                        {roles.map((opt, i) => (
                                            <Dropdown.Item key={i} onClick={() => handleSelectRole(i)}>{opt.name}</Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>} extraClass='mb-2 ' />
                            <InfoField title='Habilitado' content={<div className='align-center-vertically'>
                                <Dropdown className='dropdownButton'>
                                    <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-300" disabled={isEditing ? false : true}>
                                        {selectedEnableOption !== '' ? enableOptions[selectedEnableOption].name : user.enabled ? "Si" : "No"}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="fs-14" title={selectedEnableOption !== '' ? enableOptions[selectedEnableOption].name : ("Habilitado")}>
                                        {enableOptions.map((opt, i) => (
                                            <Dropdown.Item key={i} onClick={() => handleSelectEnable(i)}>{opt.name}</Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>} extraClass='mb-2   ' />
                        </Col>
                        <Col md={3} className='align-center-vertically mb-2 p-1' style={{ justifyContent: 'center', background: "#F0F0F0", borderRadius: '8px' }}>
                            <div className='avatar-wrapper'>
                                {user.avatarFile ? <img src={user.avatarFile} className='avatar-200' /> : <Loading animation={'grow'} />}
                                {isEditing && (
                                    <div className='avatar-overlay avatar-200'>
                                        <ActionButton variant='light' func={handleShowChangeAvatarModal} iconFa={faCamera} title='Modificar foto' />
                                    </div>
                                )}
                            </div>
                        </Col>

                    </Row>
                </Card>
            </div>

            <Modal show={showChangeAvatarModal} centered onHide={handleCloseChangeAvatarModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Modificar foto</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Row className=''>
                            <Form.Group controlId="formFile">
                                <Form.Control type="file" accept="image/*" onChange={onAvatarFileChange} multiple={false} />
                            </Form.Group>
                        </Row>

                        <Row>

                            <Col>
                                {avatarFileSrc && (
                                    <div className='mt-3'>
                                        <div className="cropper-container">
                                            <Cropper
                                                image={avatarFileSrc}
                                                crop={avatarCrop}
                                                zoom={avatarZoom}
                                                onCropChange={setAvatarCrop}
                                                onZoomChange={setAvatarZoom}
                                                aspect={1}
                                                cropShape="round"
                                                showGrid={false}
                                                onCropComplete={onCropComplete}
                                            />
                                        </div>
                                        <div className="button-container">
                                            <ActionButton func={() => setAvatarZoom(avatarZoom + 0.1)} iconFa={faPlus} title='Agrandar' />
                                            <ActionButton func={() => setAvatarZoom(avatarZoom - 0.1)} iconFa={faMinus} title='Achicar' />
                                        </div>
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" className='fs-14' onClick={handleCloseChangeAvatarModal}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                    <Button variant="primary" className='fs-14' onClick={postChangeAvatar}><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showConfirmDeleteModal.state} onHide={handleCloseConfirmDeleteModal} centered backdrop="static" className="custom-backdrop" style={{ zIndex: 1051 }}>
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropModalCont dropDownIcon' icon={faTrash} style={{ minWidht: '20px' }} /><Modal.Title>{showConfirmDeleteModal.modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='pt-4 pb-4'>
                    <div className='' style={{textAlign:'center'}}>{showConfirmDeleteModal.modalContent}</div>
                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14 me-2' onClick={handleCloseConfirmDeleteModal}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                    <Button variant="danger" className='fs-14 me-2' onClick={showConfirmDeleteModal.funct}><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default UserDetail