import React, { useEffect, useState } from 'react'
import { Form, Button, Container, Row, Col, Modal, Badge, Alert, Card } from "react-bootstrap";
import { POST, GET } from '../../../controllers/endpoints.controller';
import { getMyStudents, getUserRole, getUserToken, saveUserData } from '../../../controllers/user.controller';
import { Link } from 'react-router-dom';
import { UAParser } from 'ua-parser-js';
import { v4 as uuidv4 } from 'uuid';

export default function ChooseRole() {
    const [childs, setChilds] = React.useState([]);

    useEffect(() => {
        document.title = `Tasky`;

        getMyChilds();
    }, []);

    const [mainId, setMainId] = useState(null)
    const saveMainId = (list) => {
        for (let r of list) {
            if (!r.isChildUser) {
                setMainId(r.id);
                break;
            }
        }
    }

    const getMyChilds = async function () {
        const parser = new UAParser();
        const result = parser.getResult();
        const uuid = localStorage.getItem('uuId') != null ? localStorage.getItem('uuId') : uuidv4();
        let body = {
            device: {
                uuid: uuid,
                brand: result.browser.name,
                osName: result.os.name,
                osVersion: result.os.version,
                model: result.browser.version,
                appVersion: "",
            }
        }

        const req = await POST('/sso/childs/mine', getUserToken(), body);
        const res = await req.json();

        if (req.status === 200) {
            setChilds(res)
            console.log(res)
            saveMainId(res);
        } else {
            alert(res.message);
        }
    }

    const selectRole = async function (user) {
        user.hasChilds = true;
        user.mainId = mainId;
        await saveUserData(user);
        if (user.Role.name.includes("Familia") || user.Role.name.includes("Padre-Madre-Tutor")) {
            getMyStudents();
        } else {
            window.location.href = '/inicio';
        }
    }

    return (
        <div style={{ backgroundColor: '#F8F9FC' }}>
            <Row>
                <div className="login-container">
                    <div className="login-card">
                        <div className="logo">
                            <img src="/logo_color.svg" width={110} alt="Tasky Logo" />
                        </div>
                        <p className='mt-4 mb-2 fs-14'>Notamos que tenés varios roles, seleccioná el rol con el que deseás ingresar:</p>

                        {childs.map((child, i) => (
                            <Col md={12}>
                                <Badge className='mt-3 select-role-school'>
                                    <img className='avatar-30 me-2' src={child.school.schoolLogo} alt=""></img>
                                    {child.school.name}
                                </Badge>
                                {child.childs && <>
                                    {child.childs.map((role, j) => (
                                        <Button className='p-3 mt-2 w-100' onClick={() => selectRole(role)}>{role.Role ? role.Role.name : ""}</Button>
                                    ))}
                                </>
                                }

                            </Col>
                        ))}
                    </div>
                </div>
            </Row>



        </div>
    );
}