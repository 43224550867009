import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Card, Button, Container, Row, Col, Modal, Badge, Alert, Carousel, Spinner, Form, Dropdown, ButtonGroup, DropdownButton, } from "react-bootstrap";
import { POST, GET, DEL, PUT } from '../../../controllers/endpoints.controller';
import { faArrowLeft, faCalendar, faCheck, faCheckCircle, faChevronLeft, faChevronRight, faClock, faCog, faComment, faCommentAlt, faCommentDots, faDownload, faEllipsisV, faEnvelope, faExclamation, faExclamationCircle, faEye, faFile, faFileAlt, faFileArchive, faFileArrowUp, faFileCircleExclamation, faHeart, faLaugh, faListDots, faPaperclip, faPaperPlane, faPen, faPlaceOfWorship, faPlane, faPlus, faReply, faSearch, faSurprise, faThumbsUp, faTimes, faTrash, faUserGroup } from '@fortawesome/free-solid-svg-icons'
import Navbar from '../../navbar/Navbar'
import EventModal from '../calendar/EventModal';
import NotFound from '../../general-components/NotFound';
import Calendar from '../calendar/Calendar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import Courses from '../courses/CoursesCard';
import ActionButton from '../../general-components/ActionButton';
import { getUserId, getUserMainId, getUserRole, getUserToken, logout, validateToken, validateUser } from '../../../controllers/user.controller';
import Loading from '../../general-components/Loading';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'quill-emoji/dist/quill-emoji.css';
import moment from 'moment';
import * as Emoji from "quill-emoji";
import DatePicker from 'react-datepicker';
import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, push, get, onValue, query, limitToLast } from "firebase/database";
import { convert2Base64, downloadFile } from '../../../tools/tools';


function Chat({heigth}) {

    useEffect(() => {
        validateToken();
        getUserIdNumber();
        moment.locale('es');
       // getGroupsToChat();
        getMyChats(getUserMainId());
    }, []);

    const [userId, setUserId] = useState(-1)
    const getUserIdNumber = () => {
        setUserId(getUserMainId());
    }
    const [isMessageInputFocused, setIsMessageInputFocused] = useState(false);

    function ChatMessage({ text, sender, showAvatar, date, showName, id, file }) {
        if (sender == 'me') {
            return (
                <Row className={`chat-message me ps-0 pe-0`}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }} className='ps-0 pe-0'>
                        <div className={`message-bubble me-bubble me-1`}>
                            {file != false && <div>
                                <div onClick={() => getFileToDownload(file)} style={{ border: '1px solid white', borderRadius: '5px', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} className=' ps-2 pe-2 pt-1 pb-1 fs-12 blueHoverDownload'>
                                    <div className='fitContentWidth'><FontAwesomeIcon icon={faFile} className='me-1' style={{ height: '11px' }} /> Archivo</div>
                                    <FontAwesomeIcon icon={faDownload} style={{ cursor: 'pointer', height: '11px', justifySelf: 'flex-end' }} className='ms-2' />
                                </div>
                            </div>}
                            {text}
                            <div style={{ marginBottom: '-8px', marginTop: '-2px', textAlign: 'right', marginRight: '-8px', color: 'white' }} className='smallFontSize '>
                                {moment(date).format('HH:mm')}
                            </div>
                        </div>
                        {/* <div className='fitContentWidth'>
                            {showAvatar == true ? <div className='studentImg circle-container' style={{ borderColor: 'white' }}>
                                <img className='' />
                            </div> :
                                <div style={{ width: '34px' }} className=''></div>
                            }
                        </div> */}
                    </div>
                </Row>
            );
        }
        const person = userSelectedToChat.ChatMembers.find(member => member.userId == id);
        return (
            <Row className={`chat-message them`}>
                <div style={{ display: 'flex', justifyContent: 'flex-start' }} className='ps-0 pe-0'>
                    <div className='fitContentWidth'>
                        {showAvatar == true ? <div className='studentImg circle-container me-1' style={{ borderColor: 'white', marginTop: '-5px', marginBottom: '-2px' }}>
                            {showName ? <img className='' src={person.avatarFile} /> : <img className='' src={userSelectedToChat.avatarFile} />}
                        </div> :
                            <div style={{ width: '34px' }} className='me-1'></div>
                        }
                    </div>
                    <div className={`message-bubble them-bubble me-1`}>

                        {showName && person && <div style={{ color: '#0480FD' }}>{`${person.name} ${person.surname}`}</div>}
                        {file != false && <div>
                            <div onClick={() => getFileToDownload(file)} style={{ border: '1px solid transparent', backgroundColor: '#00000021', borderRadius: '5px', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} className=' ps-2 pe-2 pt-1 pb-1 fs-12 blueHoverDownload'>
                                <div className='fitContentWidth'><FontAwesomeIcon icon={faFile} className='me-1' style={{ height: '11px' }} /> Archivo</div>
                                <FontAwesomeIcon icon={faDownload} style={{ cursor: 'pointer', height: '11px', justifySelf: 'flex-end' }} className='ms-2' />
                            </div>
                        </div>}
                        {text}
                        <div style={{ marginBottom: '-8px', marginTop: '-2px', textAlign: 'right', marginRight: '-6px', color: 'white' }} className='smallFontSize '>
                            {moment(date).format('HH:mm')}
                        </div>
                    </div>
                </div>
            </Row>
        );
    }

    const [inputValue, setInputValue] = useState('');

    const [isLoadingRecentChats, setIsLoadingRecentChats] = useState(false)
    const [showChatLists, setShowChatLists] = useState(true);
    const [recentChatOptions, setRecentChatOptions] = useState([]);

    const handleShowChatList = () => {
        setMessages([]);
        setChatToken(null);
        setShowChatLists(true);
    }

    const [userSelectedToChat, setUserSelectedToChat] = useState(null);
    const handleShowChatPerson = (u) => {
        getOneChat(u.id)
    }

    const postInitialChat = async function (u) {
        const body = {
            userIds: u
        };
        const req = await POST(`/communication/chat`, getUserToken(), body);
        const res = await req.json();
        if (req.status === 201) {
            console.log(res)
            getOneChat(res.id)
            handleCloseNewChat();
            getMyChats();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getMyChats = async function () {
        setIsLoadingRecentChats(true)
        const req = await GET(`/communication/chats/mine`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setIsLoadingRecentChats(false)
            setRecentChatOptions(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getOneChat = async function (id) {
        const req = await GET(`/communication/chat/byId/${id}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setUserSelectedToChat(res);
            setChatToken(res.firebaseId);
            setShowChatLists(false);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const firebaseConfig = {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
    };

    const app = initializeApp(firebaseConfig);
    const db = getDatabase(app);
    const [chatToken, setChatToken] = useState(null);

    const saveData = async (fileId = 0) => {
        const userRef = ref(db, `COMM_chat/${chatToken}`);
        const today = moment().toISOString();
        const userSnapshot = await get(userRef);

        /* if (!userSnapshot.exists()) {
            await set(userRef, {
                date: today,
                messages: {}
            });
        } */

        if (inputValue.trim() != "") {
            const newMessageRef = push(ref(db, `COMM_chat/${chatToken}/messages`));
            await set(newMessageRef, {
                date: today,
                content: inputValue,
                from: userId,
                fileId: fileId == 0 ? null : fileId,
            });

            setInputValue("");
            setFile(null);
        }
    }

    let [messages, setMessages] = useState([]);

    useEffect(() => {
        if (chatToken != null) {
            const dbRef = ref(db, `COMM_chat/${chatToken}/messages`);

            const unsubscribe = onValue(dbRef, (snapshot) => {
                if (snapshot.exists()) {
                    setMessages(Object.values(snapshot.val()));
                } else {
                    setMessages([]);
                }
            }, (error) => {
                console.error("Error fetching data: ", error);
            });

            return () => unsubscribe();
        }
    }, [db, chatToken]);

    const [chatContactsList, setChatContactsList] = useState([]);

    const [isLoadingSearch, setIsLoadingSearch] = useState(false)
    const searchUsers = async function () {
        setIsLoadingSearch(true);
        const chain = document.getElementById('textSearch').value;
        const myRole = getUserRole();
        let roles = ["Alumno", "Familia"];
        if (myRole == "") {
            roles.push("")
        }
        if (chain.length >= 3) {
            const req = await GET(`/sso/users/search/ByRoles/${roles.join(",")}/${chain}`, getUserToken());
            const res = await req.json();

            if (req.status === 200) {
                console.log(res)
                setChatContactsList(res)
                document.getElementById("textSearch").value = ""
                setIsLoadingSearch(false)
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        } else {
            setIsLoadingSearch(false)
        }
    }

    const refChatContainer = useRef(null);
    useEffect(() => {
        if (refChatContainer.current) {
            const container = refChatContainer.current;
            if (container.scrollHeight > container.clientHeight) {
                container.style.padding = '0px 8px 0px 0px'
            } else {
                container.style.padding = '0px 10px 0px 0px'
            }
        }
    }, [refChatContainer])

    const personalChatContainerRef = useRef(null);
    /*     useEffect(() => {
            if (personalChatContainerRef.current) {
                personalChatContainerRef.current.scrollTop = personalChatContainerRef.current.scrollHeight;
            }
        }, [messages]); */

    useLayoutEffect(() => {
        if (personalChatContainerRef.current) {
            personalChatContainerRef.current.scrollTop = personalChatContainerRef.current.scrollHeight;
        }
    }, [messages]);

    const [showNewChat, setShowNewChat] = useState(false);
    const handleCloseNewChat = () => {
        setShowNewChat(false)
    };
    const handleShowNewChat = () => {
        setShowNewChat(true);
    };

    const handleFile = async (event) => {
        setFile(event.target.files[0]);
    }
    const [file, setFile] = useState(null);

    const postFile = async function () {
        const f = await convert2Base64(file);
        const aux = file.name.split('.')
        const fileExtension = `${aux[aux.length - 1]}`
        const fileName = file.name;

        const body = {
            b64: f,
            name: fileName.replace(`.${fileExtension}`, ""),
            extension: fileExtension

        }

        const req = await POST(`/files/`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            console.log(res)
            saveData(res.id)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    // /files/byId/:fileId
    const getFileToDownload = async function (id) {
        downloadFile(id, "archivo-chat");
    }
    const [lastMessages, setLastMessages] = useState({})
    useEffect(() => {
        const db = getDatabase();
        const unsubscribes = [];

        recentChatOptions.forEach(chat => {
            const dbRef = ref(db, `COMM_chat/${chat.firebaseId}/messages`);
            const messagesQuery = query(dbRef, limitToLast(1));

            const unsubscribe = onValue(messagesQuery, (snapshot) => {
                if (snapshot.exists()) {
                    const messages = Object.values(snapshot.val());
                    const lastMessage = messages[0]?.content || '';
                    if (lastMessage != chat.lastMessage) {
                        setLastMessages(prevMessages => ({
                            ...prevMessages,
                            [chat.firebaseId]:
                            {
                                lastMessage: lastMessage,
                                lastMessageDate: messages[0].date
                            }
                        }));
                    }
                }
            }, (error) => {
                console.error("Error fetching data: ", error);
            });
            unsubscribes.push(unsubscribe);
        });

        return () => {
            unsubscribes.forEach(unsubscribe => unsubscribe());
        };
    }, [recentChatOptions]);

    const getFormattedDate = (date) => {
        const today = moment().startOf('day');
        const lastWeek = moment().subtract(7, 'days').startOf('day');
        const dateMoment = moment(date);

        if (dateMoment.isSame(today, 'day')) {
            return dateMoment.format('HH:mm');
        } else if (dateMoment.isSameOrAfter(lastWeek)) {
            return dateMoment.format('dddd');
        } else {
            return dateMoment.format('DD/MM/YY');
        }
    };

    const [groups, setGroups] = useState([]);
    const getGroupsToChat = async function (id) {
        const req = await GET(`/communication/groupChat/byLevelId`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setGroups(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }


    return (
        <>
            <Card className={`cards pe-1 ${heigth ? heigth : 'h-100-1'}`} style={{ padding: '13px 10px' }}>
                <Row className='mb-3'>
                    <Col className='ps-0 align-center-vertically fitContentWidth' style={{ height: '33.5px' }}>
                        <span className={`navbar-icon`}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={faCommentDots}></FontAwesomeIcon></span><span className='ms-2 fs-16'>Mensajes</span>
                    </Col>
                    <Col className='endRow align-center-vertically'>
                        {showChatLists ? <ActionButton iconFa={faPlus} title='Nuevo mensaje' func={handleShowNewChat} /> : ""}
                    </Col>
                </Row>
                {showChatLists ?

                    <>

                        {isLoadingRecentChats ? <div style={{ height: '90%', alignItems: 'center' }} className='justify-content-center'>
                            <Loading animation={'border'} />
                        </div> :
                            recentChatOptions && recentChatOptions.length > 0 ?
                                <div className='slideContent pe-2' ref={refChatContainer} style={{ overflowX: 'hidden' }}>
                                    {recentChatOptions.map((u, i) => (
                                        <Row key={i} className='mb-2 pb-2 pt-2 ps-3 pe-3 personListChat' style={{ background: '#F0F0F0', borderRadius: 15, height: 'min-content', cursor: 'pointer' }} onClick={() => handleShowChatPerson(u)}>
                                            <Col className='ps-0 pe-0 fitContentWidth'>
                                                {u.ChatMembers && <>
                                                    <div className='fitContentWidth align-center-vertically'>
                                                        <div className='studentImg circle-container me-1' style={{ borderColor: 'white' }}>
                                                            <img className='' src={u.avatarFile} />
                                                        </div>
                                                        {u.ChatMembers.length > 1 &&
                                                            <div className='studentImg circle-container me-2 align-center-vertically fs-12' style={{ borderColor: 'white', width: '25px', height: '25px', backgroundColor: 'white' }}>
                                                                +{u.ChatMembers.length - 1}
                                                            </div>
                                                        }
                                                    </div>
                                                </>
                                                }
                                            </Col>
                                            <Col className='ps-0 pe-0' style={{ width: 'calc(100% - 40px)' }}>
                                                <div style={{ textAlign: 'left' }} className='personNameChatList' >
                                                    {u && u.name}
                                                    {lastMessages[u.firebaseId] || (u.lastMessage && u.lastMessage != "") ?
                                                        <div>
                                                            <Row className='smallFontSize'>
                                                                <Col className='ps-0' md={8}>
                                                                    <div className='fs-11 textDots pe-0 ps-0' >{lastMessages[u.firebaseId] ? lastMessages[u.firebaseId].lastMessage : u.lastMessage}</div>
                                                                </Col>
                                                                <Col className='endRow ps-0 pe-0'>
                                                                    <div className='fs-11 pe-0' style={{ textAlign: 'end', whiteSpace: 'nowrap' }}>{getFormattedDate(lastMessages[u.firebaseId] ? lastMessages[u.firebaseId].lastMessageDate : u.lastMessageDate)}</div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        : <div className='fs-11' style={{ color: "red", fontStyle: 'italic' }}>
                                                            Borrador
                                                        </div>}
                                                </div>
                                            </Col>
                                        </Row>
                                    ))}
                                </div> : <div style={{ height: '100%', width: '100%', justifyContent: 'center' }} className='align-center-vertically'>
                                    <NotFound iconFa={faExclamationCircle} text={"No tienes ningún chat reciente"} />
                                </div>
                        }

                    </>
                    : <>
                        <Row style={{ height: '90%' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }} className='ps-0 pe-0'>
                                <div style={{ maxHeight: 'fit-content' }}>
                                    <Row className='mb-2 pb-2 pt-2 ps-2 pe-2' style={{ background: '#F0F0F0', borderRadius: 15, height: 'min-content' }}>
                                        <Col className='align-center-vertically-v2 fitContentWidth'>
                                            <div style={{ textAlign: 'center', cursor: 'pointer' }} className='blueHover' onClick={handleShowChatList}>
                                                {/* <FontAwesomeIcon icon={faChevronLeft} /> */}
                                                <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver
                                            </div>
                                        </Col>
                                        <Col className='align-center-vertically-v2 ps-0'>
                                            <div style={{ textAlign: 'center' }}>
                                                <div className='align-center-vertically me-2 fs-14'>
                                                    <div className='fitContentWidth align-center-vertically'>
                                                        <div className='studentImg circle-container me-1' style={{ borderColor: 'white' }}>
                                                            <img className='' src={userSelectedToChat.avatarFile} />
                                                        </div>
                                                        {userSelectedToChat.ChatMembers.length > 1 &&
                                                            <div className='studentImg circle-container me-2 align-center-vertically fs-12' style={{ borderColor: 'white', width: '25px', height: '25px', backgroundColor: 'white' }}>
                                                                +{userSelectedToChat.ChatMembers.length - 1}
                                                            </div>
                                                        }
                                                    </div>
                                                    <div style={{ textAlign: 'left' }}>
                                                        {
                                                            userSelectedToChat.name
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                <div style={{ flexGrow: 1, overflowY: 'auto', marginBottom: file == null ? '15px' : '40px' }} ref={personalChatContainerRef}> {/* Mensajes */}
                                    {messages.map((item, index) => (<>
                                        {index - 1 >= 0 && moment(item.date).isSame(messages[index - 1].date, 'day') ? '' :
                                            <div style={{ textAlign: 'Center', display: 'flex', justifyContent: 'center' }} key={index} className='mb-2 mt-2'>
                                                <div style={{ background: '#6c757d', borderRadius: 15, color: 'white' }} className='fs-12 fitContentWidth ps-3 pe-3'>
                                                    {moment(item.date).format('dddd D MMMM')}
                                                </div>
                                            </div>}
                                        <ChatMessage text={item.content} sender={item.from == userId ? "me" : "them"} showAvatar={index - 1 >= 0 && messages[index - 1].from == item.from ? false : true} date={item.date} id={item.from} showName={userSelectedToChat.ChatMembers.length > 1} file={item.fileId ? item.fileId : false} />
                                    </>))}
                                </div>
                                <div style={{ position: 'absolute', bottom: '0px', width: '95%' }} className='p-1 pb-2'>
                                    <Row className={`mb-1 pb-1 pt-1 ps-2 pe-2 inputMessageRow ${isMessageInputFocused ? ' focusedMessageInput' : ''}`} style={{ background: '#F0F0F0', borderRadius: 15 }}>
                                        {file != null && <Col md={12}>
                                            <div style={{ border: '1px solid grey', borderRadius: '8px' }} className='fitContentWidth ps-3 pe-2 pb-1 pt-1'>
                                                <FontAwesomeIcon icon={faFile} className='me-2' /> {file.name}
                                                <FontAwesomeIcon onClick={() => setFile(null)} icon={faTimes} style={{ cursor: 'pointer' }} className='remiderCross ms-2' />
                                            </div>
                                        </Col>}
                                        <Col className='align-center-vertically-v2 ps-0'>
                                            <div style={{ textAlign: 'center' }}>
                                                <Form.Control
                                                    onFocus={() => setIsMessageInputFocused(true)}
                                                    onBlur={() => setIsMessageInputFocused(false)}
                                                    className='textMessageInput fs-14'
                                                    id="newTextMessage"
                                                    placeholder="Escribe algo..."
                                                    value={inputValue}
                                                    onChange={(e) => setInputValue(e.target.value)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            file != null ? postFile() : saveData();
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col className='align-center-vertically fitContentWidth' style={{ justifyContent: 'flex-end' }}>
                                            <div onClick={() => document.getElementById('file-input').click()} style={{ textAlign: 'center' }} className='endRow me-2'>
                                                <Form.Control type="file" multiple={false} onChange={handleFile} disabled={file != null} id="file-input" style={{ display: 'none' }} />
                                                <FontAwesomeIcon icon={faPaperclip} style={{ fontSize: '18px', cursor: 'pointer' }} className='sendMessageIcon' />
                                            </div>
                                            <div onClick={() => file != null ? postFile() : saveData()} style={{ textAlign: 'center' }} className='endRow'>
                                                <FontAwesomeIcon icon={faPaperPlane} style={{ fontSize: '18px', cursor: 'pointer' }} className='sendMessageIcon' />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Row>
                    </>}
            </Card>
            <Modal show={showNewChat} onHide={handleCloseNewChat} className="custom-backdrop" centered style={{ zIndex: 1051 }}>
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropModalCont dropDownIcon' icon={faCommentDots} style={{ minWidht: '20px' }} /><Modal.Title>Nuevo mensaje</Modal.Title>
                </Modal.Header>
                <Modal.Body className='pt-4 pb-4'>
                    <Row className='mb-2' style={{ flexWrap: 'nowrap' }}>
                        <Col className='p-0'>
                            <Form.Control className='textInputGray fs-14' onKeyDown={(e) => { if (e.key === 'Enter') { searchUsers() } }} placeholder="Buscar..." id="textSearch" />
                        </Col>
                        <Col className='fitContentWidth ps-0'>
                            <ActionButton iconFa={faSearch} title='' classExtra='ms-2' func={searchUsers} />
                        </Col>
                    </Row>
                    <Row>
                        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }} className='ps-0 pe-0'>
                            <div className='mb-2'>
                            {groups.length > 0 ?
                                groups.map((u, i) => (
                                    <Row key={i} className='mb-2 pb-2 pt-2 ps-3 pe-3 personListChat' style={{ background: '#F0F0F0', borderRadius: 15, height: 'min-content', cursor: 'pointer' }} onClick={() => postInitialChat( u.ChatMembers.filter(id => id != userId))} >
                                        <Col className='align-center-vertically-v2 ps-0'>
                                            <div style={{ textAlign: 'center' }}>

                                                <div className='align-center-vertically me-2 fs-14'>
                                                    <div className='fitContentWidth'>
                                                        <div className='studentImg circle-container me-2' style={{ borderColor: 'white' }}>
                                                            <img className='' src={u.avatarFile.file} />
                                                        </div>
                                                    </div>
                                                    <div style={{ textAlign: 'left' }}>
                                                        <p className='mb-0 personNameChatList'>{u.name}</p>
                                                         <p className='smallFontSize mb-0'>Grupo</p> 
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                ))    
                        : ''}</div>
                            {isLoadingSearch ?
                                <div style={{ height: '90%', alignItems: 'center' }} className='justify-content-center pt-4 pe-4'>
                                    <Loading animation={'border'} />
                                </div>
                                :
                                chatContactsList.length > 0 ?
                                    <div className='slideContent'>
                                        {chatContactsList.map((u, i) => (
                                            <Row key={i} className='mb-2 pb-2 pt-2 ps-3 pe-3 personListChat' style={{ background: '#F0F0F0', borderRadius: 15, height: 'min-content', cursor: 'pointer' }} onClick={() => postInitialChat([u.id])} >
                                                <Col className='align-center-vertically-v2 ps-0'>
                                                    <div style={{ textAlign: 'center' }}>

                                                        <div className='align-center-vertically me-2 fs-14'>
                                                            <div className='fitContentWidth'>
                                                                <div className='studentImg circle-container me-2' style={{ borderColor: 'white' }}>
                                                                    <img className='' src={u.avatarFile} />
                                                                </div>
                                                            </div>
                                                            <div style={{ textAlign: 'left' }}>
                                                                <p className='mb-0 personNameChatList'>{u.surname ? u.surname.toUpperCase() : ''}, {u.name}</p>
                                                                {/*  <p className='smallFontSize mb-0'>Preceptor</p> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        ))}
                                    </div> : groups.length == 0 && <div style={{ height: '100%', width: '100%', justifyContent: 'center' }} className='align-center-vertically pt-4 pb-4'>
                                        <NotFound iconFa={faExclamationCircle} text={"Busca a alguien para enviarle un mensaje"} />
                                    </div>
                            }
                        </div>
                    </Row>
                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14 me-2' onClick={handleCloseNewChat}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Chat