import React, { useEffect, useState } from 'react'
import Navbar from '../../navbar/Navbar'
import { Badge, Button, Card, Col, Container, Dropdown, DropdownMenu, Form, Modal, OverlayTrigger, Row, Table, Popover } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faCalendar, faChalkboardTeacher, faCheck, faClock, faCog, faEllipsisV, faEye, faFileArchive, faFilter, faListNumeric, faPen, faPlus, faSearch, faTimes, faTrash, faUser, faUsers } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import ActionButton from '../../general-components/ActionButton'
import { getUserRole, getUserToken, logout, validateToken } from '../../../controllers/user.controller'
import DatePicker from 'react-datepicker';
import { GET, POST } from '../../../controllers/endpoints.controller'
import moment from 'moment'
import { getTextColorForBackground } from '../../../tools/tools'

function Schedules() {
    const PAGE_TITLE = "Horarios";
    const PAGE_ICON = faCog;
    const PREVIOUS_PAGE = "";
    const PREVIOUS_URL = ""

    useEffect(() => {
        document.title = `Tasky - ${PAGE_TITLE}`;
        validateToken();

        getClassrooms();
    }, []);

    const [showNewSchoolHourModal, setShowNewSchoolHourModal] = useState(false);
    const handleShowNewSchoolHour = () => {
        setSelectedLevelOption("Nivel");
        setSelectedLevelId(null);
        setSelectedTimeStart(null);
        setSelectedTimeEnd(null);
        setShowNewSchoolHourModal(true);
        getLevels();
    };

    const handleCloseNewSchoolHour = () => {
        setShowNewSchoolHourModal(false);
    }

    const [showNewClassTimeModal, setShowNewClassTimeModal] = useState(false);
    const [newClassHourId, setNewClassHourId] = useState(null);
    const [newClassDay, setNewClassDay] = useState(null);
    const handleShowNewClassTimeModal = (hourId, day) => {
        setShowNewClassTimeModal(true);
        getClassesByClassroomId();
        setNewClassHourId(hourId);
        setNewClassDay(day);
    };

    const handleCloseNewClassTimeModal = () => {
        setShowNewClassTimeModal(false);
    }

    const [levels, setLevels] = useState([]);

    const [selectedClassroomOption, setSelectedClassroomOption] = useState('Curso');
    const [selectedClassroomId, setSelectedClassroomId] = useState(-1);

    const handleSelect = (eventKey) => {
        setSelectedClassroomOption(`${classrooms[eventKey].degree}º ${classrooms[eventKey].division}`);
        setSelectedClassroomId(classrooms[eventKey].id)
        getScheduleByClassroomId(classrooms[eventKey].id)
    };

    const [selectedDivision, setSelectedDivision] = useState('');

    const [classrooms, setClassrooms] = useState([]);

    const [selectedLevelOption, setSelectedLevelOption] = useState('Nivel');
    const [selectedLevelId, setSelectedLevelId] = useState(null);

    const handleSelectLevel = (eventKey) => {
        setSelectedLevelOption(eventKey.name);
        setSelectedLevelId(eventKey.id);
    };

    const [selectedTimeStart, setSelectedTimeStart] = useState(null);
    const handleChangeTimeStart = date => {
        setSelectedTimeStart(date);
    };

    const [selectedTimeEnd, setSelectedTimeEnd] = useState(null);

    const handleChangeTimeEnd = date => {
        setSelectedTimeEnd(date);
    };

    const [selectedClassOption, setSelectedClassOption] = useState('Materia');
    const [selectedClassId, setSelectedClassId] = useState(null);

    const handleSelectClass = (eventKey) => {
        setSelectedClassOption(classes[eventKey].name);
        setSelectedClassId(classes[eventKey].id);
    };

    const [schedule, setSchedule] = useState([]);
    const [classes, setClasses] = useState([]);

    const getClassrooms = async function () {
        if (getUserRole() == "Docente Jardín" || getUserRole() == "Docente Primaria" || getUserRole() == "Docente Secundaria") {
            const req = await GET(`/classes/classrooms/byTeacherId`, getUserToken());
            const res = await req.json();

            if (req.status === 200) {
                console.log(res)
                setClassrooms(res);
                if (res.length > 0) {
                    getScheduleByClassroomId(res[0].id)
                    setSelectedClassroomOption(`${res[0].degree}º ${res[0].division}`);
                    setSelectedClassroomId(res[0].id)
                }

            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        } else {
            const req = await GET(`/classes/classrooms/bySchoolYear/${moment().format('YYYY')}`, getUserToken());
            const res = await req.json();

            if (req.status === 200) {
                console.log(res)
                setClassrooms(res);
                if (res.length > 0) {
                    getScheduleByClassroomId(res[0].id)
                    setSelectedClassroomOption(`${res[0].degree}º ${res[0].division}`);
                    setSelectedClassroomId(res[0].id)
                }

            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        }
    }

    const getScheduleByClassroomId = async function (classroomId) {
        const req = await GET(`/class/schedule/byClassroomId/${classroomId}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setSchedule(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getLevels = async function () {
        const req = await GET(`/curriculum/levels`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setLevels(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getClassesByClassroomId = async function () {
        const req = await GET(`/classes/classes/byClassroomId/${selectedClassroomId}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setClasses(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const postClassTime = async function () {
        const body = {
            hourId: newClassHourId,
            day: newClassDay,
            classId: selectedClassId
        }

        const req = await POST(`/class/schedule/classTime`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            getScheduleByClassroomId(selectedClassroomId);
            handleCloseNewClassTimeModal();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const ClassComponent = ({ startHour, endHour, name, color, teachers, i }) => {
        const [teacher, setTeacher] = useState({});
        const [show, setShow] = useState(false);

        const getUserById = async function (userId) {
            const req = await GET(`/sso/users/byId/${userId}`, getUserToken());
            const res = await req.json();

            if (req.status === 200) {
                console.log(res)
                setTeacher(res);
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        }

        const handleToggle = (nextShow) => {
            setShow(nextShow);
            if (nextShow) {
                setTeacher({});
                getUserById(teachers[0].userId);
            }
        };

        if (teachers && teachers.length > 0) {
            return (
                <OverlayTrigger key={i} trigger="click" placement="left" overlay={<Popover id={i} style={{ borderRadius: '15px', minWidth: '200px' }} rootCloseEvent="mousedown">
                    <div style={{ borderRadius: '15px', overflow: 'hidden' }}>
                        <Row>
                            <Col className='p-0'>
                                <div style={{ textAlign: 'center', backgroundColor: color ? color : '#0580FD', color: getTextColorForBackground(color) }} className='pt-1 pb-1 ps-3 pe-2' >
                                    {name}
                                </div>
                            </Col>
                            <Col className='endRow fitContentWidth' style={{ textAlign: 'center', backgroundColor: color ? color : '#0580FD', color: getTextColorForBackground(color) }}>
                                <FontAwesomeIcon icon={faTimes} style={{ color: getTextColorForBackground(color), position: 'absolute', right: '0px', marginTop: '6px', cursor: 'pointer' }} className='fitContentWidth me-2' onClick={() => handleToggle(!show)} />
                            </Col>
                        </Row>
                        <Row className='mt-2'>
                            <Col className='align-center-vertically' style={{ flexDirection: 'column' }}>
                                <div className='studentImg circle-container' style={{ width: '50px', height: '50px', border: 'none' }}>
                                    {<img src={teacher.avatarFile} />}
                                </div>
                                <div className='fitContentWidth roundedText-v2 borderDiv fs-11' style={{ position: 'absolute', top: '80px' }}>
                                    {teacher.surname && teacher.surname.toUpperCase()}, {teacher.name}
                                </div>
                                <p className='fs-11' style={{ marginTop: '23px' }}>{`${(startHour + "").slice(0, -2).padStart(2, '0')}:${(startHour + "").slice(-2)}`} a {`${(endHour + "").slice(0, -2).padStart(2, '0')}:${(endHour + "").slice(-2)}`}</p>
                            </Col>
                        </Row>
                    </div>
                </Popover>} rootClose show={show} onToggle={() => handleToggle(!show)}>
                    <div style={{ backgroundColor: color ? color : '#0580FD', color: getTextColorForBackground(color), borderRadius: '5px', height: '100%', width: '100%', textAlign: 'center', marginBottom: '2px', lineHeight: '16px', justifyContent: "center", cursor: 'pointer' }} className='align-center-vertically'>{name}</div>
                </OverlayTrigger>
            )
        } else {
            return (<div style={{ backgroundColor: color ? color : '#0580FD', color: getTextColorForBackground(color), borderRadius: '5px', height: '100%', width: '100%', textAlign: 'center', marginBottom: '2px', lineHeight: '16px', justifyContent: "center", cursor: 'pointer' }} className='align-center-vertically'>{name}</div>)
        }
    }

    const BreakComponent = () => {
        return (
            <div style={{ backgroundColor: '#6C757D', borderRadius: '5px', height: '100%', width: '100%', textAlign: 'center', marginBottom: '2px', lineHeight: '16px', justifyContent: "center", color: 'white' }} className='align-center-vertically'>RECREO</div>
        )
    }

    const FreeClassComponent = () => {
        return (
            <div style={{ backgroundColor: '#F0F0F0', borderRadius: '5px', height: '100%', width: '100%', textAlign: 'center', marginBottom: '2px', lineHeight: '16px', justifyContent: "center", color: 'black' }} className='align-center-vertically'>---</div>
        )
    }

    return (
        <div className="app">
            <Navbar />
            <div className="content fs-14" >
                <Row className='toolbar m-0'>
                    <Col>
                        {PREVIOUS_PAGE != "" ?
                            <Link to={PREVIOUS_URL} className='link-primary'>
                                <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver
                            </Link>
                            : ``}

                        <span className={`navbar-icon ${PREVIOUS_PAGE != "" ? `ms-4` : ``}`}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={PAGE_ICON}></FontAwesomeIcon></span>
                        <span className='ms-2'>{PREVIOUS_PAGE != "" ? `${PREVIOUS_PAGE} - ` : ``}{PAGE_TITLE}</span>
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <Col className='fitContentWidth'>
                        <div className='fitContentWidth align-center-vertically'>
                            <FontAwesomeIcon icon={faChalkboardTeacher} className='dropDownIcon' style={{ height: 16 }} />
                            <Dropdown className='dropdownButton'>
                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-200">
                                    {selectedClassroomOption}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedClassroomOption}>
                                    {classrooms.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelect(i)}>{opt.degree}º {opt.division}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Col>
                    <Col className='endRow'>

                    </Col>
                </Row>
                <Card className='cards mt-3'>
                    <table className='table m-0 br-15' style={{ textAlign: 'center' }}>
                        <thead className='table br-15'>
                            <tr style={{ borderBottomColor: 'rgb(222, 226, 230) !important', borderBottomWidth: '0px' }} className='tableHtml blueTasky-back whiteText'>
                                <td className='w-div6' style={{ width: '150px', borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px' }}></td>
                                <td className='w-div6'>Lunes</td>
                                <td className='w-div6'>Martes</td>
                                <td className='w-div6'>Miércoles</td>
                                <td className='w-div6'>Jueves</td>
                                <td className='w-div6' style={{ borderTopRightRadius: '8px', borderBottomRightRadius: '8px' }}>Viernes</td>
                            </tr>
                        </thead>
                        <tbody style={{ borderWidth: '0px !important' }}>
                            {schedule.map((r, i) =>
                                <tr key={i} style={{ borderRadius: '5px' }}>
                                    <td>
                                        <div style={{ height: `${r.isBreak ? '45px' : '70px'}`, borderRadius: '5px', justifyContent: 'center' }} className='blueTasky-back whiteText align-center-vertically'>
                                            {`${(r.start + "").slice(0, -2).padStart(2, '0')}:${(r.start + "").slice(-2)}`} a {`${(r.end + "").slice(0, -2).padStart(2, '0')}:${(r.end + "").slice(-2)}`}
                                        </div>
                                    </td>

                                    <td>
                                        <div style={{ height: `${r.isBreak ? '45px' : '70px'}`, display: 'flex', flexDirection: 'column' }}>
                                            {r.Days.monday.map((dd, i) => (
                                                <ClassComponent startHour={r.start} endHour={r.end} name={dd.name} color={dd.color} teachers={dd.Teachers} i={i} key={i} />
                                            ))}
                                            {r.Days.monday.length == 0 && !r.isBreak && <FreeClassComponent />}
                                            {r.isBreak && <BreakComponent />}
                                        </div>
                                    </td>
                                    <td>
                                        <div style={{ height: `${r.isBreak ? '45px' : '70px'}`, display: 'flex', flexDirection: 'column' }}>
                                            {r.Days.tuesday.map((dd, i) => (
                                                <ClassComponent startHour={r.start} endHour={r.end} name={dd.name} teachers={dd.Teachers} color={dd.color} i={i} key={i} />
                                            ))}
                                            {r.Days.tuesday.length == 0 && !r.isBreak && <FreeClassComponent />}
                                            {r.isBreak && <BreakComponent />}
                                        </div>
                                    </td>
                                    <td>
                                        <div style={{ height: `${r.isBreak ? '45px' : '70px'}`, display: 'flex', flexDirection: 'column' }}>
                                            {r.Days.wednesday.map((dd, i) => (
                                                <ClassComponent startHour={r.start} endHour={r.end} name={dd.name} teachers={dd.Teachers} color={dd.color} i={i} key={i} />
                                            ))}
                                            {r.Days.wednesday.length == 0 && !r.isBreak && <FreeClassComponent />}
                                            {r.isBreak && <BreakComponent />}
                                        </div>
                                    </td>
                                    <td>
                                        <div style={{ height: `${r.isBreak ? '45px' : '70px'}`, display: 'flex', flexDirection: 'column' }}>
                                            {r.Days.thursday.map((dd, i) => (
                                                <ClassComponent startHour={r.start} endHour={r.end} name={dd.name} teachers={dd.Teachers} color={dd.color} i={i} key={i} />
                                            ))}
                                            {r.Days.thursday.length == 0 && !r.isBreak && <FreeClassComponent />}
                                            {r.isBreak && <BreakComponent />}
                                        </div>
                                    </td>
                                    <td>
                                        <div style={{ height: `${r.isBreak ? '45px' : '70px'}`, display: 'flex', flexDirection: 'column' }}>
                                            {r.Days.friday.map((dd, i) => (
                                                <ClassComponent startHour={r.start} endHour={r.end} name={dd.name} teachers={dd.Teachers} color={dd.color} i={i} key={i} />
                                            ))}
                                            {r.Days.friday.length == 0 && !r.isBreak && <FreeClassComponent />}
                                            {r.isBreak && <BreakComponent />}
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </Card>
            </div>
        </div>
    )
}

export default Schedules