import { jwtDecode } from "jwt-decode";
import { GET, POST } from "./endpoints.controller";
import { formatName } from "../tools/tools";
import { UAParser } from "ua-parser-js";
import { v4 as uuidv4 } from 'uuid';

export const saveUserData = async function (user) {
    console.log("saving user data")
    localStorage.setItem('userToken', user.token);

    localStorage.setItem('userName', user.name);
    localStorage.setItem('userSurname', user.surname);

    let avatarFile = null;

    if (user.avatarFile) {
        if (user.avatarFile.file) {
            avatarFile = user.avatarFile.file
        } else {
            avatarFile = user.avatarFile
        }
    }

    if (user.mainId) {
        localStorage.setItem('userMainId', user.mainId);
    } else {
        localStorage.setItem('userMainId', user.id);
    }

    localStorage.setItem('userAvatar', avatarFile);
    localStorage.setItem('userRole', user.Role.name);
    localStorage.setItem('userId', user.id);
    localStorage.setItem('userHasChilds', user.hasChilds ? user.hasChilds : false);

    await getMyNavbar(user.token)
}

export const validateUser = function () {
    const token = localStorage.getItem("userToken");
    if (token !== undefined && token !== null) {
        if (token.length < 10) {
            logout();
        } else {
            window.location.href = "/inicio"
        }
    } else {
        logout();
    }
}

export const getChilds = async function () {
    let roles = [];

    const parser = new UAParser();
    const result = parser.getResult();
    const uuid = localStorage.getItem('uuId') != null ? localStorage.getItem('uuId') : uuidv4();
    localStorage.setItem('uuId', uuid)
    let body = {
        device: {
            uuid: uuid,
            brand: result.browser.name,
            osName: result.os.name,
            osVersion: result.os.version,
            model: result.browser.version,
            appVersion: "",
        }
    }

    console.log(body)
    console.log(getUserToken())

    const req = await POST('/sso/childs/mine', getUserToken(), body);
    const res = await req.json();

    if (req.status === 200) {
        for (let s of res) {
            for (let r of s.childs) {
                roles.push(r)
            }
        }

        if (roles.length == 1) {
            console.log(getUserRole())
            if (getUserRole().includes("Familia") || getUserRole().includes("Padre-Madre-Tutor")) {
                getMyStudents();
            } else {
                window.location.href = "/inicio";
            }
        } else if (roles.length > 1) {
            window.location.href = "/seleccionar-rol";
        }
    } else if (req.status === 403) {
        logout();
    } else {
        alert(res.message);
    }
}

export const validateToken = function () {
    const token = localStorage.getItem("userToken");
    console.log(token)

    if (token !== undefined && token !== null && token.trim() !== "") {
        const decoded = jwtDecode(token);
        try {
            if (decoded.user.id == null) {
                logout();
            }
        } catch (e) {
            logout();
        }
    } else {
        logout();
    }
}

export const getUserToken = function () {
    return localStorage.getItem('userToken');
}

export const getUserRole = function () {
    return localStorage.getItem('userRole');
}

export const getUserId = function () {
    return localStorage.getItem('userId');
}

export const getUserMainId = function () {
    return localStorage.getItem('userMainId');
}

export const getHasChilds = function () {
    return localStorage.getItem('userHasChilds');
}

export const logout = function () {
    localStorage.removeItem('userToken');
    localStorage.removeItem('userName');
    localStorage.removeItem('userSurname');
    localStorage.removeItem('userAvatar');
    localStorage.removeItem('userRole');
    localStorage.removeItem('userHasChilds');
    localStorage.removeItem('userId');
    localStorage.removeItem('userMainId');
    localStorage.removeItem('familyMemberId');
    localStorage.removeItem('familyMemberName');
    localStorage.removeItem('familyMemberSurname');
    localStorage.removeItem('familyMemberAvatarFile');
    localStorage.removeItem('familyMembersAmount');
    localStorage.removeItem('familyMemberToken');
    window.location.href = '/login';
}

export const forceLogout = function () {
    localStorage.removeItem('userToken');
    localStorage.removeItem('userName');
    localStorage.removeItem('userSurname');
    localStorage.removeItem('userAvatar');
    localStorage.removeItem('userRole');
    localStorage.removeItem('familyMemberId');
    localStorage.removeItem('familyMemberName');
    localStorage.removeItem('familyMemberSurname');
    localStorage.removeItem('familyMemberAvatarFile');
    localStorage.removeItem('familyMembersAmount');
    localStorage.removeItem('familyMemberToken');
    window.location.href = '/login';
}

export const saveAvatar = function (avatar) {
    localStorage.setItem('userAvatar', avatar);
}

export const isLoggedIn = function () {
    return localStorage.getItem('userToken') != null;
}

export const getMyStudents = async function () {
    const req = await GET('/sso/families/members/children/mine', getUserToken());
    const res = await req.json();

    if (req.status === 200) {
        console.log(res)
        if (res.length == 1) {
            localStorage.setItem('familyMemberId', res[0].id);
            localStorage.setItem('familyMemberName', res[0].name);
            localStorage.setItem('familyMemberSurname', res[0].surname);
            localStorage.setItem('familyMemberAvatarFile', res[0].avatarFile);
            localStorage.setItem('familyMembersAmount', res.length);
            saveChildToken(res[0].id);
        } else if (res.length > 1) {
            window.location.href = "/seleccionar-estudiante";
        } else {
            window.location.href = "/no-habilitado";
        }
    } else if (req.status === 403) {
        logout();
    } else {
        alert(res.message);
    }
}

export const getMyNavbar = async function (token) {
    console.log("getting my navbar")
    const req = await GET('/sso/navbar/items/mine', token);
    const res = await req.json();

    if (req.status === 200) {
        console.log("mi navbar")
        console.log(res)
        localStorage.setItem('navBar', JSON.stringify(res));
    } else if (req.status === 403) {
        logout();
    } else {
        alert(res.message);
    }
}

export const saveChildToken = async function (userId) {

    const parser = new UAParser();
    const result = parser.getResult();
    const uuid = localStorage.getItem('uuId') != null ? localStorage.getItem('uuId') : uuidv4();
    let body = {
        userId: userId,
        device: {
            uuid: uuid,
            brand: result.browser.name,
            osName: result.os.name,
            osVersion: result.os.version,
            model: result.browser.version,
            appVersion: "",
        }
    }
    console.log(body)
    const req = await POST(`/sso/families/children/token/byUserId/`, getUserToken(), body);
    const res = await req.json();

    if (req.status === 200) {
        console.log(res)
        localStorage.setItem('familyMemberToken', res.token);
        localStorage.setItem('familyMemberGrade', `${res.degree}º ${res.division}`);
        await getMyNavbar(res.token)
        window.location.href = "/inicio";
    } else if (req.status === 403) {
        logout();
    } else {
        alert(res.message);
    }
}


export const getFamilyMemberId = function () {
    return localStorage.getItem('familyMemberId');
}

export const getFamilyMembersAmount = function () {
    return localStorage.getItem('familyMembersAmount');
}

export const getFamilyMemberToken = function () {
    return localStorage.getItem('familyMemberToken');
}

export const getAllLocalStorageData = function () {
    const items = [
        'userToken',
        'userName',
        'userSurname',
        'userMainId',
        'userAvatar',
        'userRole',
        'userId',
        'userHasChilds',
        'uuId'
    ];
    let data = [];

    for (let i of items) {
        data.push(<p className="m-0">localStorage.setItem('{i}', '{localStorage.getItem(`${i}`)}')</p>)
    }

    return data;
}