import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { GET, PUT } from '../../../controllers/endpoints.controller';
import { getFamilyMemberId, getFamilyMemberToken, getUserToken, logout } from '../../../controllers/user.controller';
import ActionButton from '../../general-components/ActionButton';
import { faCheck, faPen, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';

function InscriptionNextYear() {

    const [childId, setChildId] = useState(1434);
    const [userToken, setUserToken] = useState(getUserToken());

    useEffect(() => {
        getStudentInfo(childId);
        getStudent(childId);
    }, [])

    const yearNow = moment().format('YYYY');
    const datosMatriculacion = [
        'Datos del alumno'
    ]

    const [dataFiles, setDataFiles] = useState(null);
    const getStudentDataFiles = async function (userId) {
        const req = await GET(`/students/files/byUserId/${userId}`, userToken);
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setDataFiles(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }


    const [student, setStudent] = useState(null);
    const getStudent = async function (userId) {
        const req = await GET(`/sso/users/byId/${userId}`, userToken);
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setStudent(res)
            document.title = `Tasky - ${res.name} ${res.surname}`;
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }


    const [dataItemsState, setDataItemsState] = useState(null);
    const [dataStudent, setDataStudent] = useState(null);
    const [isStudentDataLoaded, setIsStudentDataLoaded] = useState(false);
    const getStudentInfo = async function (userId) {
        setIsStudentDataLoaded(false);
        const req = await GET(`/students/data/byUserId/${userId}`, userToken);
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setDataStudent(res)
            setIsStudentDataLoaded(true);
            setDataItemsState(Array(res.length).fill(false))
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    useEffect(() => {
        if (dataStudent != null) {
            groupDataStudent()
        }
    }, [dataStudent])

    const [optionsDataTypes, setOptionsType] = useState(null)
    const getDataTypesStudents = async function () {
        const req = await GET(`/students/data/params`, userToken);
        const res = await req.json();
        if (req.status === 200) {
            console.log(res)
            setOptionsType(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const putStudentData = async function (id, i, userId, param) {
        const body = {
            param: param,
            value: document.getElementById(`inputEditModal-${i}`).value
        }

       /*  const req = await PUT(`/students/data/updateByIdAndUserId/${id}/${userId}`, userToken, body);
        const res = await req.json();

        if (req.status === 201) {
            console.log(res)
            getStudentInfo(childId);

        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        } */
    }

    const [actaulPosition, setActualPosition] = useState(0);

    const handlePosition = (index) => {
        setActualPosition(index)
    }

    const dataListItems = [
        { title: 'Datos personales', params: ['BIRTH_DATE', 'DATE_BIRTH', 'NATIONALITY', 'NACIONALITY', 'GENDER', 'DOCUMENT'] },
        { title: 'Curso', params: ['LEVEL_ID', 'STATUS', 'EXPENDITURE_DATE', 'INCOME_DATE'] },
        { title: 'Contacto', params: ['PHONE_NUMBER', 'EMAIL'] },
        { title: 'Contacto de emergencia', params: [] },
        { title: 'Domicilio', params: ['ADDRESS_LINE1', 'ADDRESS_LINE2', 'CITY'] },
        { title: 'Observación médica', params: [] },
        { title: 'Madre', params: ['MOTHER_NAME', 'MOTHER_NATIONALITY', 'MOTHER_PROFESSION', 'MOTHER_DOCUMENT', 'MOTHER_PHONE', 'MOTHER_EMAIL'] },
        { title: 'Padre', params: ['FATHER_NAME', 'FATHER_NATIONALITY', 'FATHER_PROFESSION', 'FATHER_DOCUMENT', 'FATHER_PHONE', 'FATHER_EMAIL'] },
        { title: 'Tutor', params: ['TUTOR_NAME', 'TUTOR_NATIONALITY', 'TUTOR_PROFESSION', 'TUTOR_DOCUMENT', 'TUTOR_PHONE', 'TUTOR_EMAIL'] },
        { title: 'Hermanos', params: [] },
        { title: 'Responsable de pago', params: [] },
        { title: 'Personas autorizadas', params: [] },
        { title: 'Otros', params: null },
    ]

    const [studentDataList, setStudentDataList] = useState(dataListItems)
    const groupDataStudent = () => {
        let tempDataList = [...dataListItems];
        for (let s of dataStudent) {
            let index = tempDataList.findIndex(item =>
                item.params && item.params.includes(s.param)
            );
            if (index === -1) {
                index = tempDataList.length - 1;
            }
            if (!tempDataList[index].elements) {
                tempDataList[index].elements = [];
            }
            tempDataList[index].elements.push(s);
        }
        //Aregar el mail
        if (student.email) {
            let index = tempDataList.findIndex(item =>
                item.params && item.params.includes('EMAIL')
            );
            if (index === -1) {
                index = tempDataList.length - 1;
            }
            if (!tempDataList[index].elements) {
                tempDataList[index].elements = [];
            }
            tempDataList[index].elements.push({ param: 'EMAIL', StudentDataParam: { name: 'Correo' }, value: `${student.email}` });
        }

        console.log(tempDataList)

        setStudentDataList(tempDataList);
    }

    const [editDataState, setEditDataState] = useState(-1);
    const handleEditOneDataField = (id, i, j, state) => {
        if (state) {
            if (editDataState != -1) {
                console.log(document.getElementById(`inputEditModal-${editDataState}`))
                document.getElementById(`inputEditModal-${editDataState}`).value = studentDataList[i].elements[j].value;
            }
            setEditDataState(id);
        } else {
            setEditDataState(-1);
            console.log( studentDataList)
            document.getElementById(`inputEditModal-${id}`).value = studentDataList[i].elements[j].value;
        }
    }


    return (
        <div style={{ backgroundColor: '#F2F3F9', width: '100vw', minHeight: '100vh' }} className='ps-0 pe-0'>
            <div className="login-container pt-5 pb-5" style={{ width: '100%', height: '100%' }}>
                <div className="login-card" style={{ width: '80vw' }}>
                    <div className="logo">
                        <img src="/logo_color.svg" width={130} alt="Tasky Logo" />
                    </div>

                    <Row className='mt-3'>
                        <div style={{ textAlign: 'center' }} className='fs-16'>
                            Realiza la matriculación {yearNow}
                        </div>
                    </Row>

                    <Row className='mt-2 mb-2'>
                        {student && <div>
                            <img className="avatar-70" src={student.avatarFile} />
                            <p className='fitContentWidth roundedText-v2 borderDiv fs-14 mt-1' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                {student.surname ? student.surname.toUpperCase() : ''}, {student.name}
                            </p>
                        </div>}

                        {actaulPosition == 1 ?
                            <div>
                                <div style={{ textAlign: 'left' }} className='mt-2 mb-3'>
                                    Confirmar datos del estudiante
                                </div>

                                {studentDataList.map((r, i) => (
                                    r.elements && r.elements.length > 0 && <>
                                        <p className='fw-600 mb-1' style={{ textAlign: 'left' }}>{r.title}</p>
                                        {r.elements.map((d, j) => !d.isReadOnly && (
                                            <Row className={`entireRow ps-0 pe-0 mb-2`}>
                                                <Col md={3} className='pt-2 pb-2 ps-4 pe-4 align-center-vertically' style={{ background: "#3680F4", color: "white", borderRadius: '8px' }}>
                                                    {d.StudentDataParam && d.StudentDataParam.name}
                                                </Col>
                                                <Col className='pt-1 pb-1 ps-4'>
                                                    <div className='align-center-vertically fitContentWidth ms-2' style={{ height: '100%' }}>
                                                        <Form.Control disabled={editDataState != d.id} className='textInputGray fs-14 wi-300' id={`inputEditModal-${d.id}`} placeholder="" defaultValue={d.value} />
                                                    </div>
                                                </Col>
                                                <Col className='fitContentWidth' >
                                                    {editDataState != d.id ? <div className='align-center-vertically pt-1 pb-1' style={{ height: '100%' }}>
                                                        <ActionButton variant='secondary' iconFa={faPen} classExtra='' func={() => handleEditOneDataField(d.id, i, j, true)} />
                                                        {/* <ActionButton variant='danger' iconFa={faTrash} func={() => handleShowConfirmDeleteModal(r, i)} /> */}
                                                    </div> :
                                                        <div className='align-center-vertically pt-1 pb-1' style={{ height: '100%' }}>
                                                            <ActionButton variant='secondary' iconFa={faTimes} classExtra='me-2' func={() => handleEditOneDataField(d.id, i, j, false)} />
                                                            <ActionButton variant='primary' iconFa={faCheck} func={() => putStudentData(d.id, d.id, childId)} />
                                                        </div>
                                                    }
                                                </Col>
                                            </Row>
                                        ))}</>
                                ))}
                            </div> : ''}
                    </Row>

                    {actaulPosition == 0 ? <div className='endRow mt-2'>
                        <Link to="/"><Button variant='outline-primary' >Ver más tarde</Button></Link>
                        <Button variant='primary' className='ms-2' onClick={() => handlePosition(actaulPosition + 1)}>Comenzar</Button>
                    </div>
                        : actaulPosition == 1 ?
                            <div className='endRow mt-2'>
                                <Link to="/"><Button variant='outline-secondary' >Cancelar</Button></Link>
                                <Button variant='primary' className='ms-2' onClick={() => handlePosition(actaulPosition + 1)}>Confirmar</Button>
                            </div>
                            : ''}
                </div>
            </div>
        </div>
    )
}

export default InscriptionNextYear