import { faArrowLeft, faCalendar, faChalkboardTeacher, faCheck, faCog, faEllipsisV, faEye, faFile, faPen, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react'
import { Badge, Button, Card, Col, Modal, Row, Table, Form, Dropdown } from 'react-bootstrap';
import Navbar from '../../navbar/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import ActionButton from '../../general-components/ActionButton';
import { getUserToken, logout, validateToken } from '../../../controllers/user.controller';
import { GET, POST } from '../../../controllers/endpoints.controller';

function CurriculumConfig() {
    const PAGE_TITLE = "Planes de estudio";
    const PAGE_ICON = faCog;
    const PREVIOUS_PAGE = "Configuración";
    const PREVIOUS_URL = "/configuracion"

    useEffect(() => {
        document.title = `Tasky - ${PAGE_TITLE}`;
        validateToken();

        getCurriculum();
        getLevels();
    }, []);

    const [curriculums, setCurriculums] = useState([]);

    //Modal
    const [showNewCurriculum, setShowNewCurriculum] = useState(false);

    const handleShowNewCurriculum = () => {
        setShowNewCurriculum(true)
    };

    const handleCloseModalNewCurriculum = () => {
        setCurriculumName('');
        setCurriculumResolution('');
        setCurriculumValidity('');
        setShowNewCurriculum(false);
    }

    const [curriculumName, setCurriculumName] = useState('');

    const handleCurriculumName = (event) => {
        setCurriculumName(event.target.value);
    };

    const [curriculumResolution, setCurriculumResolution] = useState('');
    const handleCurriculumResolution = (event) => {
        setCurriculumResolution(event.target.value);
    };


    const [curriculumValidity, setCurriculumValidity] = useState('');
    const handleCurriculumValidity = (event) => {
        setCurriculumValidity(event.target.value);
    };

    const [levels, setLevels] = useState([]);
    const [selectedLevelOption, setSelectedLevelOption] = useState('Nivel');
    const [curriculumLevelId, setCurriculumLevelId] = useState(null);

    const handleSelectLevel = (eventKey) => {
        setSelectedLevelOption(eventKey.name);
        setCurriculumLevelId(eventKey.id);
    };

    const getCurriculum = async function () {
        const req = await GET(`/curriculum/curriculums`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            let result = [];
            console.log(res)
            setCurriculums(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getLevels = async function () {
        const req = await GET(`/curriculum/levels`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setLevels(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const postCurriculum = async function () {
        const body = {
            name: curriculumName,
            resolution: curriculumResolution,
            year: curriculumValidity,
            isCurrent: true,
            levelId: curriculumLevelId
        }

        const req = await POST(`/curriculum/curriculum`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            handleCloseModalNewCurriculum();
            getCurriculum();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    return (
        <div className="app">
            <Navbar />
            <div className="content">
                <Row className='toolbar m-0'>
                    <Col>
                        {PREVIOUS_PAGE != "" ?
                            <Link to={PREVIOUS_URL} className='link-primary'>
                                <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver
                            </Link>
                            : ``}

                        <span className={`navbar-icon ${PREVIOUS_PAGE != "" ? `ms-4` : ``}`}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={PAGE_ICON}></FontAwesomeIcon></span>
                        <span className='ms-2'>{PREVIOUS_PAGE != "" ? `${PREVIOUS_PAGE} - ` : ``}{PAGE_TITLE}</span>
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <Col className='endRow'>
                        <ActionButton iconFa={faPlus} title={'Nuevo plan de estudio'} func={handleShowNewCurriculum} classExtra='fs-14' />
                    </Col>
                </Row>
                <Card className='cards mt-3'>
                    <div>
                        <Row className='p-2 mb-2' style={{ color: 'white', backgroundColor: '#3680F4', borderRadius: 8 }}>
                            <Col md={8} className='align-center-vertically-v2'>
                                <div>
                                    Nombre
                                </div>
                            </Col>
                            <Col md={2} className='align-center-vertically-v2' >
                                <div style={{ textAlign: 'center' }}>
                                    Nivel
                                </div>
                            </Col>
                            <Col md={1} className='align-center-vertically-v2'>
                                <div style={{ textAlign: 'center' }}>
                                    Vigente
                                </div>
                            </Col>
                            <Col md={1} className='align-center-vertically-v2'>
                            </Col>
                        </Row>
                    </div>

                    {curriculums.map((r, i) => (
                        <div>
                            <div className='d-none d-lg-block'>
                                <Row className='mb-1 ps-2 pe-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                    <Col md={8} className='align-center-vertically-v2 pt-1 pb-1'>
                                        <div style={{ textAlign: 'left' }}>
                                            <p className='fs-11 mb-0 text-muted'>{r.year} - {r.resolution}</p>
                                            <p className='m-0'>{r.name}</p>
                                        </div>
                                    </Col>
                                    <Col md={2} className='align-center-vertically-v2' >
                                        <div style={{ textAlign: 'center' }}>
                                            {r.Level ? r.Level.name : ""}
                                        </div>
                                    </Col>
                                    <Col md={1} className='align-center-vertically-v2'>
                                        <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }} className='mt-1 mb-1'>
                                            <Badge bg='' style={{ background: r.isCurrent ? "green" : "red" }} className='statusBadge fs-14'>{r.isCurrent ? "Si" : "No"}</Badge>
                                        </div>
                                    </Col>
                                    <Col md={1} className='align-center-vertically-v2'>
                                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <Link to={`/configuracion/planes-estudio/años/?id=${r.id}`}>
                                                <Button variant="secondary">
                                                    <span><FontAwesomeIcon icon={faEye} /></span>
                                                </Button>
                                            </Link>

                                        </div>

                                    </Col>
                                </Row>
                            </div>
                        </div>
                    ))}
                </Card>
            </div>

            <Modal size='lg' show={showNewCurriculum} onHide={handleCloseModalNewCurriculum} centered className='assitanceModal modalContainer' backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropModalCont dropDownIcon' icon={faCog} style={{ minWidht: '20px' }} /><Modal.Title>Nuevo plan de estudio</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={6} className='mb-2'>
                            <div className='align-center-vertically'>
                                <div className='dropDownIcon' ></div>
                                <Form.Control className='textInputGray fs-14 wi-300' placeholder="Nombre" onChange={handleCurriculumName} value={curriculumName} />
                            </div>
                        </Col>
                        <Col md={6} className='mb-2'>
                            <div className='align-center-vertically'>
                                <FontAwesomeIcon icon={faFile} className='dropDownIcon' style={{ height: 16 }} />
                                <Form.Control className='textInputGray fs-14 wi-300' placeholder="Resolución" onChange={handleCurriculumResolution} value={curriculumResolution} />
                            </div>
                        </Col>
                        <Col md={6} className='mb-2'>
                            <div className='align-center-vertically'>
                                <FontAwesomeIcon icon={faCalendar} className='dropDownIcon' style={{ height: 16 }} />
                                <Form.Control className='textInputGray fs-14 wi-300' placeholder="Vigencia" onChange={handleCurriculumValidity} value={curriculumValidity} />
                            </div>
                        </Col>
                        <Col md={6} className='mb-2 align-center-vertically'>
                            <FontAwesomeIcon icon={faChalkboardTeacher} className='dropDownIcon' style={{ height: 16 }} />
                            <Dropdown className='dropdownButton wi-300 p-0'>
                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-300">
                                    {selectedLevelOption}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedLevelOption}>
                                    {levels.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelectLevel(opt)}>{opt.name}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14 me-2' onClick={handleCloseModalNewCurriculum}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                    <Button variant="primary" className='fs-14' onClick={postCurriculum}><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default CurriculumConfig