import React, { useEffect, useRef, useState } from 'react'
import Navbar from '../../navbar/Navbar';
import { Accordion, Badge, Button, Card, Col, Dropdown, DropdownMenu, Form, Modal, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpen, faCalendar, faChalkboardTeacher, faCheck, faChevronRight, faClipboardCheck, faClipboardUser, faEllipsisV, faExclamationCircle, faEye, faFile, faFileCircleExclamation, faList, faPen, faPenAlt, faPlus, faPlusCircle, faPlusMinus, faSearch, faTimes, faToggleOn, faTrash, faUser, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import ActionButton from '../../general-components/ActionButton';
import { getUserId, getUserRole, getUserToken, logout, validateToken } from '../../../controllers/user.controller';
import { DEL, GET, POST, PUT } from '../../../controllers/endpoints.controller';
import moment from 'moment';
import { formatNameV2, getTextColorForBackground, pluralize } from '../../../tools/tools';
import ReactDatePicker from 'react-datepicker';
import InfoField from '../../general-components/InfoField';
import Loading from '../../general-components/Loading';
import NotFound from '../../general-components/NotFound';

function Sanctions() {
    const PAGE_TITLE = "Sanciones";
    const PAGE_ICON = faExclamationCircle;

    useEffect(() => {
        document.title = `Tasky - ${PAGE_TITLE}`;
        validateToken();
        getSanctionsType();
        getClassrooms();
        getSanctionsReasons();
    }, []);

    const [sanctions, setSanctions] = useState(null);
    const [myRole, setMyRole] = useState(getUserRole());

    //Modal funciones
    const [showNewSanction, setShowNewSanction] = useState(false);

    const handleShowNewSanction = () => {
        setSelectedAmountOption(1);
        setSelectedSanctionTypeOption(null);
        setIsEditingSanction(false);
        setShowNewSanction(true)
        setSelectedContacts1([]);
        setSelectedContacts2([]);
        setSelectedDateDay(new Date());
        setnewSanctionDescription("")
        setSelectedSanctionReason({ name: 'Motivo' })
        setIsPostReady(-1)
    };

    const handleCloseModalNewSanction = () => {
        setShowNewSanction(false);
    }

    const [optionsSanctionType, setSanctionType] = useState([]);
    const [selectedSanctionTypeOption, setSelectedSanctionTypeOption] = useState(null);
    const handleSelectSanctionType = (eventKey) => {
        setSelectedSanctionTypeOption(optionsSanctionType[eventKey]);
    };

    let optionsAmount = [1, 2, 3, 4, 5];
    const [selectedAmountOption, setSelectedAmountOption] = useState('Cantidad');
    const handleAmount = (eventKey) => {
        setSelectedAmountOption(optionsAmount[eventKey]);
    };

    const [newSanctionDescription, setnewSanctionDescription] = useState('');
    const handleNewSanctionDescription = (e) => {
        setnewSanctionDescription(e.target.value);
    };

    function findSanctionType(id) {
        return optionsSanctionType.find(sanctionType => sanctionType.id == id);
    }

    const [status, setStatus] = useState([{ name: 'Todas', value: "null" }, { name: 'Aprobadas', value: "approved" }, { name: 'Desaprobadas', value: "disapproved" }]);
    const [selectedStatusOption, setSelectedStatusOption] = useState({ name: 'Todas', value: "null" });
    const handleSelectStatus = (opt) => {
        setSelectedStatusOption(opt);

        getSanctions(selectedClassroomId, groupSanctions, opt.value)
    };

    const [sanctionToEdit, setSanctionToEdit] = useState(null)
    const [isEditingSanction, setIsEditingSanction] = useState(false)
    const showModaltoEditSanction = (r) => {
        setIsEditingSanction(true)
        setSanctionToEdit(r);
        setSelectedAmountOption(r.amount)
        setnewSanctionDescription(r.content)

        //Para que no aparezca disbled confirmar
        setSelectedSanctionTypeOption(1);
        setSelectedAmountOption(1);
        setSelectedContacts1(["person 1"]);
        setSelectedContacts2(["Person 2"]);
        setSelectedDateDay("2024")
        // -----------
        setShowNewSanction(true);
        setIsPostReady(-1)
    }

    useEffect(() => {
        if (isEditingSanction) {
            setSelectedSanctionTypeOption(findSanctionType(sanctionToEdit.typeId));
            setSelectedAmountOption(sanctionToEdit.amount);
            document.getElementById("sanctionDescription").value = sanctionToEdit.content;
        }
    }, [showNewSanction]);

    const [isTableLoaded, setIsTableLoaded] = useState(false);
    const [isPostReady, setIsPostReady] = useState(-1);

    const getSanctions = async function (classroomId, grouped = -1, status = -1) {
        setIsTableLoaded(false)

        let searchChain = "null";
        if (window.getComputedStyle(document.getElementById('textSearchMobile')).display == "block") {
            searchChain = document.getElementById('textSearchMobile').value.trim() != "" ? document.getElementById('textSearchMobile').value : "null";
        } else {
            searchChain = document.getElementById('textSearchDesktop').value.trim() != "" ? document.getElementById('textSearchDesktop').value : "null";
        }

        //console.log(`/sanctions/byClassroom&Status&Search&Grouped/${classroomId}/${status == -1 ? selectedStatusOption.value : status}/${searchChain}/${grouped == -1 ? groupSanctions : grouped}`)
        const req = await GET(`/sanctions/byClassroom&Status&Search&Grouped/${classroomId}/${status == -1 ? selectedStatusOption.value : status}/${searchChain}/${grouped == -1 ? groupSanctions : grouped}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setSanctions(res)
            setIsTableLoaded(true);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getSanctionsType = async function () {
        const req = await GET(`/sanctions/type/byLevelId`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setSanctionType(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const postSanction = async function () {
        setIsPostReady(false);

        const body = {
            content: document.getElementById('sanctionDescription').value,
            amount: selectedAmountOption,
            typeId: selectedSanctionTypeOption.id,
            toIds: selectedContacts1.map(sc => sc.id),
            fromId: selectedContacts2[0].id,
            date: selectedDateDay,
            reasonId: selectedSanctionReason.id
        }

        const req = await POST(`/sanctions`, getUserToken(), body);
        const res = await req.json();
        if (req.status === 201) {
            handleCloseModalNewSanction();
            getSanctions(selectedClassroomId);
            setIsPostReady(true)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [deletesanctionId, setDeletesanctionId] = useState(null);
    const [deletesanctionSelected, setDeletesanctionSelected] = useState(null);

    const handleCloseConfirmDeleteModal = () => setShowConfirmDeleteModal(false);
    const handleShowConfirmDeleteModal = (r) => {
        setDeletesanctionId(r.id);
        setShowConfirmDeleteModal(true);
        setDeletesanctionSelected(r);
    };

    const deletesanction = async function (id) {
        const body = {
            id: id
        }
        const req = await DEL(`/sanctions/sanction`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            console.log(res)
            getSanctions(selectedClassroomId);
            handleCloseConfirmDeleteModal()
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const putModifiedSanction = async function (isAproving = false) {
        setIsPostReady(false)
        let body;
        if (isAproving) {
            body = {
                approved: selectedStateSanction.value,
                approvedFromId: getUserId(),
            };
        } else {
            body = {
                amount: selectedAmountOption,
                content: document.getElementById('sanctionDescription').value,
                typeId: selectedSanctionTypeOption.id,
            };
        }
        const req = await PUT(`/sanction/${sanctionToEdit.id}`, getUserToken(), body);
        const res = await req.json();
        if (req.status === 201) {
            console.log(res)
            getSanctions(selectedClassroomId);
            if (isAproving) {
                handleCloseConfirmChangeStateModal();
            } else {
                handleCloseModalNewSanction();
            }
            setIsPostReady(true)

        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const handleSubmitSanction = () => {
        if (isEditingSanction) {
            putModifiedSanction();
        } else {
            postSanction();
        }
    }

    const [showConfirmChangeStateModal, setShowConfirmChangeStateModal] = useState(false);

    const handleCloseConfirmChangeStateModal = () => setShowConfirmChangeStateModal(false);
    const handleShowConfirmChangeStateModal = (r) => {
        setSanctionToEdit(r);
        setIsPostReady(-1);
        setShowConfirmChangeStateModal(true);
    };

    const handleOutsideClick = (event) => {
        const clickedOutside = !event.target.closest('#searchModalSection');
        if (clickedOutside && document.getElementById('textSearch-1') && document.getElementById('textSearch-2')) {
            document.getElementById('textSearch-1').value = '';
            document.getElementById('textSearch-2').value = '';
            setIsClickOut(true);
        } else {
            setIsClickOut(false);
        }
    }
    const [isClickOut, setIsClickOut] = useState(false)

    const SearchBar = ({ searchPlaceholder, roles, selectedContacts, setSelectedContacts, faIcon, id }) => {
        const [searchResults, setSearchResults] = useState([]);

        const handleSearchChange = () => {
            setSearchResults([]);
            searchUsers();
        };

        const handleContactClick = (contact) => {
            if (!selectedContacts.find(selected => selected.id === contact.id)) {
                if (searchPlaceholder.includes("estud")) {
                    setSelectedContacts([...selectedContacts, contact]);
                } else {
                    setSelectedContacts([contact]);
                }

                document.getElementById(`textSearch-${id}`).value = '';
                setSearchResults([]);
            }
        };

        const removeContact = (contactToRemove) => {
            const updatedContacts = selectedContacts.filter(contact => contact.id !== contactToRemove.id);
            setSelectedContacts(updatedContacts);
        };

        const searchUsers = async () => {
            const chain = document.getElementById(`textSearch-${id}`).value;

            if (chain.length >= 3) {
                const req = await GET(`/sso/users/search/ByRoles/${roles.join(",")}/${chain}`, getUserToken());

                const res = await req.json();

                if (req.status === 200) {
                    console.log(res)
                    setSearchResults(prevResults => [...prevResults, ...res]);
                } else if (req.status === 403) {
                    logout();
                } else {
                    alert(res.message);
                }
            } else {
                setSearchResults([]);
            }
        };

        const getMyUser = async () => {
            const myId = getUserId()
            const req = await GET(`/sso/users/byId/${myId}`, getUserToken());

            const res = await req.json();

            if (req.status === 200) {
                console.log(res)
                handleContactClick(res)
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        }

        return (
            <Col className='ps-0'>
                <div className='align-center-vertically '>
                    <FontAwesomeIcon icon={faIcon} className='dropDownIcon' style={{ height: 16 }} />
                    <div className=''>
                        <div style={{ display: 'flex', justifyContent: 'center' }} id={`searchDiv-${searchPlaceholder}`}>
                            <Form.Control
                                className='d-none d-lg-block textInputGray fs-14 wi-350'
                                autoComplete="off"
                                id={`textSearch-${id}`}
                                placeholder={searchPlaceholder}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSearchChange()
                                    }
                                }}
                            />
                            <Form.Control
                                className='d-block d-lg-none textInputGray fs-14 wi-250'
                                autoComplete="off"
                                id={`textSearch-${id}`}
                                placeholder={searchPlaceholder}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSearchChange()
                                    }
                                }}
                            />
                            <Button onClick={() => handleSearchChange()} className='ms-2'>
                                <FontAwesomeIcon icon={faSearch} />
                            </Button>
                            {searchPlaceholder.includes('solicitante') ?
                                <Button variant='primary' className='ms-2' onClick={() => getMyUser()}><FontAwesomeIcon icon={faUser} className='me-2' />Yo</Button>
                                :
                                ""
                            }
                        </div>
                        <div className='search-results' style={{ display: searchResults.length > 0 ? 'block' : 'none' }}>
                            {!isClickOut && searchResults && searchResults.map((contact, index) => (
                                <div key={index} onClick={() => handleContactClick(contact)} style={{ cursor: 'pointer' }} className='me-1'>
                                    {contact.surname && contact.surname.toUpperCase()}, {contact.name}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className='align-center-vertically wrapCont ms-4 mb-2'>
                    {selectedContacts.map((contact, index) => (
                        <><div key={index} className='userSelectedModal mt-2 me-1' style={{ display: 'inline-block' }}>
                            <span>{contact.name} {contact.surname}</span>
                            <FontAwesomeIcon onClick={() => removeContact(contact)} icon={faTimes} className='remiderCross ms-2' />
                        </div></>
                    ))}
                </div>
            </Col>
        );
    };

    const [selectedContacts1, setSelectedContacts1] = useState([]);
    const [selectedContacts2, setSelectedContacts2] = useState([]);

    const [selectedDateDay, setSelectedDateDay] = useState(new Date());
    const handleChangeDate = date => {
        setSelectedDateDay(date);

    };

    let statesOptions = [{ value: 1, name: 'Aprobada' }, { value: 0, name: 'Desaprobada' }];
    const [selectedStateSanction, setselectedStateSanction] = useState(statesOptions[0]);
    const handleStateSanction = (eventKey) => {
        setselectedStateSanction(statesOptions[eventKey]);
    };

    const [selectedClassroomOption, setSelectedClassroomOption] = useState('Curso');
    const [selectedClassroomId, setSelectedClassroomId] = useState(-1);
    const [classrooms, setClassrooms] = useState([]);

    const getClassrooms = async function () {
        if (getUserRole() == "Docente Jardín" || getUserRole() == "Docente Primaria" || getUserRole() == "Docente Secundaria") {
            const req = await GET(`/classes/classrooms/byTeacherId`, getUserToken());
            const res = await req.json();

            if (req.status === 200) {
                console.log(res)
                setClassrooms(res);

                if (res.length > 0) {
                    setSelectedClassroomOption(`Todos`);
                    setSelectedClassroomId("null")
                    getSanctions("null");
                }
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        } else {
            const req = await GET(`/classes/classrooms/bySchoolYear/${moment().format('YYYY')}`, getUserToken());
            const res = await req.json();

            if (req.status === 200) {
                console.log(res)
                setClassrooms(res);

                if (res.length > 0) {
                    setSelectedClassroomOption(`Todos`);
                    setSelectedClassroomId("null")
                    getSanctions("null");
                }
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        }
    }

    const handleSelect = (eventKey) => {
        if (eventKey == -1) {
            setSelectedClassroomOption(`Todos`);
            setSelectedClassroomId("null")
            getSanctions("null");
        } else {
            setSelectedClassroomOption(`${classrooms[eventKey].degree}º ${classrooms[eventKey].division}`);
            setSelectedClassroomId(classrooms[eventKey].id)
            getSanctions(classrooms[eventKey].id)
        }

        setIsTableLoaded(false);
    };

    const [showApprovementDetailModal, setShowApprovementDetailModal] = useState(false);
    const [viewSanction, setViewSanction] = useState(null);

    const handleCloseApprovementDetailModal = () => setShowApprovementDetailModal(false);
    const handleShowApprovementDetailModal = (r) => {
        setViewSanction(r);
        setShowApprovementDetailModal(true);
    };

    const putApproveSanction = async function (sanctionId, approve) {
        const body = {
            id: sanctionId,
        };

        const endpoint = approve == true ? '/sanctions/status/approve' : '/sanctions/status/disapprove';

        const req = await PUT(endpoint, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            console.log(res)
            getSanctions(selectedClassroomId);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const [sanctionReasons, setSanctionReasons] = useState([]);
    const [selectedSanctionReason, setSelectedSanctionReason] = useState({ name: 'Motivo' });
    const handleSanctionReason = (eventKey) => {
        setSelectedSanctionReason(sanctionReasons[eventKey]);
    };

    const getSanctionsReasons = async function () {
        const req = await GET(`/sanctions/reasons/byLevel`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setSanctionReasons(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const [groupSanctions, setGroupSanctions] = useState(false);
    const handleCheckGroupSanctions = (e) => {
        setGroupSanctions(!groupSanctions);
        getSanctions(selectedClassroomId, !groupSanctions)
    };

    const [groupSanctionsList, setGroupSanctionsList] = useState([{
        name: 'Federico',
        surname: 'Gomez',
        avatarFile: '',
        sanctions: [
            {
                "id": 19,
                "title": "Falta de respeto",
                "content": "Muchas veces seguidas",
                "amount": 1,
                "typeId": 2,
                "reasonId": null,
                "approved": true,
                "date": null,
                "createdAt": "2024-07-10T13:59:13.000Z",
                "updatedAt": "2024-07-30T17:06:20.000Z",
                "from": {
                    "id": 2098,
                    "name": "Macarena",
                    "surname": "Suárez",
                    "email": "m.suarez@sanvicente.redfasta.edu.ar"
                },
                "to": {
                    "id": 1434,
                    "name": "Ana Lucía",
                    "surname": "Abiusi Nan",
                    "email": "alabiusinan@sanvicente.redfasta.edu.ar"
                },
                "approvedBy": {
                    "id": 2188,
                    "name": "Valentina",
                    "surname": "Francesch",
                    "email": "val.francesch@gmail.com"
                },
                "disapprovedBy": {
                    "id": 2188,
                    "name": "Valentina",
                    "surname": "Francesch",
                    "email": "val.francesch@gmail.com"
                }
            }
        ]
    }])

    return (
        <div className="app">
            <Navbar />
            <div className="content">
                <div className='d-block d-lg-none' style={{ height: '70px' }}> </div>

                <Row className='toolbar m-0'>
                    <Col>
                        <span className={'navbar-icon'}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={PAGE_ICON}></FontAwesomeIcon></span>
                        <span className='ms-2'>{PAGE_TITLE}</span>
                    </Col>
                </Row>
                <div className='d-block d-lg-none mt-3'></div>
                <div className='d-none d-lg-block mt-4'></div>
                <Row className='mb-3 align-items-center ' style={{ rowGap: '8px' }}>
                    <Col className='fitContentWidth'>
                        <div className='fitContentWidth align-center-vertically'>
                            <FontAwesomeIcon icon={faChalkboardTeacher} className='dropDownIcon' style={{ height: 16 }} />
                            <Dropdown className='dropdownButton'>
                                <Dropdown.Toggle className=" dropdownFilter txt-left fs-14 wi-150">
                                    {selectedClassroomOption}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedClassroomOption}>
                                    <Dropdown.Item onClick={() => handleSelect(-1)}>Todos</Dropdown.Item>
                                    {classrooms.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelect(i)}>{opt.degree}º {opt.division}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Col>
                    <Col className='fitContentWidth'>
                        <div className='align-center-vertically '>
                            <FontAwesomeIcon icon={faFile} className='dropDownIcon' style={{ height: 16 }} />
                            <Dropdown className='dropdownButton'>
                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-150">
                                    {selectedStatusOption && selectedStatusOption.name}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedStatusOption && selectedStatusOption.name}>
                                    {status && status.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelectStatus(opt)}>{opt.name}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Col>
                    <div className='d-none d-lg-block fitContentWidth '>
                        <Col className=''>
                            <div className=' align-center-vertically'>
                                <Form.Control className='d-none d-lg-block textInputGray fs-14 wi-200' placeholder="Buscar estudiante..." id="textSearchDesktop" onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        getSanctions(selectedClassroomId);
                                    }
                                }} />
                                <ActionButton iconFa={faSearch} title='' classExtra='ms-2 d-none d-lg-block' func={() => getSanctions(selectedClassroomId)} />
                            </div>
                        </Col>
                    </div>
                    <div className='d-block d-lg-none'>
                        <Col xs={12}>
                            <div className=' align-center-vertically'>

                                <Form.Control className='d-block d-lg-none textInputGray fs-14' placeholder="Buscar estudiante..." id="textSearchMobile" onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        getSanctions(selectedClassroomId);
                                    }
                                }} />
                                <ActionButton iconFa={faSearch} title='' classExtra='ms-2 d-block d-lg-none' func={() => getSanctions(selectedClassroomId)} />
                            </div>
                        </Col>
                    </div>


                    <Col className='fitContentWidth' xs={12}>
                        <div className='fitContentWidth align-center-vertically'>
                            <FontAwesomeIcon height={30} icon={faUser} className='dropDownIcon' style={{ height: 16 }} />
                            <Form.Check type="switch" label="Agrupar por estudiante" onChange={(val) => handleCheckGroupSanctions(val)} defaultChecked={false} />
                        </div>
                    </Col>

                    <div className='d-none d-lg-block col'>
                        <Col className=' endRow'>
                            <div className='  fitContentWidth'>
                                <ActionButton iconFa={faPlus} title={'Nueva sanción'} func={handleShowNewSanction} />
                            </div>

                        </Col>
                    </div>

                    <Col className='d-block d-lg-none' md={12}>
                        <Button variant='primary' className='fs-14 totalWidth' onClick={handleShowNewSanction}><FontAwesomeIcon className='mr-10' icon={faPlus} />Nueva sanción</Button>
                    </Col>
                </Row>
                <Row>
                    <Card className='cards mobile-cards'>
                        {isTableLoaded ?
                            !groupSanctions ? (sanctions.length > 0 ? <>
                                <div className='d-none d-lg-block'>
                                    <Row className='p-2 mb-2' style={{ color: 'white', backgroundColor: '#3680F4', borderRadius: 8 }}>

                                        <Col md={1} className='align-center-vertically-v2 pe-0'>
                                            <div style={{ textAlign: 'left' }}>
                                                Fecha
                                            </div>
                                        </Col>
                                        <Col md={5} className='align-center-vertically-v2'>
                                            <div style={{ textAlign: 'left' }}>
                                                Sanción
                                            </div>
                                        </Col>
                                        <Col md={3} className='align-center-vertically-v2'>
                                            <div style={{ textAlign: 'center' }}>
                                                Estudiante / solicitante
                                            </div>
                                        </Col>
                                        <Col md={2} className='align-center-vertically-v2'>
                                            <div style={{ textAlign: 'center' }}>
                                                Estado
                                            </div>
                                        </Col>
                                        <Col md={1} className='align-center-vertically-v2'>

                                        </Col>
                                    </Row>
                                </div>
                                <div className='d-block d-lg-none'>
                                    <Row className='p-2 mb-2' style={{ color: 'white', backgroundColor: '#3680F4', borderRadius: 8 }}>
                                        <Col md={12} className='align-center-vertically-v2 pe-0'>
                                            <div style={{ textAlign: 'center' }}>
                                                Sanciones
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                {sanctions != null && sanctions.map((r, i) => (<>
                                    <div key={i}>
                                        <div className='d-none d-lg-block'>
                                            <Row className='mb-2 ps-2 pe-2 pt-1 pb-1' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                                <Col md={1} className='dateCol align-center-vertically-v2 pe-0'>
                                                    <div style={{ textAlign: 'left' }} className=' '>
                                                        {moment(r.date).format("DD/MM/YY")}
                                                    </div>
                                                </Col>
                                                <Col md={5} className='align-center-vertically-v2'>
                                                    <div style={{ textAlign: 'left' }}>
                                                        {r.SanctionType && <Badge bg='' className='me-2' style={{ fontWeight: 400, color: r.SanctionType.color ? getTextColorForBackground(r.sanctionType.color) : 'black', backgroundColor: r.SanctionType.color ? r.sanctionType.color : '#ffc107' }} >{`${r.amount} ${r.amount > 1 ? pluralize(r.SanctionType.name) : r.SanctionType.name}`}</Badge>}

                                                        {r.reasonId == null ? '' : r.SanctionReason.name}

                                                        {(r.content != null && r.content != "") ? `${r.SanctionReason != null ? ":" : ""} ${r.content}` : ''}
                                                    </div>
                                                </Col>
                                                <Col md={3} className='align-center-vertically-v2'>
                                                    <div id={`student-${r.id}`} style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }} className='mb-1'>
                                                        <Badge bg='secondary' style={{ fontWeight: 400, color: 'white' }} ><FontAwesomeIcon icon={faUser} className='me-1' style={{ fontSize: '11px' }} />{r.to && r.to.surname ? r.to.surname.toUpperCase() : ''}, {r.to && r.to.name}</Badge>
                                                        {r.to && selectedClassroomId == "null" && <Badge bg='secondary' style={{ fontWeight: 400, color: 'white' }} className='ms-1'>{r.degree && r.division && `${r.degree}° ${r.division}`}</Badge>
                                                        }
                                                    </div>
                                                    <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
                                                        <Badge bg='secondary' style={{ fontWeight: 400, color: 'white' }} ><FontAwesomeIcon icon={faChalkboardTeacher} className='me-1' style={{ fontSize: '11px' }} />{r.from && r.from.surname ? r.from.surname.toUpperCase() : ''}, {r.from && r.from.name}</Badge>

                                                    </div>

                                                </Col>

                                                <Col md={2} className='align-center-vertically-v2' >
                                                    <div>
                                                        <p className='m-0 ps-2' style={{ display: 'flex', justifyContent: 'center' }}><Badge onClick={() => handleShowApprovementDetailModal(r)} bg={r.approved ? 'success' : 'warning'} style={{ color: `${r.approved ? 'white' : 'black'}` }} className='statusBadge fs-14 wi-100 pointer'>{r.approved ? 'Aprobada' : 'Pendiente'}</Badge></p>
                                                    </div>
                                                </Col>

                                                <Col md={1} className='align-center-vertically-v2'>
                                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                        <Dropdown className='ms-2'>
                                                            <Dropdown.Toggle variant="secondary" id="dropdown-basic" className='dropdownToggle'>
                                                                <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item onClick={() => putApproveSanction(r.id, !r.approved)}><FontAwesomeIcon icon={faClipboardCheck} style={{ marginRight: 10, marginLeft: 2 }} />{r.approved ? "Desaprobar" : "Aprobar"}</Dropdown.Item>
                                                                {(r.approved && (myRole == 'Secretaría Secundaria' || myRole == 'Rectorado Secundaria' || myRole == 'Dirección Primaria' || myRole == 'Director Primaria')) || !r.approved ? <Dropdown.Item onClick={() => handleShowConfirmDeleteModal(r)}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item> : ''}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className='d-block d-lg-none'>
                                            <div className='mb-2 ps-3 pe-3 pt-2 pb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                                <Row className='ps-0'>

                                                    <div style={{ textAlign: 'left' }} className='fitContentWidth ps-0 pe-2'>
                                                        <span className='fs-11'>{moment(r.date).format("DD/MM/YYYY")}</span>
                                                    </div>
                                                    <div className='fitContentWidth ps-0 align-center-vertically pe-1'>
                                                        {r.SanctionType && <Badge bg='warning' style={{ fontWeight: 400, color: 'black' }} >{`${r.amount} ${r.amount > 1 ? pluralize(r.SanctionType.name) : r.SanctionType.name}`}</Badge>
                                                        }</div>
                                                    <div className='fitContentWidth ps-0 align-center-vertically pe-1'>
                                                        <Badge onClick={() => handleShowApprovementDetailModal(r)} style={{ fontWeight: 400, color: `${r.approved ? 'white' : 'black'}` }} bg={r.approved ? 'success' : 'warning'} className=' pointer'>{r.approved ? 'Aprobada' : 'Pendiente'}</Badge>
                                                    </div>

                                                </Row>
                                                <Row className='pt-2 pb-2 ps-0'>
                                                    <Col xs={10} className='align-center-vertically-v2 ps-0'>
                                                        <div style={{ textAlign: 'left' }}>
                                                            {r.SanctionReason != null ? r.SanctionReason.name && r.SanctionReason.name : ''}

                                                            {(r.content != null && r.content != "") ? `${r.SanctionReason != null ? ":" : ''} ${r.content}` : ''}
                                                        </div>
                                                    </Col>
                                                    <Col xs={2} className='align-center-vertically-v2 pe-0'>
                                                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                            <Dropdown className='ms-2'>
                                                                <Dropdown.Toggle variant="secondary" id="dropdown-basic" className='dropdownToggle'>
                                                                    <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item onClick={() => putApproveSanction(r.id, !r.approved)}><FontAwesomeIcon icon={faClipboardCheck} style={{ marginRight: 10, marginLeft: 2 }} />{r.approved ? "Desaprobar" : "Aprobar"}</Dropdown.Item>
                                                                    <Dropdown.Item onClick={() => handleShowConfirmDeleteModal(r)}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className='ps-0'>
                                                    <Col md={12} className='align-center-vertically-v2 ps-0'>
                                                        <div id={`student-${r.id}`} style={{ textAlign: 'center', display: 'flex', columnGap: '3px', rowGap: '5px', flexWrap: 'wrap' }} className='mb-1'>
                                                            {r.to && <Badge bg='secondary' style={{ fontWeight: 400, color: 'white' }} ><FontAwesomeIcon icon={faUser} className='' style={{ fontSize: '11px' }} />{formatNameV2(r.to.name, r.to.surname)}</Badge>}
                                                            {r.to && selectedClassroomId == "null" && <Badge bg='secondary' style={{ fontWeight: 400, color: 'white' }} className=''>{r.degree && r.division && `${r.degree}° ${r.division}`}</Badge>}
                                                            {r.from && <Badge bg='secondary' style={{ fontWeight: 400, color: 'white' }} ><FontAwesomeIcon icon={faChalkboardTeacher} className='' style={{ fontSize: '11px' }} />{formatNameV2(r.from.name, r.from.surname)}</Badge>
                                                            }</div>
                                                        <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>


                                                        </div>

                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </div>

                                </>))}
                            </> : <div className='justify-content-center'>
                                <NotFound classExtra="loading-100" iconFa={faFileCircleExclamation} text='No se han encontrado sanciones' />
                            </div>)
                                : groupSanctions &&
                                    sanctions != null && sanctions.length > 0 ? sanctions.map((s, i) => (
                                        <Accordion key={i} alwaysOpen={true} activeItemKey={i}>
                                            <Accordion.Item eventKey={i} className='mb-2' style={{ border: '1px solid rgb(240, 240, 240)', borderRadius: '8px' }}>
                                                <Accordion.Header className=''>
                                                    <Row style={{ width: '100%' }}>
                                                        <Col md={9} className='ps-0 pe-0' xs={11}>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <div className='fitContentWidth'>
                                                                    <div className='studentImg circle-container toolBarIcon' style={{ borderColor: 'white', background: 'white' }}>
                                                                        {s.avatarFile ? <img className='' src={s.avatarFile} alt={s.name} /> : <Loading animation={'grow'} bsSize='sm' />}
                                                                    </div>
                                                                </div>
                                                                <p className='m-0'>{s.surname && s.surname.toUpperCase()}, {s.name} <Badge bg='secondary' style={{ fontWeight: 400, color: 'white' }} className='ms-1'>{s.degree && s.division && `${s.degree}° ${s.division}`}</Badge></p>


                                                            </div>
                                                        </Col>
                                                        <Col md={3} className='endRow pe-0' xs={1}>
                                                            <div className=' d-none d-lg-block endRow me-2 align-center-vertically-v2 pe-3' >
                                                                {s.sanctions && s.sanctions.length} {s.sanctions && s.sanctions.length > 1 ? ' sanciones' : ' sanción'}
                                                            </div>
                                                            <div className='d-block d-lg-none endRow me-2 align-center-vertically-v2 pe-0' >
                                                                {s.sanctions && s.sanctions.length}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Accordion.Header>
                                                <Accordion.Body className='p-3 pb-2'>
                                                    {s.sanctions && s.sanctions.map((r, j) => (
                                                        <div key={j}>
                                                            <div className='d-none d-lg-block'>
                                                                <Row className='mb-2 ps-2 pe-2 pt-1 pb-1' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                                                    <Col md={1} className='dateCol align-center-vertically-v2 pe-0'>
                                                                        <div style={{ textAlign: 'left' }} className=' '>
                                                                            {moment(r.date).format("DD/MM/YY")}
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={5} className='align-center-vertically-v2'>
                                                                        <div style={{ textAlign: 'left' }}>
                                                                            {r.SanctionType && <Badge bg='warning' className='me-2' style={{ fontWeight: 400, color: 'black' }} >{`${r.amount} ${r.amount > 1 ? pluralize(r.SanctionType.name) : r.SanctionType.name}`}</Badge>}

                                                                            {r.SanctionReason != null ? r.SanctionReason.name && r.SanctionReason.name : ''}

                                                                            {(r.content != null && r.content != "") ? `${r.SanctionReason != null && ":"} ${r.content}` : ''}
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={3} className='align-center-vertically-v2'>
                                                                        <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
                                                                            <Badge bg='secondary' style={{ fontWeight: 400, color: 'white' }} ><FontAwesomeIcon icon={faChalkboardTeacher} className='me-1' style={{ fontSize: '11px' }} />{r.from && r.from.surname ? r.from.surname.toUpperCase() : ''}, {r.from && r.from.name}</Badge>
                                                                        </div>
                                                                    </Col>

                                                                    <Col md={2} className='align-center-vertically-v2' >
                                                                        <div>
                                                                            <p className='m-0 ps-2' style={{ display: 'flex', justifyContent: 'center' }}><Badge onClick={() => handleShowApprovementDetailModal(r)} bg={r.approved ? 'success' : 'warning'} style={{ color: `${r.approved ? 'white' : 'black'}` }} className='statusBadge fs-14 wi-100 pointer'>{r.approved ? 'Aprobada' : 'Pendiente'}</Badge></p>
                                                                        </div>
                                                                    </Col>

                                                                    <Col md={1} className='align-center-vertically-v2'>
                                                                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                            <Dropdown className='ms-2'>
                                                                                <Dropdown.Toggle variant="secondary" id="dropdown-basic" className='dropdownToggle'>
                                                                                    <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                                                                </Dropdown.Toggle>
                                                                                <Dropdown.Menu>
                                                                                    <Dropdown.Item onClick={() => putApproveSanction(r.id, !r.approved)}><FontAwesomeIcon icon={faClipboardCheck} style={{ marginRight: 10, marginLeft: 2 }} />{r.approved ? "Desaprobar" : "Aprobar"}</Dropdown.Item><Dropdown.Item onClick={() => handleShowConfirmDeleteModal(r)}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                                                                                </Dropdown.Menu>
                                                                            </Dropdown>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                            <div className='d-block d-lg-none'>
                                                                <div className='mb-2 ps-3 pe-3 pt-2 pb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                                                    <Row className='ps-0'>

                                                                        <div style={{ textAlign: 'left' }} className='fitContentWidth ps-0'>
                                                                            <span className='fs-11'>{moment(r.date).format("DD/MM/YYYY")}</span>
                                                                        </div>
                                                                        <div className='fitContentWidth ps-0 align-center-vertically'>
                                                                            {r.SanctionType && <Badge bg='warning' style={{ fontWeight: 400, color: 'black' }} >{`${r.amount} ${r.amount > 1 ? pluralize(r.SanctionType.name) : r.SanctionType.name}`}</Badge>
                                                                            }</div>
                                                                        <div className='fitContentWidth ps-0 align-center-vertically'>
                                                                            <Badge onClick={() => handleShowApprovementDetailModal(r)} style={{ fontWeight: 400, color: `${r.approved ? 'white' : 'black'}` }} bg={r.approved ? 'success' : 'warning'} className=' pointer'>{r.approved ? 'Aprobada' : 'Pendiente'}</Badge>
                                                                        </div>

                                                                    </Row>
                                                                    <Row className='pt-2 pb-2 ps-0'>
                                                                        <Col xs={10} className='align-center-vertically-v2 ps-0'>
                                                                            <div style={{ textAlign: 'left' }}>
                                                                                {r.SanctionReason != null ? r.SanctionReason.name && r.SanctionReason.name : ''}

                                                                                {(r.content != null && r.content != "") ? `${r.SanctionReason != null && ":"} ${r.content}` : ''}
                                                                            </div>
                                                                        </Col>
                                                                        <Col xs={2} className='align-center-vertically-v2 pe-0'>
                                                                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                                <Dropdown className='ms-2'>
                                                                                    <Dropdown.Toggle variant="secondary" id="dropdown-basic" className='dropdownToggle'>
                                                                                        <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                                                                    </Dropdown.Toggle>
                                                                                    <Dropdown.Menu>
                                                                                        <Dropdown.Item onClick={() => putApproveSanction(r.id, !r.approved)}><FontAwesomeIcon icon={faClipboardCheck} style={{ marginRight: 10, marginLeft: 2 }} />{r.approved ? "Desaprobar" : "Aprobar"}</Dropdown.Item>
                                                                                        <Dropdown.Item onClick={() => handleShowConfirmDeleteModal(r)}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                                                                                    </Dropdown.Menu>
                                                                                </Dropdown>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className='ps-0'>
                                                                        <Col md={12} className='align-center-vertically-v2 ps-0'>
                                                                            <div id={`student-${r.id}`} style={{ textAlign: 'center', display: 'flex', columnGap: '5px', rowGap: '5px', flexWrap: 'wrap' }} className='mb-1'>
                                                                                {r.to && <Badge bg='secondary' style={{ fontWeight: 400, color: 'white' }} ><FontAwesomeIcon icon={faUser} className='me-1' style={{ fontSize: '11px' }} />{formatNameV2(r.to.name, r.to.surname)}</Badge>}
                                                                                {r.to && selectedClassroomId == "null" && <Badge bg='secondary' style={{ fontWeight: 400, color: 'white' }} className='ms-1'>{r.degree && r.division && `${r.degree}° ${r.division}`}</Badge>}
                                                                                {r.from && <Badge bg='secondary' style={{ fontWeight: 400, color: 'white' }} ><FontAwesomeIcon icon={faChalkboardTeacher} className='me-1' style={{ fontSize: '11px' }} />{formatNameV2(r.from.name, r.from.surname)}</Badge>
                                                                                }</div>
                                                                            <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>


                                                                            </div>

                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    )) :
                                    <div className='justify-content-center'>
                                        <NotFound classExtra="loading-100" iconFa={faFileCircleExclamation} text='No se han encontrado sanciones' />
                                    </div>
                            : <div className='justify-content-center'>
                                <Loading animation={'border'} classExtra='loading-100' />
                            </div>}
                    </Card>
                </Row>
            </div>

            <Modal size={'lg'} show={showNewSanction} onClick={handleOutsideClick} onHide={handleCloseModalNewSanction} centered backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={40} className='dropModalCont dropDownIcon' icon={faExclamationCircle} style={{ minWidht: '20px' }} /><Modal.Title>{isEditingSanction ? 'Editar sanción' : 'Nueva sanción'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isEditingSanction &&
                        <InfoField title='Estudiante' content={`${sanctionToEdit.to && sanctionToEdit.to.surname ? sanctionToEdit.to.surname.toUpperCase() : ''}, ${sanctionToEdit.to && sanctionToEdit.to.name}`} extraClass='mb-3' />
                    }
                    <Row className='mb-2' style={{ rowGap: '8px' }}>

                        {!isEditingSanction && <>
                            <Col md={12} className='ps-0' >
                                <div className='align-center-vertically w-100'>
                                    <FontAwesomeIcon icon={faCalendar} className='dropDownIcon' style={{ height: 16 }} />
                                    <ReactDatePicker
                                        selected={selectedDateDay}
                                        onChange={handleChangeDate}
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText="Fecha"
                                        className='dropdown-toggle'
                                        locale='es' maxDate={moment().toDate()} />
                                </div>
                            </Col>
                        </>}

                        <Col md={12} style={{ display: 'flex', flexWrap: 'wrap', rowGap: '8px' }} id="searchModalSection" className='ps-0 mt-2'>
                            <SearchBar searchPlaceholder="Buscar estudiante..."
                                roles={["Alumno"]}
                                selectedContacts={selectedContacts1}
                                setSelectedContacts={setSelectedContacts1}
                                faIcon={faUser}
                                id="1"
                            />
                        </Col>

                        <Col md={12} style={{ display: 'flex', flexWrap: 'wrap', rowGap: '8px' }} id="searchModalSection" className='ps-0 '>
                            <SearchBar searchPlaceholder="Buscar solicitante..."
                                roles={["Preceptor Secundaria", "Rectorado Secundaria", "Secretaría Secundaria", "Docente Secundaria"]}
                                selectedContacts={selectedContacts2}
                                setSelectedContacts={setSelectedContacts2}
                                faIcon={faChalkboardTeacher}
                                id="2"
                            />
                        </Col>

                        <Col className='fitContentWidth ps-0'>
                            <div className='align-center-vertically'>
                                <FontAwesomeIcon icon={faBookOpen} className='dropDownIcon' style={{ height: 16 }} />
                                <Dropdown className='dropdownButton'>
                                    <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-200">
                                        {selectedSanctionTypeOption != null && selectedSanctionTypeOption.name ? selectedSanctionTypeOption.name : 'Tipo'}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="fs-14" title={selectedSanctionTypeOption != null && selectedSanctionTypeOption.name ? selectedSanctionTypeOption.name : 'Tipo'}>
                                        {optionsSanctionType.map((opt, i) => (
                                            <Dropdown.Item key={i} onClick={() => handleSelectSanctionType(i)}>{opt.name}</Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Col>

                        <Col className='fitContentWidth ps-0'>
                            <div className='align-center-vertically'>
                                <div className='d-block d-lg-none dropDownIcon' ></div>
                                <Dropdown className='dropdownButton'>
                                    <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-100">
                                        {selectedAmountOption}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="fs-14" title={selectedAmountOption}>
                                        {optionsAmount.map((opt, i) => (
                                            <Dropdown.Item key={i} onClick={() => handleAmount(i)}>{opt}</Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Col>

                        <Col md={12} className='ps-0 '>
                            <div className='align-center-vertically'>
                                <div className='dropDownIcon'>
                                    <FontAwesomeIcon icon={faList} className='dropDownIcon' style={{ height: 16 }} />

                                </div>
                                <Dropdown className='d-none d-lg-block dropdownButton'>

                                    <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-400">
                                        {selectedSanctionReason.name}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className=" fs-14 wi-400" title={selectedSanctionReason.name}>
                                        {sanctionReasons.map((opt, i) => (
                                            <Dropdown.Item className='' style={{ whiteSpace: 'break-spaces' }} key={i} onClick={() => handleSanctionReason(i)}>{opt.name}</Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Dropdown className='d-block d-lg-none dropdownButton'>

                                    <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-300">
                                        {selectedSanctionReason.name}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className=" fs-14 wi-300" title={selectedSanctionReason.name}>
                                        {sanctionReasons.map((opt, i) => (
                                            <Dropdown.Item className='' style={{ whiteSpace: 'break-spaces' }} key={i} onClick={() => handleSanctionReason(i)}>{opt.name}</Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Col>

                        <Col className='mt-1 mb-2 ps-0 pe-0' md={12}>
                            <div className='align-center-vertically'>
                                <div className='dropDownIcon' ></div>
                                <Form.Control as='textarea' className='textInputGray fs-14' id="sanctionDescription" placeholder="Descripción" value={newSanctionDescription} onChange={handleNewSanctionDescription} style={{ maxWidth: '1100px', height: '100px' }} />
                            </div>
                        </Col>
                    </Row>

                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14' onClick={handleCloseModalNewSanction}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                    <Button variant="primary" className='fs-14' onClick={handleSubmitSanction} disabled={selectedSanctionTypeOption == null || selectedAmountOption == 'Cantidad' || selectedContacts1.length == 0 || selectedContacts2.length == 0 || selectedDateDay == null || isPostReady !== -1 || selectedSanctionReason.name == 'Motivo'}>
                        {isPostReady == -1 ? <><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</> :
                            <div className="justify-content-center" style={{ width: '85px' }}>
                                <Loading animation={'grow'} size='20px' bsSize='sm' variant='light' classExtra='justify-content-center' />
                            </div>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal show={showConfirmDeleteModal} onHide={handleCloseConfirmDeleteModal} centered backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropModalCont dropDownIcon' icon={faTrash} style={{ minWidht: '20px' }} /><Modal.Title>Eliminar</Modal.Title>
                </Modal.Header>
                <Modal.Body className='pt-4 pb-4'>
                    <span className=''>¿Deseas eliminar {deletesanctionSelected != null && `${deletesanctionSelected.amount} `}
                        {deletesanctionSelected != null && deletesanctionSelected.amount > 1 ? (optionsSanctionType.length > 0 && deletesanctionSelected != null && pluralize(deletesanctionSelected.SanctionType.name)) : (deletesanctionSelected != null && deletesanctionSelected.SanctionType && deletesanctionSelected.SanctionType.name)}
                        {` para `}{deletesanctionSelected != null && `${deletesanctionSelected.to && deletesanctionSelected.to.surname ? deletesanctionSelected.to.surname.toUpperCase() : ''}, ${deletesanctionSelected.to && deletesanctionSelected.to.name}`}?</span>
                </Modal.Body>
                <Modal.Footer className='p-2 mt-2'>
                    <Button variant="outline-secondary" className='fs-14 me-2' onClick={handleCloseConfirmDeleteModal}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                    <Button variant="danger" className='fs-14 me-2' onClick={() => deletesanction(deletesanctionId)}><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showConfirmChangeStateModal} onHide={handleCloseConfirmChangeStateModal} centered backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropModalCont dropDownIcon' icon={faClipboardCheck} style={{ minWidht: '20px' }} /><Modal.Title>Aprobar / desaprobar</Modal.Title>
                </Modal.Header>
                <Modal.Body className='pt-4 pb-4'>
                    <div className='align-center-vertically'>
                        <FontAwesomeIcon icon={faClipboardCheck} className='dropDownIcon' style={{ height: 16 }} />
                        <Dropdown className='dropdownButton'>
                            <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-200">
                                {selectedStateSanction.name}
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="fs-14" title={selectedStateSanction.name}>
                                {statesOptions.map((opt, i) => (
                                    <Dropdown.Item key={i} onClick={() => handleStateSanction(i)}>{opt.name}</Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </Modal.Body>
                <Modal.Footer className='p-2 mt-2'>
                    <Button variant="outline-secondary" className='fs-14 me-2' onClick={handleCloseConfirmChangeStateModal}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                    <Button variant="primary" className='fs-14 me-2' onClick={() => putApproveSanction()} disabled={isPostReady != -1}>{isPostReady == -1 ? <><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</> : <div className="justify-content-center" style={{ width: '85px' }}>
                        <Loading animation={'grow'} size='20px' bsSize='sm' variant='light' classExtra='justify-content-center' />
                    </div>}</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showApprovementDetailModal} onHide={handleCloseApprovementDetailModal} centered backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropModalCont dropDownIcon' icon={faFileCircleExclamation} style={{ minWidht: '20px' }} /><Modal.Title>Detalle de la sanción</Modal.Title>
                </Modal.Header>
                <Modal.Body className='pt-4'>
                    {viewSanction && <><InfoField title='Estado' content={viewSanction.approved ? 'Aprobada' : 'Pendiente'} extraClass='mb-2' />
                        {viewSanction.approved ? viewSanction.ApprovedBy != null && <InfoField title='Por' content={`${viewSanction.ApprovedBy.surname ? viewSanction.ApprovedBy.surname.toUpperCase() : ''}, ${viewSanction.ApprovedBy && viewSanction.ApprovedBy.name}`} />
                            : viewSanction.DisapprovedBy != null && <InfoField title='Modif. por' content={`${viewSanction.DisapprovedBy.surname ? viewSanction.DisapprovedBy.surname.toUpperCase() : ''}, ${viewSanction.DisapprovedBy && viewSanction.DisapprovedBy.name}`} />}
                    </>}
                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14 me-2' onClick={handleCloseApprovementDetailModal}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cerrar</Button>
                </Modal.Footer>
            </Modal>
        </div>

    )
}

export default Sanctions