import React, { useEffect, useState } from 'react'
import { Button, Col, Dropdown, Form, Modal, Row } from 'react-bootstrap';
import { faCalendar, faCheck, faPlus, faClock, faFile, faBell, faTimes, faChalkboardTeacher } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ActionButton from '../../general-components/ActionButton';


function EventModal({ handleClose, show }) {
    const [eventCreation, setEventCreation] = useState(false)

    let [buttonStyleEv, setButtonStyleEv] = useState('');
    let [buttonStyleTa, setButtonStyleTa] = useState('blueTasky-back whiteText ');
    const handleEventCreation = () => {
        setEventCreation(true);
        setButtonStyleEv('blueTasky-back whiteText ')
        setButtonStyleTa('')
    }
    const handleTaskCreation = () => {
        setEventCreation(false);
        setButtonStyleEv('')
        setButtonStyleTa('blueTasky-back whiteText ')
    }

    const minutosUnidad = {
        day: 1440,
        week: 10080,
        hour: 60,
        minute: 1,
    }

    const [formData, setFormData] = useState({ reminder: [], });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleChangeReminder = (e) => {
        const { value } = e.target;
        //Conseguir qué es el valor del dropdown
        //Multiplicar por minutos
        //guardar en un indice único?
        /* 
                const nuevoObjeto = { ...formData };
                nuevoObjeto.reminder.push(value); */
    };

    const reminder = ['minutos', 'horas', 'días', 'semanas']
    const [selectedReminderOption, setSelectedReminderOption] = useState('minutos');

    const handleSelectReminder = (eventKey) => {
        setSelectedReminderOption(reminder[eventKey]);
    };

    const [reminderList, setReminderList] = useState([])

    const newReminder = () => {
      const newId = Date.now(); // id único
      const newSubSubjectItem = {
        id: newId,
        component: (
            <div className='align-center-vertically mb-2'>
            <Form.Control className='textInputGray fs-14 me-2' type="number" style={{ width: '50px' }} id="" placeholder="0" onChange={handleChangeReminder} />
            <Dropdown className='dropdownButton'>
                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-200">
                    {selectedReminderOption}
                </Dropdown.Toggle>
                <Dropdown.Menu className="fs-14" title={selectedReminderOption}>
                    {reminder.map((opt, i) => (
                        <Dropdown.Item key={i} onClick={() => handleSelectReminder(i)}>{opt}</Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
            <FontAwesomeIcon onClick={() => removeReminder(newId)} icon={faTimes} className='remiderCross ms-2' onCl />
        </div>
        )
      };
      setReminderList(prevList => [...prevList, newSubSubjectItem]);
    };

    const removeReminder = (idToRemove) => {
        setReminderList(prevList => prevList.filter(item => item.id !== idToRemove));
      };

    const handleCloseModal = () => {
        setFormData({})
        setReminderList([])
        handleClose();
    }

    const handleSubmit = () => {
        //guardar formData
        //console.log(formData)
        handleCloseModal();
    };


    return (
        <Modal size='lg' show={show} onHide={handleCloseModal} centered className='modalContainer' backdrop="static" >
            <Modal.Header closeButton>
                <FontAwesomeIcon height={35} className='toolBarIcon ' icon={faCalendar} /><Modal.Title>Nuevo Evento</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='formContainer'>
                    <Row>
                        <Col className='iconModalCol'>
                        </Col>
                        <Col>
                            {/*                     <input type='text' name='titulo' className="campo-input fontSize13" placeholder='Título'  onChange={handleChange}/> */}
                            <div className='align-center-vertically mb-2'>
                                <Form.Control className='textInputGray fs-14 wi-300' id="" placeholder="Título" onChange={handleChange} />
                            </div>
                            <div className='calButtonCont'>
                                <button className={buttonStyleTa + ' calCreateButton'} onClick={handleTaskCreation}>Tarea</button>
                                <span><button className={buttonStyleEv + ' calCreateButton'} onClick={handleEventCreation}>Evento</button></span>
                            </div>
                        </Col>
                    </Row>
                    {eventCreation ?
                        <Row>
                            <Col className='iconModalCol'>
                                <FontAwesomeIcon icon={faClock} />
                            </Col>
                            <Col>
                                <div className='align-center-vertically'>
                                    <input type="datetime-local" name='fecha' className="campo-input fs-14" placeholder='Fecha' onChange={handleChange} /> <p style={{ margin: '0px 10px' }}>al</p> <input type="datetime-local" name='fecha-final' className="campo-input" placeholder='Fecha' onChange={handleChange} />
                                </div>
                            </Col>
                        </Row>
                        : <>
                            <Row>
                                <Col className='iconModalCol'>
                                    <FontAwesomeIcon icon={faClock} />
                                </Col>
                                <Col>
                                    <input type='datetime-local' name='fecha' className="campo-input fs-14" placeholder='Fecha' onChange={handleChange} />
                                </Col>
                            </Row>
                            <Row>
                                <Col className='iconModalCol'>
                                    <FontAwesomeIcon icon={faFile} />
                                </Col>
                                <Col>
                                    <textarea type='textarea' name='descripcion' className="campo-input fs-14" placeholder='Descripción' style={{ height: '120px' }} onChange={handleChange}></textarea>
                                </Col>
                            </Row>
                        </>
                    }
                    <Row>
                        <Col className='iconModaColSpecial iconModalCol'>
                            <FontAwesomeIcon icon={faBell} />
                        </Col>
                        <Col className='fitContentWidth'>
                            <div className=' '>
                                {reminderList.map(button => button.component)}
                            </div>
                            <ActionButton iconFa={faPlus} title='Aregar notificación' func={newReminder} />
                        </Col>
                    </Row>
                    <Row>
                        <Col className='iconModalCol'>
                        </Col>
                        <Col>

                        </Col>
                    </Row>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-secondary" className='fs-14' onClick={handleCloseModal}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                <Button variant="primary" className='fs-14'><FontAwesomeIcon className='mr-10' icon={faCheck} onClick={handleSubmit} />Confirmar</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default EventModal