import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
import { Row, Col } from 'react-bootstrap'

function InfoField({ title = '', content = '', extraClass = '' }) {

    return (
        <Row className={`entireRow ps-0 pe-0 ${extraClass}`}>
            <Col md={3} className='pt-2 pb-2 ps-4 pe-4 align-center-vertically' style={{ background: "#3680F4", color: "white", borderRadius: '8px' }}>
                {title}
            </Col>
            <Col className='pt-2 pb-2 ps-4'>
                {content}
            </Col>
        </Row>
    )
}

export default InfoField