import React, { useEffect } from 'react'
import { Row } from "react-bootstrap";
import { POST } from '../../../controllers/endpoints.controller';
import { getChilds, saveUserData, validateUser } from '../../../controllers/user.controller';
import { UAParser } from 'ua-parser-js';
import { v4 as uuidv4 } from 'uuid';

export default function GoogleAuth() {

    useEffect(() => {
        document.title = `Tasky`;
        getGoogleUserInfo();
    }, []);

    const getParam = function () {
        const params = new URLSearchParams(window.location.search);
        if (params.has('code')) {
            return (window.location.search);
        } else {
            return -1;
        }
    }

    const getGoogleUserInfo = async function () {
        const parser = new UAParser();
        const result = parser.getResult();
        const uuid = localStorage.getItem('uuId')  != null?localStorage.getItem('uuId') :  uuidv4() ;
        const body = {
            code:getParam(),
            device: {
                uuid: uuid,
                brand: result.browser.name,
                osName: result.os.name,
                osVersion: result.os.version,
                model: result.browser.version,
                appVersion: "",
            }
        }
        localStorage.setItem('uuId', body.device.uuid);
        const req = await POST(`/sso/login/google/callback/`, '', body);
        const res = await req.json();

        if (req.status === 200) {
            saveUserData(res);
            getChilds();
        } else {
            window.location.href = "/no-habilitado";
        }
    }

    return (
        <div>
            <Row>
                Cargando...
            </Row>
        </div>
    );
}