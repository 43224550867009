import React from 'react'
import { isPlural } from '../../tools/tools'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row } from 'react-bootstrap';

function NotFound({iconFa, text, classExtra, size = 32}) {

    return (
        <div className={`notFoundContainer ${classExtra}`} style={{minHeight: size}}>
            <Row className='mb-3 mt-2 justify-content-center'>
                <FontAwesomeIcon icon={iconFa} className='notFoundIcon' style={{textAlign:'center'}}/>
            </Row>
            <Row>
               <p className='mb-0' style={{textAlign:'center'}}>{text}</p>             
            </Row>
        </div>
    )
}

export default NotFound