import React, { useEffect, useRef, useState } from 'react'
import Navbar from '../../navbar/Navbar'
import { Badge, Button, Card, Col, Collapse, Dropdown, Form, Modal, Row, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faBook, faBookOpen, faCalendar, faChalkboardTeacher, faCheck, faClipboardCheck, faClipboardUser, faCommentAlt, faDownload, faEllipsisV, faEnvelope, faExclamationCircle, faExclamationTriangle, faEye, faF, faFile, faFileAlt, faFileArchive, faFileCircleExclamation, faIdCard, faMailBulk, faMapMarkerAlt, faMinus, faPaperclip, faPen, faPhone, faPlus, faTimes, faTrash, faUser, faUsers } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { faDochub, faTelegram } from '@fortawesome/free-brands-svg-icons';
import ActionButton from '../../general-components/ActionButton';
import { getFamilyMemberId, getFamilyMemberToken, getUserId, getUserRole, getUserToken, logout, validateToken } from '../../../controllers/user.controller';
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { DEL, GET, POST, PUT } from '../../../controllers/endpoints.controller';
import { calculateDynamicCropSize, convert2Base64, downloadFile, fileToBase64, formatNameV2, getCroppedImg, pluralize } from '../../../tools/tools';
import PDFViewer from 'mgr-pdf-viewer-react';
import Cropper from 'react-easy-crop';
import Loading from '../../general-components/Loading';
import moment from 'moment';
import NotFound from '../../general-components/NotFound';
import ReactQuill from 'react-quill';
import DatePicker from 'react-datepicker';

function StudentSite() {
    const PAGE_TITLE = "Estudiante";
    const PAGE_ICON = faUser;
    const PREVIOUS_PAGE = "Cursos";
    const PREVIOUS_URL = "/cursos"

    const location = useLocation();


    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        document.title = `Tasky - ${PAGE_TITLE}`;
        validateToken();
        moment.locale('es');
        setStudentId(getParams())
        getStudentDataFiles(getParams())
        getStudent(getParams())
        getStudentInfo(getParams());
        getSanctionsByUserId(getParams())
        getDataTypesStudents();
        getSanctionsStadisticsByUserId(getParams());
        getAssistance(getParams(), (moment().month() + 1))

        /* if (rolesCanSeePsychopedagogic.find(r => r == myRole)) {
            getStudentPsychopedagogic(getParams());
            getPsychoTags()
        } */
    }, []);

    const [myRole, setMyRole] = useState(getUserRole());
    const [userToken, setUserToken] = useState(myRole.includes("Familia") || myRole.includes("Padre-Madre-Tutor") ? getFamilyMemberToken() : getUserToken());

    const [showViewFileModal, setShowViewFileModal] = React.useState(false);
    const [actualViewFile, setActualViewFile] = React.useState(null);
    const [actualViewFileName, setActualViewFileName] = React.useState("");
    const [actualViewFileExtension, setActualViewFileExtension] = React.useState(null);
    const handleCloseViewFileModal = () => {
        setShowViewFileModal(false);
        setActualViewFile(null);
    };
    const handleShowViewFileModal = () => {
        setShowViewFileModal(true);
    };

    const [showChangeAvatarModal, setShowChangeAvatarModal] = React.useState(false);
    const [actualChangeAvatar, setActualChangeAvatar] = React.useState(null);
    const handleCloseChangeAvatarModal = () => {
        setShowChangeAvatarModal(false);
        setActualChangeAvatar(null);
    };
    const handleShowChangeAvatarModal = () => {
        setShowChangeAvatarModal(true);
    };

    //Controlador padding cards con scroll
    const interCardsRef = useRef(null);
    useEffect(() => {
        if (interCardsRef.current) {
            const container = interCardsRef.current;
            if (container.scrollHeight > container.clientHeight) {
                container.style.padding = '0px 10px 0px 10px'
            } else {
                container.style.padding = '0px 5px 0px 10px'
            }
        }
    }, [interCardsRef])

    const interCardsRefSanctions = useRef(null);
    useEffect(() => {
        if (interCardsRef.current) {
            const container = interCardsRefSanctions.current;
            if (container.scrollHeight > container.clientHeight) {
                container.style.padding = '0px 5px 0px 5px'
            } else {
                container.style.padding = '0px 10px 0px 10px'
            }
        }
    }, [interCardsRefSanctions])

    const interCardsRefData = useRef(null);
    useEffect(() => {
        if (interCardsRef.current) {
            const container = interCardsRefData.current;
            if (container.scrollHeight > container.clientHeight) {
                container.style.padding = '0px 5px 0px 10px'
            } else {
                container.style.padding = '0px 10px 0px 10px'
            }
        }
    }, [interCardsRefData])

    //Modal
    const [showModalStudentData, setShowModalStudentData] = useState(false);

    const handleShowModalStudentData = () => {
        setSelectedTypeOption({ name: 'Seleccione el tipo' });
        setinputStudentList([]);
        addInputStudent();
        setShowModalStudentData(true);
    };

    const handleCloseModalStudentData = () => {
        setShowModalStudentData(false);
    }

    const [showModalNewFile, setShowModalNewFile] = useState(false);
    const handleShowNewFileModal = () => {
        setShowModalNewFile(true);
    };
    const handleCloseNewFileModal = () => {
        setShowModalNewFile(false);
    }

    const [optionsType, setOptionsType] = useState([]);

    const [selectedTypeOption, setSelectedTypeOption] = useState({});
    const handleSelectType = (eventKey, id) => {
        setSelectedTypeOption((prevState) => ({
            ...prevState,
            [id]: optionsType[eventKey],
        }));
        console.log(optionsType[eventKey])
    };

    const [dataStudent, setDataStudent] = useState([])

    const [sanctions, setSanctions] = useState(null);
    const studentAsisstance = [];

    const [inputStudentList, setinputStudentList] = useState([]);

    const addInputStudent = () => {
        const newId = Date.now(); // id único
        setinputStudentList(prevList => [...prevList, newId]);
    }

    const NewinputStudent = ({ newId }) => {
        return (
            <Col md={12} className=' mb-2'>
                <div className='mb-2'>
                    <div className='align-center-vertically '>
                        <FontAwesomeIcon icon={faFile} className='dropDownIcon' style={{ height: 16 }} />

                        <Dropdown className='dropdownButton'>
                            <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-300">
                                {selectedTypeOption[newId] != null ? selectedTypeOption[newId].name : 'Seleccione el tipo'}
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="fs-14" title={selectedTypeOption[newId] ? selectedTypeOption[newId].name : 'Seleccione el tipo'}>
                                {optionsType.map((opt, i) => (
                                    <Dropdown.Item key={i} onClick={() => handleSelectType(i, newId)}>{opt.name}</Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
                <div className='align-center-vertically fitContentWidth'>
                    <FontAwesomeIcon icon={faFileAlt} className='dropDownIcon' style={{ height: 16 }} />
                    <Form.Control className='textInputGray fs-14 wi-300' id={`${newId}-inputModal`} placeholder="Dato" />
                </div>
                {/* <FontAwesomeIcon icon={faTimes} className='remiderCross ms-2' style={{ cursor: 'pointer' }} onClick={() => removeinputStudent(newId)} /> */}
            </Col>
        )
    };

    const removeinputStudent = (idToRemove) => {
        if (inputStudentList.length == 1) {
            addInputStudent();
        }
        setinputStudentList(prevList => prevList.filter(item => item !== idToRemove));
    };

    const [studentId, setStudentId] = useState(null);
    const [dataFiles, setDataFiles] = useState([]);
    const [student, setStudent] = useState({});

    const [avatarCrop, setAvatarCrop] = useState({ x: 0, y: 0 });
    const [avatarZoom, setAvatarZoom] = useState(1);
    const [avatarFileSrc, setAvatarFileSrc] = useState(null);

    const [avatarCropped, setAvatarCropped] = useState({});

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        setAvatarCropped(croppedAreaPixels)
    }

    const getParams = function () {
        const params = new URLSearchParams(window.location.search);
        if (params.has('id')) {
            return params.get('id');
        } else {
            if(myRole.includes("Familia") || myRole.includes("Padre-Madre-Tutor")){
                return getFamilyMemberId()
            }else{
                return getUserId()
            }
           
        }
    }

    const onAvatarFileChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.onload = () => {
                setAvatarFileSrc(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const getStudentDataFiles = async function (userId) {
        const req = await GET(`/students/files/byUserId/${userId}`, userToken);
        const res = await req.json();

        console.log("Files")
        if (req.status === 200) {
            console.log(res)
            setDataFiles(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getStudent = async function (userId) {
        const req = await GET(`/sso/users/byId/${userId}`, userToken);
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setStudent(res)
            document.title = `Tasky - ${res.name} ${res.surname}`;
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const viewFile = async (fileId, name) => {
        handleShowViewFileModal();

        const req = await GET(`/files/byIdNoHeader/${fileId}`, userToken);
        const res = await req.json();

        if (req.status === 200) {
            console.log(res);
            if (res.extension.includes("pdf") || res.extension.includes("png") || res.extension.includes("jpg") || res.extension.includes("jpeg")) {
                setActualViewFile(res.file);
                setActualViewFileName(name);
                setActualViewFileExtension(res.extension)
            } else {
                downloadFile(fileId, `Test.${res.extension}`);
            }

        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const postChangeAvatar = async function () {
        const croppedImage = await getCroppedImg(avatarFileSrc, {
            x: avatarCropped.x,
            y: avatarCropped.y,
            width: avatarCropped.width, // Estos valores ahora son dinámicos
            height: avatarCropped.height
        });

        const body = {
            userId: studentId,
            file: {
                b64: croppedImage,
                name: `avatar-${studentId}`,
                extension: 'jpg'
            }
        }

        const req = await PUT(`/sso/users/avatar`, userToken, body);
        const res = await req.json();

        if (req.status === 201) {
            console.log(res)
            handleCloseChangeAvatarModal();
            getStudent(studentId);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const postStudentNewData = async function () {
        let body = {
            param: selectedTypeOption[inputStudentList[0]].id,
            value: document.getElementById(`${inputStudentList[0]}-inputModal`).value,
            studentId: Number(getParams())
        }
        /*         {
                    "studentId": 483,
                    "param": "prueba",
                    "value": "prueba2"
                } */
        /* for (let elementId of inputStudentList) {
            body.push({
                param: selectedTypeOption[elementId].name,
                value: document.getElementById(`${elementId}-inputModal`).value,
                studentId: getParams()
            })
        } */
        console.log(body);
        const req = await POST(`/students/data`, userToken, body);
        const res = await req.json();
        if (req.status === 201) {
            console.log(res)
            handleCloseModalStudentData();
            getStudentInfo(getParams());
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const [dataItemsState, setDataItemsState] = useState(null);
    const [isStudentDataLoaded, setIsStudentDataLoaded] = useState(false);
    const getStudentInfo = async function (userId) {
        setIsStudentDataLoaded(false);
        const req = await GET(`/students/data/byUserId/${userId}`, userToken);
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setDataStudent(res)
            setIsStudentDataLoaded(true);
            setDataItemsState(Array(res.length).fill(false))
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getDataTypesStudents = async function () {
        const req = await GET(`/students/data/params`, userToken);
        const res = await req.json();
        if (req.status === 200) {
            console.log(res)
            setOptionsType(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const infoDiv = (iconFa, text, name) => <div className='infoStudentContainer mb-2'>
        <div className='iconStudent'>
            {/* <FontAwesomeIcon icon={iconFa} /> */}
            <span className='fs-12'>{name}</span>
        </div>
        <span className='fs-14'>{text}</span>
    </div>

    const [showEditStudentData, setShowEditStudentData] = useState(false);
    const handleShowEditStudentDataModal = () => {
        setShowEditStudentData(true);
    };
    const handleCloseEditStudentData = () => {
        setShowEditStudentData(false);
    }

    const [editDataState, setEditDataState] = useState(-1);
    const handleEditOneDataField = (i, state) => {
        if (state) {
            if (editDataState != -1) {
                document.getElementById(`inputEditModal-${editDataState}`).value = dataStudent[editDataState].value;
            }
            setEditDataState(i);
        } else {
            setEditDataState(-1);
            document.getElementById(`inputEditModal-${i}`).value = dataStudent[i].value;
        }
    }

    useEffect(() => {
        if (showEditStudentData) {
            for (let i = 0; i < dataStudent.length; i++) {
                document.getElementById(`inputEditModal-${i}`).value = dataStudent[i].value;
            }
        }
        setStudentDataList(dataListItems);
        groupDataStudent();
    }, [dataStudent])

    const putStudentData = async function (id, i, userId, param) {
        const body = {
            param: param,
            value: document.getElementById(`inputEditModal-${i}`).value
        }

        const req = await PUT(`/students/data/updateByIdAndUserId/${id}/${userId}`, userToken, body);
        const res = await req.json();

        if (req.status === 201) {
            console.log(res)
            getStudentInfo(getParams());
            handleEditOneDataField(i, false);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [deleteParamElement, setDeletePsychoRecordElement] = useState(null);
    const handleCloseConfirmDeleteModal = () => setShowConfirmDeleteModal(false);
    const handleShowConfirmDeleteModal = (element, i) => {
        setDeletePsychoRecordElement({
            element: element,
            i: i
        });
        setShowConfirmDeleteModal(true);
    };


    const deleteStudentData = async function (id, i, userId) {
        const req = await DEL(`/students/data/deleteByUserIdAndId/${id}/${userId}`, userToken);
        const res = await req.json();

        if (req.status === 201) {
            console.log(res)
            getStudentInfo(getParams());
            handleEditOneDataField(i, false);
            handleCloseConfirmDeleteModal();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const [optionsSanctionType, setSanctionType] = useState([]);
    const getSanctionsType = async function () {
        const req = await GET(`/sanctions/type/byLevelId`, userToken);
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setSanctionType(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const [isSanctionLoaded, setIsSanctionLoaded] = useState(false);
    const getSanctionsByUserId = async function (userId) {
        setIsSanctionLoaded(false)
        const req = await GET(`/sanctions/byUserId/${userId}`, userToken);
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setIsSanctionLoaded(true);
            setSanctions(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const [sanctionStadistics, setSanctionStadistics] = useState([])
    const getSanctionsStadisticsByUserId = async function (userId) {
        console.log(`/sanctions/statics/byUserId/${userId}`)
        const req = await GET(`/sanctions/statics/byUserId/${userId}`, userToken);
        const res = await req.json();

        if (req.status === 200) {
            console.log("Estadisticas")
            console.log(res)
            setSanctionStadistics(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const dataListItems = [
        { title: 'Datos personales', params: ['BIRTH_DATE', 'DATE_BIRTH', 'NATIONALITY', 'NACIONALITY', 'GENDER', 'DOCUMENT'] },
        { title: 'Curso', params: ['LEVEL_ID', 'STATUS', 'EXPENDITURE_DATE', 'INCOME_DATE'] },
        { title: 'Contacto', params: ['PHONE_NUMBER', 'EMAIL'] },
        { title: 'Contacto de emergencia', params: [] },
        { title: 'Domicilio', params: ['ADDRESS_LINE1', 'ADDRESS_LINE2', 'CITY'] },
        { title: 'Observación médica', params: [] },
        { title: 'Madre', params: ['MOTHER_NAME', 'MOTHER_NATIONALITY', 'MOTHER_PROFESSION', 'MOTHER_DOCUMENT', 'MOTHER_PHONE', 'MOTHER_EMAIL'] },
        { title: 'Padre', params: ['FATHER_NAME', 'FATHER_NATIONALITY', 'FATHER_PROFESSION', 'FATHER_DOCUMENT', 'FATHER_PHONE', 'FATHER_EMAIL'] },
        { title: 'Tutor', params: ['TUTOR_NAME', 'TUTOR_NATIONALITY', 'TUTOR_PROFESSION', 'TUTOR_DOCUMENT', 'TUTOR_PHONE', 'TUTOR_EMAIL'] },
        { title: 'Hermanos', params: [] },
        { title: 'Responsable de pago', params: [] },
        { title: 'Personas autorizadas', params: [] },
        { title: 'Otros', params: null },
    ]
    {/* 
    1. Datos personales (birth, natioanlId)
    2. Curso
    3. Contacto (phone, mail)
    4. Contacto de emergencia
    5. Domicilio
    6. Observación médica
    7. Progenitores
    8. Hermanos
    9. Responsable de pago
    10.Personas autorizadas
    11.Otros
    */}
    const [studentDataList, setStudentDataList] = useState(dataListItems)
    const groupDataStudent = () => {
        let tempDataList = [...dataListItems];
        for (let s of dataStudent) {
            let index = tempDataList.findIndex(item =>
                item.params && item.params.includes(s.param)
            );
            if (index === -1) {
                index = tempDataList.length - 1;
            }
            if (!tempDataList[index].elements) {
                tempDataList[index].elements = [];
            }
            tempDataList[index].elements.push(s);
        }
        //Aregar el mail
        if (student.email) {
            let index = tempDataList.findIndex(item =>
                item.params && item.params.includes('EMAIL')
            );
            if (index === -1) {
                index = tempDataList.length - 1;
            }
            if (!tempDataList[index].elements) {
                tempDataList[index].elements = [];
            }
            tempDataList[index].elements.push({ param: 'EMAIL', StudentDataParam: { name: 'Correo' }, value: `${student.email}` });
        }

        setStudentDataList(tempDataList);
    }

    const [assistance, setAssistance] = useState(null);
    const [isAssistanceLoaded, setIsAssistanceLoaded] = useState(false);
    const getAssistance = async function (userId, month) {
        setIsAssistanceLoaded(false);

        const req = await GET(`/assistance/classes/byUserIdAndMonth/${userId}/${month}`, userToken);
        const res = await req.json();

        if (req.status === 200) {
            console.log("Assistance: ")
            console.log(res)
            setAssistance(res)
            setIsAssistanceLoaded(true);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const studentRowAssist = (sa, i) => {

        let stdDays = []

        for (let d of sa.dates) {
            stdDays.push(
                <td className='pb-0 pt-1 ps-0 pe-0'>
                    <Row className='tableRows'>
                        <Col className='align-center-vertically-v2 ps-1 pe-1'>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Badge bg='' className='assistanceBadge fs-14' style={{ height: 36, width: 36, background: `${d.color}`, color: `${d.color != '#FFFFFF' ? 'white' : 'black'}` }}>{d.shortName != 'ND' ? d.shortName : '-'}</Badge>
                            </div>
                        </Col>
                    </Row>
                </td>
            )
        }

        return (<tr key={i} className=''>

            <td className='sticky-column pb-0 pt-1 ps-0 pe-0'>
                <Row style={{ borderRadius: '8px 0 0 8px' }} className='tableRows'>
                    <Col className='align-center-vertically-v2'>
                        <div style={{ textAlign: 'left', width: 140 }} className='d-none d-lg-block'>
                            {sa.classId == null ? 'General' : sa.name}
                        </div>
                        <div style={{ textAlign: 'left', width: 100 }} className='d-block d-lg-none fs-12'>
                            {sa.classId == null ? 'General' : sa.name}
                        </div>
                    </Col>
                </Row>
            </td>

            <td className='pb-0 pt-1 ps-0 pe-0'>
                <Row className='tableRows'>
                    <Col className='align-center-vertically-v2 ps-1 pe-1'>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 55, backgroundColor: 'white', color: 'black' }}>{sa.stats.assist.total}</Badge>
                        </div>
                    </Col>
                </Row>
            </td>

            <td className='pb-0 pt-1 ps-0 pe-0'>
                <Row className='tableRows'>
                    <Col className='align-center-vertically-v2 ps-1 pe-1'>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 55, backgroundColor: 'white', color: 'black' }}>{sa.stats ? sa.stats.faults.justified : ''}</Badge>
                        </div>
                    </Col>
                </Row>
            </td>

            <td className='pb-0 pt-1 ps-0 pe-0'>
                <Row className='tableRows'>
                    <Col className='align-center-vertically-v2 ps-1 pe-1'>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 55, backgroundColor: 'white', color: 'black' }}>{sa.stats ? sa.stats.faults.unjustified : ''}</Badge>
                        </div>
                    </Col>
                </Row>
            </td>

            <td className='pb-0 pt-1 ps-0 pe-0'>
                <Row className='tableRows'>
                    <Col className='align-center-vertically-v2 ps-1 pe-1'>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 55, backgroundColor: 'white', color: 'black' }}>{sa.stats ? sa.stats.faults.noComputable : ''}</Badge>
                        </div>
                    </Col>
                </Row>
            </td>

            <td className='pb-0 pt-1 ps-0 pe-0'>
                <Row className='tableRowsDarkLeft'>
                    <Col className='align-center-vertically-v2 ps-1 pe-1'>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 55, backgroundColor: 'white', color: 'black' }}>{sa.stats ? sa.stats.last.total : ''}</Badge>
                        </div>
                    </Col>
                </Row>
            </td>

            <td className='pb-0 pt-1 ps-0 pe-0'>
                <Row className='tableRows'>
                    <Col className='align-center-vertically-v2 ps-1 pe-1'>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 55, backgroundColor: 'white', color: 'black' }}>{sa.stats ? sa.stats.last.justified : ''}</Badge>
                        </div>
                    </Col>
                </Row>
            </td>

            <td className='pb-0 pt-1 ps-0 pe-0'>
                <Row className='tableRowsDarkLeft'>
                    <Col className='align-center-vertically-v2 ps-1 pe-1'>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 55, backgroundColor: 'white', color: 'black' }}>{sa.stats ? sa.stats.total : ''}</Badge>
                        </div>
                    </Col>
                </Row>
            </td>

            <td className='pb-0 pt-1 ps-0 pe-0'>
                <Row className='tableRows'>
                    <Col className='align-center-vertically-v2 ps-1 pe-1'>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 55, backgroundColor: 'white', color: 'black' }}>{sa.stats ? sa.stats.totalJustified : ''}</Badge>
                        </div>
                    </Col>
                </Row>
            </td>

            <td className='pb-0 pt-1 ps-0 pe-0'>
                <Row className='tableRows'>
                    <Col className='align-center-vertically-v2 ps-1 pe-1'>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Badge bg='' className='statusBadge fs-14' style={{ height: 36, width: 55, backgroundColor: 'white', color: 'black' }}>{sa.stats ? sa.stats.totalUnjustified : ''}</Badge>
                        </div>
                    </Col>
                </Row>
            </td>

        </tr>)
    }

    const rolesCanSeePsychopedagogic = ["Rectorado Secundaria", "Dirección Primaria", "Director Primaria"]
    const [psychoRecords, setPsychoRecords] = useState([{
        title: "Informe de prueba",
        content: `<p><strong>Queridos padres y alumnos,</strong></p>
                      <p>Nos complace anunciar que la <span style="color:blue;">Fiesta de Fin de Año</span> se llevará a cabo el próximo viernes. Les pedimos que vengan con atuendos <em>formales</em> y preparados para disfrutar de una noche <strong>inolvidable</strong>.</p>
                      <p><span style="color:green;">¡Esperamos verlos a todos!</span></p>`,
        psychologist: {
            id: 12,
            name: 'Mariana',
            surname: 'Gonzalez',
        },
        date: new Date(),
        tags: [
            {
                name: "tag 1",
                color: 'orange'
            }
        ],
        files: [
            {
                b64: "https://www.exact.com.pe/assets/uploads/posts/1714508636_5663fedb683e43b412db.png",
                extension: "jpg",
                name: "archivo1"
            },
            {
                b64: "",
                extension: "pdf",
                name: "archivo2 fkrmoemvfertgfv"
            }
        ]
    }])
    const getStudentPsychopedagogic = async function (userId) {
        const req = await GET(`/students/psychopedagogic/records/byUserId/${userId}`, userToken);
        const res = await req.json();

        if (req.status === 200) {
            console.log(res);
            setPsychoRecords(res.PsychopedagogicRecords);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const [psychoTags, setPsychoTags] = useState(null)
    const getPsychoTags = async function (userId) {
        const req = await GET(`/students/psychopedagogic/tags/byLevel/mine`, userToken);
        const res = await req.json();

        if (req.status === 200) {
            console.log("Tags")
            console.log(res);
            setPsychoTags(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const [selectedPsychoTags, setSelectedPsychoTags] = useState([]);
    const handleSelectPsychoTag = (el) => {
        let arr = selectedPsychoTags;
        arr.push(el)
        setSelectedPsychoTags(arr)
    }

    const removeTag = (t) => {
        const updatedContacts = selectedPsychoTags.filter(contact => contact.id !== t.id);
        setSelectedPsychoTags(updatedContacts);
    };

    const processFile = async (file) => {
        const f = await convert2Base64(file);
        const aux = file.name.split('.')
        const fileExtension = `${aux[aux.length - 1]}`
        const fileName = file.name;

        const body = {
            file: f,
            name: fileName.replace(`.${fileExtension}`, ""),
            extension: fileExtension
        }
        return body
    }

    const postNewPsychoRecord = async function () {
        let filesArray = []
        files.map((file) => {
            filesArray.push(processFile(file));
        })
        let tagIds = []
        for(let t of selectedPsychoTags){
            tagIds.push(t.id)
        }
        let body = {
            title: valueTitleModal,
            content: valueContentModal,
            userId: Number(getParams()),
            psychologistId: null,
            date: selectedDatePsychoModal,
            tagIds: tagIds, 
            files: filesArray
        }

        console.log(body);
        const req = await POST(`/students/psychopedagogic/record`, userToken, body);
        const res = await req.json();
        if (req.status === 201) {
            console.log(res)
            getStudentPsychopedagogic(getParams())
            handleCloseNewPsychoRecordModal();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const [selectedDatePsychoModal, setSelectedDatePsychoModal] = useState(new Date());
    const handlePsychoDateModal = date => {
        setSelectedDatePsychoModal(date);
    };

    const [showNewPsychoRecordModal, setShowNewPsychoRecordModal] = useState(false);
    const handleCloseNewPsychoRecordModal = () => setShowNewPsychoRecordModal(false);
    const handleShowNewPsychoRecordModal = () => {
        setValueContentModal('');
        setShowNewPsychoRecordModal(true);
    };

    const [valueContentModal, setValueContentModal] = useState('');
    const quillRef = useRef(null);
    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline'], ['emoji'],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['link'],
        ],
        "emoji-toolbar": true,
        "emoji-shortname": true,
    };

    const handleFile = async (event) => {
        let arr = [...files];
        for (let i = 0; i < event.target.files.length; i++) {
            arr.push(event.target.files[i])
        }
        setFiles(arr)
        console.log(arr)
    }


    const [files, setFiles] = useState([]);
    const deleteFile = (index) => {
        setFiles(prevFile => prevFile.filter((_, i) => i !== index));
    }
    const [valueTitleModal, setValueTitleModal] = useState('');
    const handleChangeTitle = (e) => {
        setValueTitleModal(e.target.value)
    }

    useEffect(() => {
        console.log("Change in files")
    }, [files])


    const [recordToSee, setRecordToSee] = useState(null);
    const [showPsychoRecordModal, setshowPsychoRecordModal] = useState(false);
    const handleShowPsychoRecord = (r) => {
        setRecordToSee(r)
        setFiles([])
        setshowPsychoRecordModal(true);
    };
    const handleCloseFileDetailModal = () => {
        setshowPsychoRecordModal(false);
    }

    return (
        <div className="app">
            <Navbar />
            <div className="content">
                <div className='d-block d-lg-none' style={{ height: '70px' }}> </div>
                <Row className='toolbar m-0'>
                    <Col>
                        {PREVIOUS_PAGE != "" ?
                            <Link onClick={goBack} className='link-primary'>
                                <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver
                            </Link>
                            : ``}
                        <span className={`navbar-icon ${PREVIOUS_PAGE != "" ? `ms-4` : ``}`}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={PAGE_ICON}></FontAwesomeIcon></span>
                        <span className='ms-2'>{PREVIOUS_PAGE != "" ? `${PREVIOUS_PAGE} - ` : ``}{PAGE_TITLE}</span>
                    </Col>
                </Row>
                <div className='d-block d-lg-none mt-3'></div>
                <div className='d-none d-lg-block mt-4'></div>
                <Row>
                    <Col md={6} className=''>
                        <Card className='cards pt-3 mobile-cards heightDataStudentContainer' style={{ paddingRight: '5px', paddingLeft: '5px' }}>
                            <Row className='mb-3 align-center-vertically' style={{ justifyContent: 'space-between', paddingLeft: '10px', paddingRight: '10px' }}>
                                <Col className='align-center-vertically fs-16 ps-0 fitContentWidth'>
                                    <span className={`navbar-icon`}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={faFileAlt}></FontAwesomeIcon></span><span className='ms-2 fs-16'>Legajo</span>
                                </Col>
                                {/*<Col className='endRow pe-0'>
                                    <ActionButton title='Documento' variant='primary' iconFa={faPlus} func={handleShowNewFileModal} />
                                </Col>
                                <Col className='endRow fitContentWidth pe-0'>
                                    <ActionButton title='Dato' variant='primary' iconFa={faPen} func={handleShowModalStudentData} />
                                </Col>*/}
                                {!myRole.includes("Alumno") ?<> <Col className='d-none d-lg-block endRow fitContentWidth pe-0'>
                                    <div className=' align-center-vertically' >
                                        <ActionButton title='Editar' variant='outline-secondary' iconFa={faPen} func={handleShowEditStudentDataModal} classExtra='me-2' />
                                        <ActionButton title='Cargar datos' variant='primary' iconFa={faPlus} func={handleShowModalStudentData} />

                                    </div>
                                    <div className='d-block d-lg-none '>
                                        <div>
                                            <ActionButton title='' variant='outline-secondary' iconFa={faPen} func={handleShowEditStudentDataModal} classExtra='me-2' />
                                            <ActionButton title='' variant='primary' iconFa={faPlus} func={handleShowModalStudentData} />
                                        </div>

                                    </div>
                                </Col></> : ''}
                            </Row>

                            {isStudentDataLoaded ?
                                <><Row style={{ textAlign: 'center' }}>
                                    <div>
                                        <img className="avatar-70" onClick={handleShowChangeAvatarModal} src={student.avatarFile} />
                                        <p className='fitContentWidth roundedText-v2 borderDiv fs-14 mt-2' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                            {student.surname ? student.surname.toUpperCase() : ''}, {student.name}
                                        </p>
                                    </div>
                                </Row>
                                    <div className='slideContent mt-2 pe-1 ps-1' ref={interCardsRefData}>
                                        {dataStudent != null && dataStudent.length > 0 && (
                                            <Row>
                                                {studentDataList.map((r, i) => (
                                                    r.elements && r.elements.length > 0 && <>
                                                        <p className='fw-600 mb-1'>{r.title}</p>
                                                        {r.elements.map((d, j) => (
                                                            <Col key={j + i} className='fitContentWidth ps-0'>
                                                                {infoDiv(faCalendar, d.value, d.StudentDataParam && d.StudentDataParam.name)}
                                                            </Col>
                                                        ))}</>
                                                ))}
                                            </Row>
                                        )}
                                        {!myRole.includes('Familia') && !myRole.includes('Padre-Madre-Tutor') && !myRole.includes('Alumno') && dataFiles.length > 0 &&
                                            <Row>
                                                <p className='mt-1 mb-1'><strong>Documentos</strong></p>
                                                {dataFiles.map((r, i) =>
                                                    <Row key={i} className='mb-1 pt-1 pb-1 ps-0 pe-0' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                                        <Col md={8} xs={8} className='align-center-vertically'>
                                                            <span className={`navbar-icon`}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={faFile}></FontAwesomeIcon></span>
                                                            <p className='mb-0 ps-2'>{r.StudentFileType ? r.StudentFileType.name : ''}</p>
                                                        </Col>
                                                        <Col md={4} xs={4}>
                                                            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '100%' }}>
                                                                <Button variant="outline-secondary" style={{ height: 'min-content' }} onClick={() => viewFile(r.fileId, r.StudentFileType ? r.StudentFileType.name : '')}>
                                                                    <span><FontAwesomeIcon icon={faEye} /></span>
                                                                </Button>
                                                                <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                                                    <Dropdown className='ms-2'>
                                                                        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                                                            <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu>
                                                                            <Dropdown.Item ><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                )}
                                            </Row>}
                                    </div></>
                                :
                                <div className='justify-content-center'>
                                    <Loading animation={'border'} classExtra='loading-100' />
                                </div>
                            }
                        </Card>
                    </Col>
                    <div className='d-block d-lg-none mt-3'></div>
                    <Col md={6} className=''>
                        <Card className='cards studentCardHeight pt-3 mobile-cards' style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                            <Row className='mb-3 ps-1'>
                                <div className='align-center-vertically fs-16 mb-2' style={{ justifyContent: 'space-between' }}>
                                    <div className='align-center-vertically'>
                                        <div className={`navbar-icon fitContentWidth`}>
                                            <FontAwesomeIcon height={35} className='navbar-icon-content' icon={faClipboardUser}></FontAwesomeIcon>
                                        </div>
                                        <span className='ms-2 fs-16'>Asistencia</span>
                                    </div>
                                    <div >
                                        <ActionButton variant='secondary' url={'/estudiante/asistencia'} iconFa={faEye} />
                                    </div>
                                </div>
                                {isAssistanceLoaded ?
                                    <>
                                        <div className='d-none d-sm-block slideTable fs-14 ps-0 pe-0' style={{ borderRadius: '10px', height: 'calc(50vh - 130px)' }}>
                                            <table className='br-15 table m-0' style={{ borderColor: 'white' }}>
                                                <thead className='sticky-row headerBlue' style={{ color: 'white' }}>

                                                    <tr className=' headerContainer'>
                                                        <td className='sticky-column' rowSpan="2">
                                                            <div style={{ textAlign: 'left' }} className='ps-2'>
                                                                Asignatura
                                                            </div>

                                                        </td>
                                                        <td colSpan={4}>{moment().format('MMMM').charAt(0).toUpperCase() + moment().format('MMMM').slice(1)}</td>
                                                        <td colSpan={2}>Anteriores</td>
                                                        <td rowSpan="2" className='fs-11'>Faltas totales</td>
                                                        <td rowSpan="2" className='fs-11'>Faltas totales justificadas</td>
                                                        <td rowSpan="2" className='fs-11'>Faltas totales injustificadas</td>
                                                    </tr>
                                                    <tr className='headerContainer'>
                                                        <td className='fs-11'>Asistencia</td>
                                                        <td className='fs-11'>Faltas justificadas</td>
                                                        <td className='fs-11'>Faltas injustificadas</td>
                                                        <td className='fs-11'>No Computable</td>
                                                        <td className='fs-11'>Faltas totales</td>
                                                        <td className='fs-11'>Faltas justificadas</td>
                                                    </tr>
                                                </thead><tbody>
                                                    {assistance && assistance.map((std, i) =>
                                                        studentRowAssist(std, i)
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className='d-block d-lg-none slideTable fs-14 ps-0 pe-0' style={{ borderRadius: '10px', height: 'calc(60vh)' }}>
                                            <table className='br-15 table m-0' style={{ borderColor: 'white' }}>
                                                <thead className='sticky-row headerBlue' style={{ color: 'white' }}>

                                                    <tr className=' headerContainer'>
                                                        <td className='sticky-column' rowSpan="2">
                                                            <div style={{ textAlign: 'left' }} className='ps-2'>
                                                                Asignatura
                                                            </div>

                                                        </td>
                                                        <td colSpan={4}>{moment().format('MMMM').charAt(0).toUpperCase() + moment().format('MMMM').slice(1)}</td>
                                                        <td colSpan={2}>Anteriores</td>
                                                        <td rowSpan="2" className='fs-11'>Faltas totales</td>
                                                        <td rowSpan="2" className='fs-11'>Faltas totales justificadas</td>
                                                        <td rowSpan="2" className='fs-11'>Faltas totales injustificadas</td>
                                                    </tr>
                                                    <tr className='headerContainer'>
                                                        <td className='fs-11'>Asistencia</td>
                                                        <td className='fs-11'>Faltas justificadas</td>
                                                        <td className='fs-11'>Faltas injustificadas</td>
                                                        <td className='fs-11'>No Computable</td>
                                                        <td className='fs-11'>Faltas totales</td>
                                                        <td className='fs-11'>Faltas justificadas</td>
                                                    </tr>
                                                </thead><tbody>
                                                    {assistance && assistance.map((std, i) =>
                                                        studentRowAssist(std, i)
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>

                                    </>
                                    :
                                    <div className='justify-content-center'>
                                        <Loading animation={'border'} classExtra='loading-100' />
                                    </div>
                                }
                            </Row>
                        </Card>

                        <Card className='cards studentCardHeight pt-3 mobile-cards' style={{ marginTop: '16px', paddingLeft: '5px', paddingRight: '5px' }}>
                            <Row className=''>
                                <div className='align-center-vertically fs-16 mb-2' style={{ paddingLeft: '10px', justifyContent: 'space-between' }}>
                                    <div className='align-center-vertically'>
                                        <div className={`navbar-icon fitContentWidth`}>
                                            <FontAwesomeIcon height={35} className='navbar-icon-content' icon={faExclamationCircle}></FontAwesomeIcon>
                                        </div>
                                        <span className='ms-2 fs-16'>Sanciones</span>
                                    </div>
                                    <div >
                                        <ActionButton variant='secondary' url={'/estudiante/sanciones'} iconFa={faEye} />
                                    </div>
                                </div>
                                {isSanctionLoaded ? <>
                                    {sanctions != null && sanctions.sanctions ? <>
                                        <div className='d-none d-lg-block slideContent pe-1 ps-1'>
                                            <div style={{ height: 'calc(50vh - 130px)' }} ref={interCardsRefSanctions}>
                                                <Row>
                                                    {sanctionStadistics.map((r, i) => (
                                                        <Col md={4} xs={4} className={`mb-2 ps-0 ${i == sanctionStadistics.length - 1 && 'pe-0'}`} style={{ paddingLeft: '10px' }} >
                                                            <Card className='staticCards cards p-2 ps-3 pe-3'>
                                                                <p className='m-0'>{pluralize(r.sanctionTypeName)}</p>
                                                                <div className='wrapCont flexEnd'>
                                                                    <p className=' m-0' style={{ fontSize: '30px', fontWeight: '700', lineHeight: '32px' }}>{r.total}</p>
                                                                </div>
                                                                <p className='fs-12 mb-0' style={{ color: 'grey', lineHeight: '11px' }}>{r.totalThisYear} este año</p>
                                                            </Card>
                                                        </Col>))}
                                                </Row>

                                                {sanctions.sanctions.map((r, i) => myRole.includes('Familia') || myRole.includes('Padre-Madre-Tutor') || myRole.includes('Alumno') ? r.approved && (
                                                    <div className='mb-2 ps-3 pe-3 pt-2 pb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                                        <Row className='ps-0'>

                                                            <div style={{ textAlign: 'left' }} className='fitContentWidth ps-0 pe-2'>
                                                                <span className='fs-11'>{moment(r.date).format("DD/MM/YYYY")}</span>
                                                            </div>
                                                            <div className='fitContentWidth ps-0 pe-1 align-center-vertically'>
                                                                {r.SanctionType && <Badge bg='warning' style={{ fontWeight: 400, color: 'black' }} >{`${r.amount} ${r.amount > 1 ? pluralize(r.SanctionType.name) : r.SanctionType.name}`}</Badge>
                                                                }</div>
                                                            <div className='fitContentWidth ps-0 pe-1 align-center-vertically'>
                                                                <Badge /* onClick={() => handleShowApprovementDetailModal(r)}  */ style={{ fontWeight: 400, color: `${r.approved ? 'white' : 'black'}` }} bg={r.approved ? 'success' : 'warning'} >{r.approved ? 'Aprobada' : 'Pendiente'}</Badge>
                                                            </div>
                                                            <div className='fitContentWidth ps-0 pe-1 align-center-vertically'>
                                                                {r.from && <Badge bg='secondary' style={{ fontWeight: 400, color: 'white' }} ><FontAwesomeIcon icon={faChalkboardTeacher} className='me-1' style={{ fontSize: '11px' }} />{formatNameV2(r.from.name, r.from.surname)}</Badge>
                                                                }</div>
                                                        </Row>
                                                        <Row className='pt-1 ps-0'>
                                                            <Col xs={12} className='align-center-vertically-v2 ps-0'>
                                                                <div style={{ textAlign: 'left' }}>
                                                                    {r.SanctionReason == null ? '' : r.SanctionReason.name}
                                                                    {r.content != null && r.content != "" ? `${r.SanctionReason != null ? ":" : ""} ${r.content}` : ''}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                ) :
                                                    <div className='mb-2 ps-3 pe-3 pt-2 pb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                                        <Row className='ps-0'>

                                                            <div style={{ textAlign: 'left' }} className='fitContentWidth ps-0'>
                                                                <span className='fs-11'>{moment(r.date).format("DD/MM/YYYY")}</span>
                                                            </div>
                                                            <div className='fitContentWidth ps-0 align-center-vertically'>
                                                                {r.SanctionType && <Badge bg='warning' style={{ fontWeight: 400, color: 'black' }} >{`${r.amount} ${r.amount > 1 ? pluralize(r.SanctionType.name) : r.SanctionType.name}`}</Badge>
                                                                }</div>
                                                            <div className='fitContentWidth ps-0 align-center-vertically'>
                                                                <Badge /* onClick={() => handleShowApprovementDetailModal(r)}  */ style={{ fontWeight: 400, color: `${r.approved ? 'white' : 'black'}` }} bg={r.approved ? 'success' : 'warning'} className=' pointer'>{r.approved ? 'Aprobada' : 'Pendiente'}</Badge>
                                                            </div>
                                                            <div className='fitContentWidth ps-0 align-center-vertically'>
                                                                {r.from && <Badge bg='secondary' style={{ fontWeight: 400, color: 'white' }} ><FontAwesomeIcon icon={faChalkboardTeacher} className='me-1' style={{ fontSize: '11px' }} />{formatNameV2(r.from.name, r.from.surname)}</Badge>
                                                                }</div>
                                                        </Row>
                                                        <Row className='pt-1 ps-0'>
                                                            <Col xs={12} className='align-center-vertically-v2 ps-0'>
                                                                <div style={{ textAlign: 'left' }}>
                                                                    {r.SanctionReason == null ? '' : r.SanctionReason.name}
                                                                    {r.content != null && r.content != "" ? `${r.SanctionReason != null ? ":" : ""} ${r.content}` : ''}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='d-block d-lg-none pe-1 ps-1' style={{ maxHeight: '50vh', overflowY: 'auto' }}>
                                            <Row style={{ maxWidth: '100%' }}>
                                                {sanctionStadistics.map((r, i) => (
                                                    <Col md={4} xs={4} className='mb-2 pe-0 ps-0 pe-1' style={{ paddingLeft: '' }} >
                                                        <Card className='staticCards cards p-2' style={{ height: '90px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                                            <p className='m-0' style={{ wordBreak: 'break-all', lineHeight: '14px' }}>{pluralize(r.sanctionTypeName)}</p>
                                                            <div className='wrapCont flexEnd'>
                                                                <p className=' m-0' style={{ fontSize: '30px', fontWeight: '700', lineHeight: '32px' }}>{r.total}</p>
                                                            </div>
                                                            <p className='fs-12 mb-0' style={{ color: 'grey', lineHeight: '11px' }}>{r.totalThisYear} este año</p>
                                                        </Card>
                                                    </Col>
                                                ))}
                                            </Row>
                                            <div ref={interCardsRefSanctions}>
                                                {sanctions.sanctions.map((r, i) => myRole && (myRole.includes('Familia') || myRole.includes('Padre-Madre-Tutor') || myRole.includes('Alumno')) ? r.approved && (
                                                    <div className='mb-2 ps-3 pe-3 pt-2 pb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                                        <Row className='ps-0'>

                                                            <div style={{ textAlign: 'left' }} className='fitContentWidth ps-0'>
                                                                <span className='fs-11'>{moment(r.date).format("DD/MM/YYYY")}</span>
                                                            </div>
                                                            <div className='fitContentWidth ps-0 align-center-vertically'>
                                                                {r.SanctionType && <Badge bg='warning' style={{ fontWeight: 400, color: 'black' }} >{`${r.amount} ${r.amount > 1 ? pluralize(r.SanctionType.name) : r.SanctionType.name}`}</Badge>
                                                                }</div>
                                                            <div className='fitContentWidth ps-0 align-center-vertically'>
                                                                <Badge /* onClick={() => handleShowApprovementDetailModal(r)}  */ style={{ fontWeight: 400, color: `${r.approved ? 'white' : 'black'}` }} bg={r.approved ? 'success' : 'warning'} >{r.approved ? 'Aprobada' : 'Pendiente'}</Badge>
                                                            </div>

                                                        </Row>
                                                        <Row className='pt-1 ps-0'>
                                                            <Col xs={12} className='align-center-vertically-v2 ps-0'>
                                                                <div style={{ textAlign: 'left' }}>
                                                                    {r.SanctionReason != null ? r.SanctionReason.name && r.SanctionReason.name : ''}

                                                                    {(r.content != null && r.content != "") ? `${r.SanctionReason != null ? ":" : ""} ${r.content}` : ''}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <div className='fitContentWidth ps-0 align-center-vertically mt-2'>
                                                            {r.from && <Badge bg='secondary' style={{ fontWeight: 400, color: 'white' }} ><FontAwesomeIcon icon={faChalkboardTeacher} className='me-1' style={{ fontSize: '11px' }} />{formatNameV2(r.from.name, r.from.surname)}</Badge>
                                                            }</div>
                                                    </div>
                                                ) :
                                                    (
                                                        <div className='mb-2 ps-3 pe-3 pt-2 pb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                                            <Row className='ps-0'>

                                                                <div style={{ textAlign: 'left' }} className='fitContentWidth ps-0'>
                                                                    <span className='fs-11'>{moment(r.date).format("DD/MM/YYYY")}</span>
                                                                </div>
                                                                <div className='fitContentWidth ps-0 align-center-vertically'>
                                                                    {r.SanctionType && <Badge bg='warning' style={{ fontWeight: 400, color: 'black' }} >{`${r.amount} ${r.amount > 1 ? pluralize(r.SanctionType.name) : r.SanctionType.name}`}</Badge>
                                                                    }</div>
                                                                <div className='fitContentWidth ps-0 align-center-vertically'>
                                                                    <Badge /* onClick={() => handleShowApprovementDetailModal(r)}  */ style={{ fontWeight: 400, color: `${r.approved ? 'white' : 'black'}` }} bg={r.approved ? 'success' : 'warning'} className=' pointer'>{r.approved ? 'Aprobada' : 'Pendiente'}</Badge>
                                                                </div>

                                                            </Row>
                                                            <Row className='pt-1 ps-0'>
                                                                <Col xs={12} className='align-center-vertically-v2 ps-0'>
                                                                    <div style={{ textAlign: 'left' }}>
                                                                        {r.SanctionReason != null ? r.SanctionReason.name && r.SanctionReason.name : ''}

                                                                        {(r.content != null && r.content != "") ? `${r.SanctionReason != null ? ":" : ""} ${r.content}` : ''}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <div className='fitContentWidth ps-0 align-center-vertically mt-2'>
                                                                {r.from && <Badge bg='secondary' style={{ fontWeight: 400, color: 'white' }} ><FontAwesomeIcon icon={faChalkboardTeacher} className='me-1' style={{ fontSize: '11px' }} />{formatNameV2(r.from.name, r.from.surname)}</Badge>
                                                                }</div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    </> :
                                        <div style={{ height: 'calc(50vh - 135px)', justifyContent: 'center' }} className='align-center-vertically'>
                                            <NotFound iconFa={faFileCircleExclamation} text={'No se han encontrado sanciones'} />
                                        </div>
                                    }
                                </> :
                                    <div className='justify-content-center'>
                                        <Loading animation={'border'} classExtra='loading-100' />
                                    </div>}
                            </Row>
                        </Card>
                    </Col>

                    {false && rolesCanSeePsychopedagogic.find(role=>myRole.includes(role)) && rolesCanSeePsychopedagogic.find(r => r.includes(myRole)) ?
                        <Col md={6} className='mt-3'>
                            {/* Desktop */}
                            <Card className='cards pt-3 mobile-cards d-none d-lg-block' style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                                <Row className='mb-2'>
                                    <div className='align-center-vertically fs-16 mb-2' style={{ paddingLeft: '10px', justifyContent: 'space-between' }}>
                                        <div className='align-center-vertically'>
                                            <div className={`navbar-icon fitContentWidth`}>
                                                <FontAwesomeIcon height={35} className='navbar-icon-content' icon={faBookOpen}></FontAwesomeIcon>
                                            </div>
                                            <span className='ms-2 fs-16'>Informe psicopedagógico</span>
                                        </div>
                                        <div>
                                            <ActionButton variant='primary' func={handleShowNewPsychoRecordModal} iconFa={faPlus} />
                                        </div>
                                    </div>
                                </Row>
                                <Row>
                                    {psychoRecords.map((r, i) => (
                                        <Row className='mb-1 pb-1 pt-1 ps-2 pe-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                            <Col className='align-center-vertically-v2 fitContentWidth ps-0 pe-2'>
                                                <div style={{ textAlign: 'left' }} className='fs-12'>
                                                    {moment(r.date).format("DD/MM/YYYY")}
                                                </div>
                                            </Col>
                                            <Col className='align-center-vertically-v2 ps-0 pe-0'>
                                                <div>
                                                    {r.tags && r.tags.map((t, j) => (
                                                        <Badge bg='' style={{ backgroundColor: t.color }} className='fs-12 me-2'>{t.name}</Badge>
                                                    ))}
                                                </div>
                                                <div style={{ textAlign: 'left' }}>
                                                    {r.title}
                                                </div>
                                            </Col>
                                            {/* <Col className='ps-1 pe-1 align-center-vertically fitContentWidth'>
                                                <Badge bg='secondary'><FontAwesomeIcon className='me-1' icon={faChalkboardTeacher} /> {formatNameV2(r.psychologist.name, r.psychologist.surname)}</Badge>
                                            </Col> */}

                                            <Col className='align-center-vertically-v2 fitContentWidth endRow pe-0'>
                                                <ActionButton func={() => handleShowPsychoRecord(r)} iconFa={faEye} variant='secondary' />
                                                {/*  <div style={{ display: 'flex', justifyContent: 'end' }}>
                                                <Dropdown className='ms-2'>
                                                    <Dropdown.Toggle variant="secondary" id="dropdown-basic" >
                                                        <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item ><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div> */}
                                            </Col>
                                        </Row>
                                    ))}
                                </Row>
                            </Card>

                            {/* Mobile */}
                            <Card className='cards pt-3 mobile-cards d-block d-lg-none' style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                                <Row className='mb-2'>
                                    <div className='align-center-vertically fs-16 mb-2' style={{ paddingLeft: '10px', justifyContent: 'space-between' }}>
                                        <div className='align-center-vertically'>
                                            <div className={`navbar-icon fitContentWidth`}>
                                                <FontAwesomeIcon height={35} className='navbar-icon-content' icon={faBookOpen}></FontAwesomeIcon>
                                            </div>
                                            <span className='ms-2 fs-16'>Informe psicopedagógico</span>
                                        </div>
                                        <div>
                                            <ActionButton variant='primary' func={handleShowNewPsychoRecordModal} iconFa={faPlus} />
                                        </div>
                                    </div>
                                </Row>
                                <Row>
                                    {psychoRecords.map((r, i) => (
                                        <Row className='mb-1 pb-2 pt-2 ps-2 pe-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                            <Col className='align-center-vertically-v2 ps-0 pe-0'>
                                                <div style={{ display: "flex" }} className='mb-2'>
                                                    <div className='fitContentWidth fs-12 me-2'>{moment(r.date).format("DD/MM/YYYY")}</div>
                                                    {r.tags && r.tags.map((t, j) => (
                                                        <Badge bg='' style={{ backgroundColor: t.color }} className='fs-12 me-2'>{t.name}</Badge>
                                                    ))}
                                                </div>

                                                <div style={{ textAlign: 'left' }}>
                                                    {r.title}

                                                </div>
                                                {/* <div className='mt-2'>
                                                    <Badge bg='secondary'><FontAwesomeIcon className='me-1' icon={faChalkboardTeacher} /> {formatNameV2(r.psychologist.name, r.psychologist.surname)}</Badge>
                                                </div> */}
                                            </Col>

                                            <Col className='align-center-vertically-v2 fitContentWidth endRow pe-0'>
                                                <ActionButton func={() => handleShowPsychoRecord(r)} iconFa={faEye} variant='secondary' />
                                                {/*  <div style={{ display: 'flex', justifyContent: 'end' }}>
                                                <Dropdown className='ms-2'>
                                                    <Dropdown.Toggle variant="secondary" id="dropdown-basic" >
                                                        <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item ><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div> */}
                                            </Col>
                                        </Row>
                                    ))}
                                </Row>
                            </Card>
                        </Col>
                        : ''}
                </Row >
            </div >

            <Modal show={showModalStudentData} onHide={handleCloseModalStudentData} centered className='assitanceModal modalContainer' backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropDownIcon' icon={faUser} style={{ minWidht: '20px' }} /><Modal.Title>Cargar datos del estudiante</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className=''>
                        <Col md={12} className='p-0'>
                            <div className='align-center-vertically mb-2 fitContentWidth'>
                                <div style={{ rowGap: '6px' }}>
                                    {inputStudentList.map((r) => <NewinputStudent newId={r} />)}
                                    {/* <Button variant='primary' onClick={addInputStudent}><FontAwesomeIcon className='mr-10' icon={faPlus} />Agregar información</Button>
                                 */}</div>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14' onClick={handleCloseModalStudentData}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                    <Button variant="primary" className='fs-14' onClick={postStudentNewData}><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button>
                </Modal.Footer>
            </Modal>

            <Modal size={'lg'} show={showEditStudentData} onHide={handleCloseEditStudentData} centered className='assitanceModal modalContainer' backdrop="static" style={{ zIndex: 1049 }}>
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropDownIcon' icon={faUser} style={{ minWidht: '20px' }} /><Modal.Title>Editar datos del estudiante</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='justify-content-center'>
                        <Col md={12} className='p-0' >
                            {dataStudent.map((r, i) => !r.isReadOnly && (
                                <>

                                    <Row className={`entireRow ps-0 pe-0 mb-2`}>
                                        <Col md={3} className='pt-2 pb-2 ps-4 pe-4 align-center-vertically' style={{ background: "#3680F4", color: "white", borderRadius: '8px' }}>
                                            {r.StudentDataParam && r.StudentDataParam.name}
                                        </Col>
                                        <Col className='pt-1 pb-1 ps-4'>
                                            <div className='align-center-vertically fitContentWidth ms-2' style={{ height: '100%' }}>
                                                <Form.Control className='textInputGray fs-14 wi-300' id={`inputEditModal-${i}`} placeholder="" defaultValue={r.value} disabled={editDataState != i} />
                                            </div>
                                        </Col>
                                        <Col className='fitContentWidth' >
                                            {editDataState != i ? <div className='align-center-vertically pt-1 pb-1' style={{ height: '100%' }}>
                                                <ActionButton variant='secondary' iconFa={faPen} classExtra='me-2' func={() => handleEditOneDataField(i, true)} />
                                                <ActionButton variant='danger' iconFa={faTrash} func={() => handleShowConfirmDeleteModal(r, i)} />
                                            </div> :
                                                <div className='align-center-vertically pt-1 pb-1' style={{ height: '100%' }}>
                                                    <ActionButton variant='secondary' iconFa={faTimes} classExtra='me-2' func={() => handleEditOneDataField(i, false)} />
                                                    <ActionButton variant='primary' iconFa={faCheck} func={() => putStudentData(r.id, i, getParams())} />
                                                </div>
                                            }
                                        </Col>
                                    </Row>
                                </>))}
                        </Col >
                    </Row>
                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14' onClick={handleCloseEditStudentData}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cerrar</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showModalNewFile} onHide={handleCloseNewFileModal} centered backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropDownIcon' icon={faUser} style={{ minWidht: '20px' }} /><Modal.Title>Nuevo archivo</Modal.Title>
                </Modal.Header>
                <Modal.Body>


                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14' onClick={handleCloseNewFileModal}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                    <Button variant="primary" className='fs-14'><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button>
                </Modal.Footer>
            </Modal>

            {showConfirmDeleteModal && <div>
                <div style={{ opacity: 0.5, zIndex: 1050 }}></div>
                <Modal show={showConfirmDeleteModal} onHide={handleCloseConfirmDeleteModal} centered backdrop="static" style={{ zIndex: 1051 }}>
                    <Modal.Header closeButton>
                        <FontAwesomeIcon height={35} className='dropModalCont dropDownIcon' icon={faTrash} style={{ minWidht: '20px' }} /><Modal.Title>Eliminar</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='pt-4 pb-4'>
                        <span className=''>¿Deseas eliminar la información sobre "{deleteParamElement && deleteParamElement.element && deleteParamElement.element.StudentDataParam && deleteParamElement.element.StudentDataParam.name}"?</span>
                    </Modal.Body>
                    <Modal.Footer className='p-2'>
                        <Button variant="outline-secondary" className='fs-14 me-2' onClick={handleCloseConfirmDeleteModal}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                        <Button variant="danger" className='fs-14 me-2' onClick={() => deleteStudentData(deleteParamElement.element.id, deleteParamElement.i, getParams())}><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button>
                    </Modal.Footer>
                </Modal>
            </div>}

            <Modal size='xl' show={showViewFileModal} onHide={handleCloseViewFileModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{actualViewFileName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {actualViewFile && actualViewFileExtension?
                        actualViewFileExtension.includes("pdf") ?
                            <PDFViewer document={{
                                base64: actualViewFile
                            }} />
                            :
                            <img src={actualViewFile} style={{ width: '100%' }} />
                        :
                        <div className='w-100 justify-content-center' style={{ textAlign: 'center', marginTop: 200, marginBottom: 200 }}>
                            <Loading animation={'border'} />
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseViewFileModal}>
                        <FontAwesomeIcon className='mr-10' icon={faTimes} />Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showChangeAvatarModal} centered onHide={handleCloseChangeAvatarModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Modificar foto</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Row className='pb-3'>
                            <Form.Group controlId="formFile">
                                <Form.Control type="file" accept="image/*" onChange={onAvatarFileChange} multiple={false} />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Col>
                                {avatarFileSrc && (
                                    <div className='mt-3'>
                                        <div className="cropper-container">
                                            <Cropper
                                                image={avatarFileSrc}
                                                crop={avatarCrop}
                                                zoom={avatarZoom}
                                                onCropChange={setAvatarCrop}
                                                onZoomChange={setAvatarZoom}
                                                aspect={1}
                                                cropShape="round"
                                                showGrid={false}
                                                onCropComplete={onCropComplete}
                                            />
                                        </div>
                                        <div className="button-container mb-3">
                                            <ActionButton func={() => setAvatarZoom(avatarZoom + 0.1)} iconFa={faPlus} title='Agrandar' />
                                            <ActionButton func={() => setAvatarZoom(avatarZoom - 0.1)} iconFa={faMinus} title='Achicar' />
                                        </div>
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" className='fs-14' onClick={handleCloseChangeAvatarModal}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                    <Button variant="primary" className='fs-14' onClick={postChangeAvatar}><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button>
                </Modal.Footer>
            </Modal>

            {showNewPsychoRecordModal &&
                <Modal size={'xl'} show={showNewPsychoRecordModal} onHide={handleCloseNewPsychoRecordModal} centered backdrop="static" style={{ zIndex: 1049 }} >
                    <Modal.Header closeButton>
                        <FontAwesomeIcon height={35} className='dropDownIcon' icon={faFileAlt} style={{ minWidht: '20px' }} /><Modal.Title>Nuevo informe psicopedagógico</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className='mb-2'>
                            <Col md={12}>

                                <div className='align-center-vertically'>
                                    <FontAwesomeIcon icon={faCalendar} className='dropDownIcon' style={{ height: 16 }} />
                                    <DatePicker
                                        selected={selectedDatePsychoModal}
                                        onChange={handlePsychoDateModal}
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText="Fecha"
                                        className='dropdown-toggle'
                                        locale='es' maxDate={moment().toDate()} />
                                </div>
                            </Col>
                        </Row>
                        <Row className='mb-2'>
                            <Col md={12} className='align-center-vertically'>
                                <FontAwesomeIcon icon={faBook} className='dropDownIcon' style={{ height: 16 }} />
                                <Dropdown className='dropdownButton wi-300 p-0'>
                                    <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-300">
                                        Categorías
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="fs-14" title="Categorías">
                                        {psychoTags && psychoTags.map((opt, i) => (
                                            <Dropdown.Item key={i} onClick={() => handleSelectPsychoTag(opt)}>{opt.name}</Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                                {selectedPsychoTags.map((t, i) => (
                                    <div key={i} className='me-2 userSelectedModal mt-2' style={{ display: 'inline-block' }}>
                                        <span>{t.name}</span>
                                        <FontAwesomeIcon onClick={() => removeTag(t)} icon={faTimes} className='remiderCross ms-2' />
                                    </div>
                                ))}
                            </Col>
                        </Row>

                        <Row className='mb-2'>
                            <Col md={12}>
                                <div className='align-center-vertically'>
                                    <Form.Control className='textInputGray fs-14' id="newPsychoRecordTitle" placeholder="Título" value={valueTitleModal} onChange={handleChangeTitle} />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <div className='align-center-vertically pb-5' style={{height:'100%'}}>
                                    <ReactQuill theme="snow" value={valueContentModal} ref={quillRef} onChange={setValueContentModal} modules={modules} style={{ height: '350px', width: '100%' }} />
                                </div>
                            </Col>
                        </Row>

                        <div className='d-block d-lg-none' style={{height:'20px'}}>

                        </div>
                        <Row className='mt-1'>
                            <Col>
                                <div onClick={() => document.getElementById('file-input').click()} className='me-2 fitContentWidth'>
                                    <Form.Control type="file" multiple={true} onChange={handleFile} id="file-input" style={{ display: 'none' }} />
                                    <Button variant='outline-secondary mt-2'>
                                        <FontAwesomeIcon icon={faPaperclip} className='me-2' />
                                        Adjuntar archivo
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} className='pt-2'>
                                <div className='align-center-vertically'>
                                    {
                                        files.map((f, i) => (
                                            <div key={i} style={{ border: '1px solid grey', borderRadius: '8px' }} className='fitContentWidth ps-3 pe-2 pb-1 pt-1 me-2'>
                                                <FontAwesomeIcon icon={faFile} className='me-2' /> {f.name}
                                                <FontAwesomeIcon onClick={() => deleteFile(i)} icon={faTimes} style={{ cursor: 'pointer' }} className='remiderCross ms-2' />
                                            </div>
                                        ))
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Modal.Body>

                    <Modal.Footer className='p-2'>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', alignItems: 'center' }}>

                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button variant="outline-secondary" className='fs-14 ms-2' onClick={handleCloseNewPsychoRecordModal}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cerrar</Button>
                                 <Button variant="primary" className='fs-14 ms-2' onClick={postNewPsychoRecord}>
                                    {false ?
                                        <div className="justify-content-center" style={{ width: '85px' }}>
                                            <Loading animation={'grow'} size='20px' bsSize='sm' variant='light' classExtra='justify-content-center' />
                                        </div>
                                        : <><FontAwesomeIcon className='mr-10' icon={faCheck} />Publicar</>}
                                </Button> 
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>
            }

            <Modal size='lg' show={showPsychoRecordModal} onHide={handleCloseFileDetailModal} centered className='assitanceModal modalContainer' backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropDownIcon' icon={faFile} style={{ minWidht: '20px' }} /><Modal.Title>{recordToSee && recordToSee.title}</Modal.Title>
                </Modal.Header>
                {recordToSee && <Modal.Body>
                    <Row className='' style={{ position: 'relative' }} >
                        <div className='flex ps-0 pe-0'>
                            {recordToSee.tags && recordToSee.tags.map((t, j) => (
                                <Badge bg='' style={{ backgroundColor: t.color }} className='fs-12 me-2'>{t.name}</Badge>
                            ))}
                        </div>

                        <div style={{ backgroundColor: 'white' }} className='mt-1 mb-1 ps-0 pe-0'>
                            <div dangerouslySetInnerHTML={{ __html: recordToSee.content }} />

                            <div className='mt-2 align-center-vertically' style={{ flexWrap: 'wrap', rowGap: '4px' }}>
                                {recordToSee.files && recordToSee.files.map((f, j) => (
                                    <div key={j} style={{ width: '115px' }}>
                                        <div style={{ border: '1px solid transparent', backgroundColor: '#00000021', borderRadius: '5px', cursor: 'pointer', display: 'flex', justifyContent: 'center', flexDirection: 'column' }} className=' ps-3 pe-3 pt-1 pb-1 fs-12 me-2 blueHoverDownload'>
                                            <div onClick={() => viewFile(f.id)} style={{ width: '100%', textAlign: 'center', height: '35px' }} className='align-center-vertically justify-content-center'>
                                                <FontAwesomeIcon icon={faFile} className='me-1' style={{ fontSize: '16px' }} />
                                            </div>
                                            <div className='fitContentWidth align-center-vertically'>
                                                <p className='mb-0 textDots'>{f.name != null ? f.name : "Archivo"}.{f.extension}</p>
                                                <FontAwesomeIcon onClick={() => downloadFile(f.id, `${f.name}.${f.extension}`)} icon={faDownload} style={{ cursor: 'pointer', height: '11px', justifySelf: 'flex-end' }} className='ms-2' />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {recordToSee.files && recordToSee.files.length > 1 &&
                                <div className="mt-2 pointer blueHover fitContentWidth" >Descargar todos los archivos</div>
                            }
                        </div>

                    </Row>
                </Modal.Body>}
                {recordToSee && <Modal.Footer className='p-2 flex j-s-between'>
                    <div className='' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Row style={{ width: '100%' }}>
                            <Col className='ps-0 fitContentWidth align-center-vertically'>
                                <div className='align-center-vertically fitContentWidth' >
                                    <p className='fs-12 mb-0'>{recordToSee.date && `${moment(recordToSee.date).format("DD/MM/YY")}`} </p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Button variant="outline-secondary" className='fs-14 me-2' onClick={handleCloseFileDetailModal}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cerrar</Button>
                    </div>
                </Modal.Footer>}
            </Modal>
            <Modal>

            </Modal>
        </div >
    )
}

export default StudentSite;