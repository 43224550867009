import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'

function Privacy() {
    return (
        <div className='content ms-0 ps-5 pe-5 pt-1'>
            <div style={{ width: '100%' }}>
                <div className='mt-4'>
                    <img src="/logo_color.svg" width={80} alt="Logo"></img>
                </div>
            </div>
            <div style={{ textAlign: 'center' }} className='fw-700 fs-24 mt-4 mb-4'>Política de privacidad</div>
            <Container className='p-0'>
                <Row>
                    <Col md={12} className='p-0'>
                        <Card className='cards'>
                            <p>
                                1.	Introducción.
                            </p>
                            <p>
                                La protección de los datos personales y la privacidad de los usuarios es una prioridad para Secdevs. El ser una empresa enfocada en el desarrollo de soluciones informáticas para instituciones y redes educativas de nivel inicial, primario y secundario nos obliga a redoblar los esfuerzos para asegurar la integridad y privacidad de la información y datos personales ingresados a Tasky por las instituciones educativas, evitando su alteración, difusión, filtración, acceso, procesamiento o uso no autorizados, con particular énfasis en la protección de la información referida a personas menores de edad.
                            </p>
                            <p>
                                El propósito del presente documento es brindar a los usuarios información adicional a la contenida en los Términos y Condiciones del servicio, relativa al tipo de datos almacenados en Tasky, los propósitos para los cuales dichos datos pueden ser utilizados o procesados, las medidas de seguridad adoptadas por Secdevs para el almacenamiento y tratamiento de datos personales, el modo en que los usuarios pueden gestionar esos datos y los derechos que toda persona posee en relación a sus datos personales.
                            </p>
                            <p>
                                2.	Sobre los datos almacenados en Tasky.
                            </p>
                            <p>
                                2.1.	Tipo de datos almacenados en Tasky. La institución educativa que contrata el servicio puede recabar y almacenar en Tasky una serie de datos personales de los usuarios necesarios para su gestión académica y administrativa. Algunos de esos datos pueden ser considerados datos sensibles en los términos de la ley Nº 25.326.
                            </p>
                            <p>
                                En líneas generales, los datos que la institución académica recaba de padres y alumnos son nombre y apellido, número de documento de identidad, fecha de nacimiento, dirección de correo electrónico, números de teléfono celular y de contacto, domicilios particular y laboral y demás información necesaria para cuestiones administrativas.
                            </p>
                            <p>
                                Asimismo, la institución educativa puede recabar datos de los alumnos necesarios para situaciones de emergencia médica, tales como grupo sanguíneo, alergias, antecedentes médicos, vacunas o información de la cobertura de salud con la que cuenta.
                            </p>
                            <p>
                                Por otra parte, la institución educativa también puede almacenar en Tasky información académica del alumno tal como calificaciones, inasistencias, sanciones disciplinarias u observaciones de los docentes relativas a su desempeño.
                            </p>
                            <p>
                                2.2.	Recopilación de datos de personas menores de edad. Toda vez que Tasky es una plataforma pensada para la gestión académica y administrativa de instituciones educativas de nivel inicial, primario y secundario, una parte significativa de los datos almacenados y procesados por el sistema refiere a personas menores de 18 años de edad, que son consideradas menores de edad para la legislación argentina. Estos datos son recabados por las instituciones educativas con el único propósito de cumplir con sus funciones administrativas y pedagógicas.
                            </p>
                            <p>
                                Secdevs cumple estrictamente con las medidas de protección de los datos personales de las personas menores de edad, de conformidad con lo establecido en la ley Nº 25.326, su decreto reglamentario y demás normativa complementaria. Asimismo, y a efectos de evitar que las personas menores de edad suministren datos personales sin autorización de sus padres, tutores o guardadores, los usuarios con perfil de “alumno” no están autorizados para agregar, modificar o borrar sus datos personales, médicos o académicos. Si, no obstante esta limitación, usted advierte que una persona menor de edad ha incorporado o publicado en Tasky datos personales sin contar con la autorización de sus padres, tutores o guardadores resultando ello legalmente necesario (por ejemplo, a través de mensajes privados o publicaciones en el módulo de Aula virtual deberá comunicarse inmediatamente con la institución educativa para que esta proceda a su eliminación o rectificación.
                            </p>
                            <p>
                                Los datos personales de personas menores de edad sólo pueden ser visualizados por los usuarios “profesor”, “secretario”, “directivo” y “administrador” en el marco de sus respectivas funciones, así como por los padres, tutores o guardadores del alumno. La información personal, médica y académica de los alumnos no puede ser visualizada, agregada, modificada o eliminada por otros usuarios ni por terceros.
                            </p>
                            <p>
                                Secdevs no suministrará datos personales de personas menores almacenados en Tasky a terceros en ningún momento y bajo ninguna circunstancia, excepto que medie una obligación legal u orden judicial al respecto.
                            </p>
                            <p>
                                2.3.	Información recabada mediante el uso de cookies. Se denomina cookie a un archivo enviado por un sitio web y almacenado en el equipo del usuario con el objeto de recopilar cierta información respecto de la actividad del navegador. Tasky utiliza cookies con el objeto de identificar las páginas que son visitadas y su frecuencia, a los fines de efectuar análisis estadísticos y mejorar la experiencia del usuario. En particular, Tasky utiliza cookies para recordar las preferencias de cada usuario y de ese modo brindarle una experiencia de navegación por la plataforma personalizada y acorde a sus necesidades. La información recabada a través de cookies es de carácter anónimo y no permite acceder a datos personales del usuario ni otorga acceso remoto a su equipo. Las cookies pueden ser eliminadas en cualquier momento por el propio usuario a través de su navegador web. Asimismo, si bien la mayoría de los navegadores web admiten por defecto el uso de cookies, es posible configurarlos para que rechacen las cookies salvo autorización expresa del usuario. En caso de configurar su navegador web para que rechace el uso de cookies, es probable que algunas secciones o funciones de Tasky no funcionen adecuadamente.
                            </p>
                            <p>
                                3.	Uso de datos personales en Tasky.
                            </p>
                            <p>
                                Los datos personales recabados por las instituciones educativas y almacenados en Tasky son utilizados exclusivamente para fines académicos y administrativos tales como la comunicación de novedades institucionales a la comunidad educativa, la gestión de clases y tareas virtuales, el control de asistencia de los alumnos, etcétera. Secdevs no utiliza la información personal de los usuarios con fines propios, limitándose a su procesamiento automatizado para satisfacer las necesidades de las instituciones educativas. Los datos personales de los usuarios almacenados en Tasky están destinados al uso exclusivo de la institución educativa que contrata el servicio y no serán transferidos a terceros bajo ningún concepto, excepto que medie una obligación legal u orden judicial al respecto. En particular, Secdevs garantiza que los datos de los usuarios no serán utilizados con ninguna finalidad comercial o publicitaria.
                            </p>
                            <p>
                                4.	¿Quién puede acceder a los datos de los usuarios?
                            </p>
                            <p>
                                Tal como se explica en los Términos y Condiciones del servicio, la información de los usuarios sólo puede ser visualizada por los usuarios pertenecientes a una misma comunidad educativa, según los niveles de acceso que tengan habilitados en función de su perfil. Así, los usuarios “alumno” sólo pueden visualizar la información referida a su persona, no estando autorizados a agregar, modificar o borrar sus datos personales, médicos o académicos; los usuarios “padre” sólo pueden visualizar la información personal, médica y académica de los alumnos que tuvieran vinculados a su usuario, así como su propia información personal, estando autorizados a agregar, modificar o borrar sus propios datos personales y los de los alumnos que tuvieran vinculados a su usuario; los usuarios “profesor” no pueden visualizar, agregar, modificar o borrar la información personal o médica de los alumnos ni de los padres, pudiendo únicamente acceder a la información académica de los alumnos vinculada a su función en la institución educativa; los usuarios “secretario” tienen acceso completo a la información personal, médica y académica de los usuarios alumnos, padres y profesores, pudiendo agregarla, modificarla y borrarla, de acuerdo a las competencias que le hubieran sido asignadas por la institución educativa; los usuarios “directivo” pueden visualizar la información de los usuarios alumno, padre, profesor y secretario, pero no pueden agregar, modificar o borrar dichos datos; los usuarios “administrador” designados por la institución educativa tienen acceso completo a los datos de los usuarios pudiendo agregarlos, modificarlos o borrarlos.
                            </p>
                            <p>
                                Asimismo, el personal de Secdevs tiene acceso amplio al sistema y eventualmente podría visualizar datos personales de los usuarios o proceder a su modificación o borrado. Dicho acceso se encuentra limitado, no obstante, estrictamente a lo necesario para brindar soporte técnico, efectuar correcciones al sistema o solucionar problemas vinculados a su correcto funcionamiento.
                            </p>
                            <p>
                                5.	Transferencia Internacional de Datos
                            </p>
                            <p>
                                Secdevs almacena la información administrada por la plataforma Tasky en los servidores del centro de datos (datacenter) de Secdevs ubicados en la Ciudad Autónoma de Buenos Aires. Secdevs cumple con los más altos estándares en seguridad, tanto informática como física.
                            </p>
                            <p>
                                6.	Medidas de protección de datos.
                            </p>
                            <p>
                                La protección de los datos personales de los usuarios es una prioridad para Secdevs. En la recolección, tratamiento y almacenamiento de datos personales Secdevs instrumenta todas las medidas técnicas y organizativas necesarias para garantizar su seguridad y confidencialidad, evitando su adulteración, pérdida, consulta, transmisión o tratamiento no autorizados, así como para detectar y solucionar desviaciones de información de cualquier tipo. Estas medidas de seguridad son objeto de evaluación y perfeccionamiento permanente, de acuerdo a la evolución de los conocimientos y tecnologías disponibles, y se adecuan a los estándares establecidos por la ley Nº 25.326, su decreto reglamentario y la normativa complementaria emitida por la Agencia de Acceso a la Información Pública. Del mismo modo, en aquellos casos en que Secdevs subcontrata el almacenamiento o procesamiento de datos en servidores externos, asegura que en todos los casos dichos proveedores brinden un nivel adecuado de seguridad para los datos de los usuarios, tanto en los aspectos técnicos y organizativos, como a nivel jurídico cuando los datos son almacenados o procesados en el exterior del país. Sin embargo, toda vez que no es posible garantizar la absoluta infalibilidad de las medidas de seguridad adoptadas, Secdevs advierte que los usuarios deberán tener en consideración tal circunstancia y que no se responsabiliza por violaciones a la confidencialidad y/o integridad de los datos personales y de las comunicaciones de los usuarios que no hayan podido razonablemente ser evitadas con los medios técnicos y humanos actualmente a su disposición. En todos los casos Secdevs se compromete a adoptar todas las medidas a su alcance para restablecer inmediatamente niveles adecuados de seguridad en sus sistemas en caso de detectar que está siendo víctima de un ciberataque o de una filtración de datos.
                            </p>
                            <p>
                                Del mismo modo, Secdevs no puede garantizar la seguridad en la transmisión de datos desde el equipo del usuario o desde los equipos de la institución educativa a sus servidores o los de los proveedores subcontratados, por lo que eventualmente terceras personas podrían interceptar, captar o acceder a datos a través de la red en el transcurso de dichas transmisiones.
                            </p>
                            <p>
                                7.	Derechos del titular de los datos.
                            </p>
                            <p>
                                La entrega de datos personales a las instituciones educativas para su utilización en Tasky es totalmente voluntaria. Cuando la entrega de determinados datos resulte necesaria para acceder a alguna sección o función de Tasky, o para visualizar determinado contenido, se requerirá expresamente el consentimiento del usuario para recabar dichos datos.
                            </p>
                            <p>
                                Asimismo, toda persona tiene derecho a solicitar información de forma gratuita respecto de sus datos personales almacenados en Tasky en los términos del artículo 14 de la ley Nº 25.326, así como a exigir su rectificación, actualización y, cuando corresponda, su supresión o confidencialidad.
                            </p>
                            <p>
                                Toda vez que la carga, modificación y supresión de los datos personales de los usuarios en Tasky es responsabilidad de la institución educativa que contrata el servicio, el ejercicio de los derechos contemplados en la ley Nº 25.326 deberá efectuarse mediante comunicación por los canales habilitados por la respectiva institución educativa.
                            </p>
                            <p>
                                En particular, los usuarios podrán solicitar:
                            </p>
                            <p>
                                •	La rectificación de los datos, cuando los mismos sean falsos, inexactos, imprecisos o erróneos.
                            </p>
                            <p>
                                •	La actualización de los datos, dirigida a preservar su vigencia en función de las modificaciones que pudieran haber sufrido los
                            </p>
                            <p>
                                hechos que los datos representan en función del paso del tiempo.
                            </p>
                            <p>
                                •	La adición de datos, cuando los datos registrados se consideren incompletos y no representen con suficiente fidelidad los
                            </p>
                            <p>
                                hechos a los que refieren.
                            </p>
                            <p>
                                •	La disociación de datos, a efectos de evitar que los mismos sólo puedan ser tratados sin posibilidad de ser asociados o vinculados a su titular.
                            </p>
                            <p>
                                •	La supresión de datos, consistente en su eliminación de los archivos o registros de Tasky, sin que quede constancia alguna de su anterior registración.
                            </p>
                            <p>
                                •	El sometimiento de los datos a confidencialidad, de modo tal que sólo puedan acceder a ellos las personas expresamente
                            </p>
                            <p>
                                autorizadas al efecto para el cumplimiento de funciones específicas, teniendo expresamente prohibido ponerlos en conocimiento de terceros no autorizados.
                            </p>
                            <p>
                                Asimismo, para obtener mayor información sobre los derechos que asisten a los titulares de datos personales y el modo de ejecerlos, o bien para efectuar denuncias o reclamos, sugerimos comunicarse a la Dirección Nacional de Protección de Datos personales por los siguientes canales:
                            </p>
                            <p>
                                Página web: https://www.argentina.gob.ar/aaip/datospersonales
                            </p>
                            <p>
                                Correo electrónico: datospersonales@aaip.gob.ar
                            </p>
                            <p>
                                Telefóno: (54-11) 3988-3968
                            </p>
                            <p>
                                Dirección: Av. Pte. Gral. Julio A. Roca 710, piso 3º - Ciudad Autónoma de Buenos Aires (C1067ABP).
                            </p>
                            <p>
                                8.	Canal de denuncias.
                            </p>
                            <p>
                                Si Ud. desea efectuar una denuncia o reclamo respecto del tratamiento de los datos personales efectuados por Tasky, podrá hacerlo mediante correo electrónico dirigido a denuncias@secdevs.com.ar, indicando en el asunto el motivo de la denuncia o reclamo.
                            </p>
                            <p>
                                9.	Modificaciones a la Política de Privacidad de Datos.
                            </p>
                            <p>
                                Secdevs podrá en cualquier momento modificar en todo o en parte la presente Política de Privacidad de Datos, sin que ello de lugar a reclamo alguno por parte de los usuarios, ni origine derecho a compensación o indemnización de ningún tipo a su favor. Las modificaciones a la Política de Privacidad de Datos serán notificadas al usuario mediante la publicación de un mensaje al iniciar su sesión en Tasky, ocasión en la que el usuario deberá prestar su conformidad a los cambios introducidos. En caso de disconformidad con las modificaciones a la Política de Privacidad de datos, el usuario deberá abandonar la plataforma Tasky inmediatamente. La utilización bajo cualquier modalidad de Tasky, o la mera permanencia en el sitio, serán entendidos como una manifestación de voluntad positiva respecto de la plena aceptación de todas y cada una de las modificaciones introducidas a la Política de Privacidad de Datos.
                            </p>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Privacy