import React, { useEffect, useState } from 'react'
import Navbar from '../../navbar/Navbar'
import { Button, Card, Col, Dropdown, Form, Modal, Row, } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faBook, faCalculator, faCalendar, faChalkboardTeacher, faCheck, faCog, faEllipsisV, faEye, faHeading, faListOl, faPen, faPlus, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Link, useParams } from 'react-router-dom'
import ActionButton from '../../general-components/ActionButton'
import { getUserToken, logout, validateToken } from '../../../controllers/user.controller'
import { useNavigate } from 'react-router-dom';
import { GET, POST, PUT } from '../../../controllers/endpoints.controller'

function Class() {

    const PAGE_TITLE = "Nodales";
    const PAGE_ICON = faCog;
    const PREVIOUS_PAGE = "Configuración > Cursos > Materias";
    const PREVIOUS_URL = "/configuracion/cursos/materias"

    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1); // Navega hacia atrás en el historial
    };

    useEffect(() => {
        document.title = `Tasky - ${PAGE_TITLE}`;
        validateToken();

        getNodalsByClassId();
    }, []);

    const getParam = function (name) {
        const params = new URLSearchParams(window.location.search);
        if (params.has(name)) {
            return params.get(name);
        } else {
            return null;
        }
    }

    const [showModalNodal, setShowModalNodal] = useState(false);
    const handleShowModalNodal = () => {
        setShowModalNodal(true);
        getPeriods();
    };
    const handleCloseModalNodal = () => {
        setShowModalNodal(false);
    }

    const [selectedEditNodal, setSelectedEditNodal] = useState({});

    const [showEditNodalModal, setShowEditNodalModal] = useState(false);
    const handleShowEditNodalModal = (nodal) => {
        setSelectedEditNodal(nodal);
        setSelectedPeriodId(nodal.Period.id);
        setShowEditNodalModal(true);
        getPeriods();
    };
    const handleCloseEditNodalModal = () => {setShowEditNodalModal(false)}

    const [selectedPeriodId, setSelectedPeriodId] = useState(null);

    const handleSelectPeriod = (event) => {
        setSelectedPeriodId(event.target.value);
    };

    const [periods, setPeriods] = useState([]);
    const [nodals, setNodals] = useState([]);

    const getPeriods = async function () {
        const req = await GET(`/grades/periods/noGrouped`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setPeriods(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getNodalsByClassId = async function () {
        const req = await GET(`/grades/nodals/byClassId/${getParam("materia")}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setNodals(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const postNodal = async function () {
        const body = {
            classId: getParam("materia"),
            name: document.getElementById('txtNewNodalName').value,
            periodId: selectedPeriodId,
            number: document.getElementById('txtNewNodalNumber').value
        }

        const req = await POST(`/grades/nodal`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            console.log(res)
            getNodalsByClassId();
            handleCloseModalNodal();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const putNodal = async function () {
        const body = {
            id: selectedEditNodal.id,
            classId: getParam("materia"),
            name: document.getElementById('txtEditNodalName').value,
            periodId: selectedPeriodId,
            number: document.getElementById('txtEditNodalNumber').value
        }

        console.log(body)

        const req = await PUT(`/grades/nodal`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            console.log(res)
            getNodalsByClassId();
            handleCloseEditNodalModal();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    return (
        <div className="app">
            <Navbar />
            <div className="content fs-14" >
                <Row className='toolbar m-0'>
                    <Col>
                        {PREVIOUS_PAGE != "" ?
                            <Link onClick={goBack} className='link-primary'>
                                <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver
                            </Link>
                            : ``}

                        <span className={`navbar-icon ${PREVIOUS_PAGE != "" ? `ms-4` : ``}`}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={PAGE_ICON}></FontAwesomeIcon></span>
                        <span className='ms-2'>{PREVIOUS_PAGE != "" ? `${PREVIOUS_PAGE} > ` : ``}{PAGE_TITLE}</span>
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <Col className='endRow'>
                        <ActionButton iconFa={faPlus} title={'Nuevo nodal'} func={handleShowModalNodal} classExtra='fs-14' />
                    </Col>
                </Row>
                <Card className='cards mt-3'>
                    <div>
                        <Row className='p-2 mb-2' style={{ color: 'white', backgroundColor: '#3680F4', borderRadius: 8 }}>
                            <Col md={3} className='align-center-vertically-v2'>
                                <div style={{ textAlign: 'left' }}>
                                    Nodal
                                </div>
                            </Col>
                            <Col md={3} className='align-center-vertically-v2'>
                                <div style={{ textAlign: 'center' }}>
                                    Nombre
                                </div>
                            </Col>
                            <Col md={3} className='align-center-vertically-v2'>
                                <div style={{ textAlign: 'center' }}>
                                    Periodo
                                </div>
                            </Col>
                            <Col md={3} className='align-center-vertically-v2'>
                            </Col>
                        </Row>
                    </div>

                    {nodals.map((r, i) => (
                        <div key={i}>
                            <div className='d-none d-lg-block'>
                                <Row className='mb-1 pb-1 pt-1 ps-2 pe-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                    <Col md={3} className='align-center-vertically-v2 '>
                                        <div style={{ textAlign: 'left' }}>
                                            ({r.id}) Nodal {r.number}
                                        </div>
                                    </Col>
                                    <Col md={3} className='align-center-vertically-v2'>
                                        <div style={{ textAlign: 'center' }}>
                                            {r.name}
                                        </div>
                                    </Col>
                                    <Col md={3} className='align-center-vertically-v2'>
                                        <div style={{ textAlign: 'center' }}>
                                            {r.Period ? r.Period.name : ''}
                                        </div>
                                    </Col>
                                    <Col md={3} className='align-center-vertically-v2'>
                                        <div style={{ display: 'flex', justifyContent: 'end' }}>
                                            <Dropdown className='ms-2'>
                                                <Dropdown.Toggle variant="secondary" id="dropdown-basic" >
                                                    <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => handleShowEditNodalModal(r)}><FontAwesomeIcon icon={faPen} style={{ marginRight: 10, marginLeft: 2 }}/>Editar</Dropdown.Item>
                                                    <Dropdown.Item ><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    ))}
                </Card>
            </div>

            <Modal show={showModalNodal} onHide={handleCloseModalNodal} centered backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropDownIcon' icon={faChalkboardTeacher} style={{ minWidht: '20px' }} /><Modal.Title>Nuevo nodal</Modal.Title>
                </Modal.Header>
                <Modal.Body className='fs-14'>
                    <Row>
                        <Col md={12} className=''>
                            <div className='align-center-vertically mb-2'>
                                <FontAwesomeIcon icon={faHeading} className='dropDownIcon' style={{ height: 16 }} />
                                <Form.Control className='textInputGray fs-14' id="txtNewNodalName" placeholder="Nombre" />
                            </div>
                        </Col>
                        <Col md={12} className=''>
                            <div className='align-center-vertically mb-2'>
                                <FontAwesomeIcon icon={faListOl} className='dropDownIcon' style={{ height: 16 }} />
                                <Form.Control className='textInputGray fs-14 wi-100' id="txtNewNodalNumber" placeholder="Número"/>
                            </div>
                        </Col>
                        <Col md={12} className='align-items-center'>
                            <div className='align-center-vertically' style={{ width: '100%' }}>
                                <FontAwesomeIcon icon={faCalendar} className='dropDownIcon' style={{ height: 16 }} />
                                <Form.Select className='dropdownButton' id="" value={selectedPeriodId} onChange={(e) => handleSelectPeriod(e)} >
                                    {periods.map((opt, i) => (
                                        <option key={i} id={i} value={opt.id}>{opt.name}</option>
                                    ))}
                                </Form.Select>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14' onClick={handleCloseModalNodal}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                    <Button variant="primary" className='fs-14' onClick={postNodal}><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showEditNodalModal} onHide={handleCloseEditNodalModal} centered backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropDownIcon' icon={faChalkboardTeacher} style={{ minWidht: '20px' }} /><Modal.Title>Editar nodal</Modal.Title>
                </Modal.Header>
                <Modal.Body className='fs-14'>
                    <Row>
                        <Col md={12} className=''>
                            <div className='align-center-vertically mb-2'>
                                <FontAwesomeIcon icon={faHeading} className='dropDownIcon' style={{ height: 16 }} />
                                <Form.Control className='textInputGray fs-14' id="txtEditNodalName" placeholder="Nombre" defaultValue={selectedEditNodal.name}/>
                            </div>
                        </Col>
                        <Col md={12} className=''>
                            <div className='align-center-vertically mb-2'>
                                <FontAwesomeIcon icon={faListOl} className='dropDownIcon' style={{ height: 16 }} />
                                <Form.Control className='textInputGray fs-14 wi-100' id="txtEditNodalNumber" placeholder="Número" defaultValue={selectedEditNodal.number} />
                            </div>
                        </Col>
                        <Col md={12} className='align-items-center'>
                            <div className='align-center-vertically' style={{ width: '100%' }}>
                                <FontAwesomeIcon icon={faCalendar} className='dropDownIcon' style={{ height: 16 }} />
                                <Form.Select className='dropdownButton' id="" value={selectedPeriodId} onChange={(e) => handleSelectPeriod(e)} >
                                    {periods.map((opt, i) => (
                                        <option key={i} id={i} value={opt.id}>{opt.name}</option>
                                    ))}
                                </Form.Select>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14' onClick={handleCloseEditNodalModal}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                    <Button variant="primary" className='fs-14' onClick={putNodal}><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Class